import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    searchBar: {
        "& .MuiFilledInput-underline": {
          "&:before": {
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
          },
          "&:after": {
            borderBottom: "2px solid #198B66",
          },
          backgroundColor: theme.palette.action.hover,
        },
      },
}));
