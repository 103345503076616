import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },

  imageWrapper: {
    width: "100px",
    height: "80px",
    position: "relative",
    "&:hover": {
      "& div, button": {
        opacity: 1,
      },
    },
  },

  imageWrapperFull: {
    maxHeight: "80vh",
    width: "60vw",
    position: "relative",
    display: "flex",
    alignItem: "flex-start"
  },
  
  imageFull: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },

  mainImage: {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  
  closeBtn: {
    position: "absolute",
    right: 0,
    top:0,
    padding: 0,
    "& .MuiIconButton-label": {
      backgroundColor: theme.palette.background.paper,
      width: "35px",
      height: "35px",
    },
  },

  overlay: {
    opacity: 0,
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    transition: ".2s",
    cursor: 'pointer'
  },

  deleteIcon: {
    opacity: 0,
    color: theme.palette.error.main,
    position: "absolute",
    right: 0,
    bottom: 0,

  },

  starIcon: {
    opacity: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },

  button: {
    minWidth: "200px",
    textTransform: "uppercase",
    [theme.breakpoints.down(350)]: {
        minWidth: "155px",
    },
  },

  starIconMain: {
    opacity: 1,
    position: "absolute",
    right: 0,
    top: 0,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalContent: {
    "&:focus": {
      outline: "none",
    },
  },

  mainText: {
    fontSize: "0.7rem",
    color: theme.palette.secondary.main,
  },

  mainTextIcon: {
    fontSize: "0.9rem",
  },

  documentBg: {
    backgroundColor: theme.palette.text.disabled,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  anchorLink: {
    color: theme.palette.text.secondary,
  },

  required: {
    color: theme.palette.error.main
  }
}));
