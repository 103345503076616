/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import * as Icon from "../utils/icons";
import SettingsWithSections from "../components/Settings/SettingsWithSections/SettingsWithSections";
import SettingsWithCheckboxes from "../components/Settings/SettingsWithCheckboxes/SettingsWithCheckboxes";
import Error from "../components/Error/Error";
import { getRequest } from "../utils/apiRequests";
import { categoryGetAll } from "../apiURL/httpCategory";
import { objectiveGetAll } from "../apiURL/httpObjective";
import { personsInvolvedGetAll } from "../apiURL/httpPersonsInvolved";
import { durationGetAll } from "../apiURL/httpDuration";
import { scopeAmountGetAll } from "../apiURL/httpScopeAmount";
import { scopePerUnitGetAll } from "../apiURL/httpScopePerUnit";
import { costsGetAll } from "../apiURL/httpCosts";
import { teamTypeGetAll } from "../apiURL/httpTeamType";
import { membersGetAll } from "../apiURL/httpMembers";
import { teamCompositionGetAll } from "../apiURL/httpTeamComposition";
import { ownershipGetAll } from "../apiURL/httpOwnership";
import { pitchTypeGetAll } from "../apiURL/httpPitchType";
import { cabinTypeGetAll } from "../apiURL/httpCabinType";
import { sponsorsGetAll } from "../apiURL/httpSponsors";
import Typography from "@material-ui/core/Typography";
import { marketingConsentGet } from "../apiURL/httpMarketingConsent";

interface DataType {
  id: number;
  name: string;
  active: boolean;
}

interface AllDataType {
  [key: string]: DataType[] | boolean;
  categories: DataType[];
  objectives: DataType[];
  personsInvolved: DataType[];
  duration: DataType[];
  scopeAmount: DataType[];
  scopePerUnit: DataType[];
  costs: DataType[];
  teamType: DataType[];
  members: DataType[];
  teamComposition: DataType[];
  ownership: DataType[];
  pitchType: DataType[];
  cabinType: DataType[];
  sponsors: DataType[];
  marketingConsent: boolean;
}

const initialSettings: AllDataType = {
  categories: [],
  objectives: [],
  personsInvolved: [],
  duration: [],
  scopeAmount: [],
  scopePerUnit: [],
  costs: [],
  teamType: [],
  members: [],
  teamComposition: [],
  ownership: [],
  pitchType: [],
  cabinType: [],
  sponsors: [],
  marketingConsent: false,
};

const PracticeSettings: FC = () => {
  const [allData, setAllData] = useState<AllDataType>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    settingsList,
    pageTitleList,
    sponsorList,
    buttonList,
    siteLanguage,
    messagesList,
  } = useContext(LanguageContext) as LanguageContextType;
  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;
  const history = useHistory();
  const location = useLocation();

  const scopeNames = [settingsList.choices, settingsList.choices];
  const infrastructureNames = [
    settingsList.choices,
    settingsList.inputs,
    settingsList.inputs,
  ];

  const option1 = [
    {
      id: 1,
      name: sponsorList.amount,
      active: true,
    },
    {
      id: 2,
      name: sponsorList.moreInfo,
      active: true,
    },
  ];

  const option2 = [
    {
      id: 1,
      name: sponsorList.arguments,
      active: true,
    },
  ];

  const option3 = [
    {
      id: 1,
      name: sponsorList.sites,
      active: true,
    },
  ];

  const option4 = [
    {
      id: 1,
      name: sponsorList.advertising,
      active: true,
    },
  ];

  useEffect(() => {
    setSiteTitle(pageTitleList.praxisprofiSettings);
  }, [siteLanguage]);

  useEffect(() => {
    Promise.all([
      getRequest(categoryGetAll),
      getRequest(objectiveGetAll),
      getRequest(personsInvolvedGetAll),
      getRequest(durationGetAll),
      getRequest(scopeAmountGetAll),
      getRequest(scopePerUnitGetAll),
      getRequest(costsGetAll),
      getRequest(teamTypeGetAll),
      getRequest(membersGetAll),
      getRequest(teamCompositionGetAll),
      getRequest(ownershipGetAll),
      getRequest(pitchTypeGetAll),
      getRequest(cabinTypeGetAll),
      getRequest(sponsorsGetAll),
      getRequest(marketingConsentGet),
    ])
      .then(
        ([
          categories,
          objectives,
          personsInvolved,
          duration,
          scopeAmount,
          scopePerUnit,
          costs,
          teamType,
          members,
          teamComposition,
          ownership,
          pitchType,
          cabinType,
          sponsors,
          consent
        ]) => {
          initialSettings.categories = categories;
          initialSettings.objectives = objectives;
          initialSettings.personsInvolved = personsInvolved;
          initialSettings.duration = duration;
          initialSettings.scopeAmount = scopeAmount;
          initialSettings.scopePerUnit = scopePerUnit;
          initialSettings.costs = costs;
          initialSettings.teamType = teamType;
          initialSettings.members = members;
          initialSettings.teamComposition = teamComposition;
          initialSettings.ownership = ownership;
          initialSettings.pitchType = pitchType;
          initialSettings.cabinType = cabinType;
          initialSettings.sponsors = sponsors;
          initialSettings.marketingConsent = consent;

          setAllData(initialSettings);
          setError(false);
          setLoading(false);
        }
      )
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  if (error || loading || !allData) {
    return <Error loading={loading} error={error} />;
  }

  if (!allData.categories[0]) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: "80vh" }}
      >
        <Typography variant="subtitle2">{messagesList.empty}</Typography>
      </Box>
    );
  }

  const consent = [{
    id: 1, 
    name: settingsList.mandatoryOptional,
    active: allData.marketingConsent
  }]

  const sponsorNames = [
    settingsList.choices,
    `${settingsList.forChoice} "Ja"`,
    `${settingsList.forChoice} "${allData?.sponsors[1].name}"`,
    `${settingsList.forChoice} "${allData?.sponsors[2].name}"`,
    `${settingsList.forChoice} "${allData?.sponsors[3].name}"`,
  ];

  const scopeValues = {
    "1": allData.scopeAmount,
    "2": allData.scopePerUnit,
  };

  const infrastructureValues = {
    "1": allData.ownership,
    "2": allData.pitchType,
    "3": allData.cabinType,
  };

  const sponsorValues = {
    "1": allData.sponsors,
    "2": option1,
    "3": option2,
    "4": option3,
    "5": option4,
  };

  return (
    <Box>
      {location.state ? (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Button
            color="default"
            startIcon={<Icon.ArrowBack />}
            onClick={() => history.goBack()}
          >
            {buttonList.back}
          </Button>
        </Box>
      ) : (
        <Box mb={2} />
      )}
      <SettingsWithCheckboxes
        settingData={allData.categories}
        title={settingsList.categories}
      />
      <SettingsWithCheckboxes
        settingData={allData.objectives}
        title={settingsList.objectives}
      />
      <SettingsWithCheckboxes
        settingData={allData.personsInvolved}
        title={settingsList.personsInvolved}
      />
      <SettingsWithCheckboxes
        settingData={allData.duration}
        title={settingsList.duration}
      />
      <SettingsWithSections
        settingData={scopeValues}
        title={settingsList.scope}
        titleNames={scopeNames}
      />
      <SettingsWithCheckboxes
        settingData={allData.costs}
        title={settingsList.financialCosts}
      />
      <SettingsWithSections
        settingData={sponsorValues}
        title={settingsList.sponsors + " / " + settingsList.funding}
        titleNames={sponsorNames}
      />
      <SettingsWithCheckboxes
        settingData={allData.teamType}
        title={settingsList.clubType}
      />
      <SettingsWithCheckboxes
        settingData={allData.members}
        title={settingsList.noOfMembers}
      />
      <SettingsWithCheckboxes
        settingData={allData.teamComposition}
        title={settingsList.noOfTeams}
      />
      <SettingsWithSections
        settingData={infrastructureValues}
        title={settingsList.infrastructure}
        titleNames={infrastructureNames}
      />
       <SettingsWithCheckboxes
        settingData={consent}
        title={settingsList.marketingConsent}
        disableFunctions
      />
    </Box>
  );
};

export default PracticeSettings;
