import React, { useContext, useState, FC } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import CustomAutocompleteSingle from "../CustomAutocompleteSingle/CustomAutocompleteSingle";
import CustomInput from "../CustomInput/CustomInput";
import CustomAutocomplete from "../CustomAutocomplete/CustomAutocomplete";
import Header from "./components/Header";
import { PracticeType } from "../../types/practiceType";
import { messageFields } from "../../data/practice-detail/practiveDetail-messageFields";
import { AllDataType, modifyData } from "../../utils/getAllData";
import {
  handleActiveMessages,
  findValueId,
  AllData,
} from "../../utils/practiceDetailFunctions";
import { useStyles } from "./PracticeStyles";

type Props = {
  data: PracticeType;
  allData: AllDataType;
  validated: boolean;
  validateBtn: boolean;
  editable: boolean;
  disableEditing: boolean;
  validatedDraft: boolean;
  handleEdit: () => void;
  changeSelectMulti: (newValue: AllData[], objectKey: string) => void;
  changeSelect: (newValue: AllData | null, objectKey: string) => void;
  changeInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    objectKey: string,
    max?: number
  ) => void;
};

const Finance: FC<Props> = ({
  data,
  editable,
  handleEdit,
  changeInput,
  changeSelect,
  changeSelectMulti,
  validated,
  validateBtn,
  allData,
  disableEditing,
  validatedDraft,
}) => {
  const [messageField, setMessageField] = useState(messageFields);

  const {
    practiceDetailList,
    settingsList,
    messagesList,
    sponsorList,
  } = useContext(LanguageContext) as LanguageContextType;

  const classes = useStyles();
  const dataObj: PracticeType = { ...data };
  const all = modifyData(allData);
  const hide = dataObj.status === "APPROVED" || dataObj.status === "DENIED";
  const draft = dataObj.status === "DRAFT";

  const handleMessageField = (objectKey: string, newValue: boolean) => {
    setMessageField(handleActiveMessages(messageField, objectKey, newValue));
  };

  const singleMessageField = (objectKey: string) => {
    const message = messageField.find((item) => item.key === objectKey);
    return message?.active;
  };

  const getSelectField = (objectKey: string) => {
    return editable ? (
      <Box className={classes.selectWrapper}>
        <CustomAutocompleteSingle
          valueId={
            dataObj[objectKey] === ""
              ? null
              : {
                  id: findValueId(
                    all[objectKey],
                    dataObj[objectKey] as string
                  ) as number,
                  value: dataObj[objectKey] as string,
                }
          }
          items={all[objectKey]}
          id={`${objectKey}-select`}
          onChange={(e, newValue) => {
            changeSelect(newValue, objectKey);
          }}
        />
      </Box>
    ) : (
      getText(dataObj[objectKey] as string)
    );
  };

  const getInputField = (
    objectKey: string,
    title?: string,
    type?: "number",
    max?: number,
    multiline?: boolean
  ) => {
    return editable ? (
      <CustomInput
        id={`${objectKey}-input`}
        fullWidth
        type={type ? type : "text"}
        multiline={multiline}
        value={dataObj[objectKey] as number | string}
        onChange={(e) => {
          if (
            type === "number" &&
            max &&
            (+e.target.value < 0 || +e.target.value > 99999999999999.99)
          ) {
            handleMessageField(objectKey, true);
          } else if (type !== "number" && max && e.target.value.length > max) {
            handleMessageField(objectKey, true);
          }
          changeInput(e, objectKey, max);
        }}
      />
    ) : (
      getText(dataObj[objectKey] as string | number, title)
    );
  };

  const getSubtitle = (title: string, objectKey: string, required: boolean) => {
    return (
      <Typography
        variant="subtitle2"
        className={`
        ${
          !draft && !dataObj[objectKey] && !validated && editable && required
            ? classes.required
            : ""
        }
        ${
          draft &&
          validatedDraft &&
          !dataObj[objectKey] &&
          !validated &&
          required &&
          editable
            ? classes.required
            : ""
        }
        `}
      >
        {title}
        {required ? <span>*</span> : ""}
      </Typography>
    );
  };

  const getText = (value: string | number, title?: string) => {
    return (
      <Typography
        className={classes.text}
        variant={title === practiceDetailList.name ? "h6" : "body2"}
      >
        {value}
      </Typography>
    );
  };

  const getTextFromSelect = (value: AllData[] | string) => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return <Typography variant="body2">{value}</Typography>;
    } else {
      return value.map((it) => {
        return (
          <Typography variant="body2" key={it.id}>
            {it.value}
          </Typography>
        );
      });
    }
  };

  return (
    <>
      <Paper style={{ padding: "1.5rem" }}>
        <Header
          title={practiceDetailList.financials}
          editable={editable}
          onClick={handleEdit}
          validated={validateBtn}
          hide={hide || disableEditing}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {((findValueId(all.costs, dataObj.costs) === 7 &&
              !dataObj.costsComment) ||
              findValueId(all.costs, dataObj.costs) !== 7 ||
              editable) && (
              <>
                {getSubtitle(settingsList.financialCosts, "costs", true)}
                {getSelectField("costs")}
              </>
            )}
            {findValueId(all.costs, dataObj.costs) === 7 && (
              <>
                <Box mt={editable ? 2 : 0} />
                {getSubtitle(
                  !editable
                    ? dataObj.costsComment
                      ? settingsList.financialCosts
                      : ""
                    : practiceDetailList.specifyAmount,
                  "costsComment",
                  false
                )}
                <Box className={classes.selectWrapper}>
                  {getInputField("costsComment")}
                </Box>
              </>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography
              variant="subtitle2"
              className={`
              ${
                !draft && (!dataObj.sponsors || !dataObj.sponsors[0]) && !validated && editable
                  ? classes.required
                  : ""
              }
                ${
                  draft &&
                  validatedDraft &&
                  !dataObj.sponsors[0] &&
                  !validated &&
                  editable
                    ? classes.required
                    : ""
                }
                `}
            >
              {practiceDetailList.isSponsored}*
            </Typography>
            {editable ? (
              <Box className={classes.selectWrapper}>
                <CustomAutocomplete
                  valueId={dataObj.sponsors as AllData[]}
                  onChange={(e, newValue) =>
                    changeSelectMulti(newValue, "sponsors")
                  }
                  items={all.sponsors}
                  id="sponsors-select"
                />
              </Box>
            ) : (
              getTextFromSelect(dataObj.sponsors as AllData[])
            )}
          </Grid>
          {typeof dataObj.sponsors !== "string" &&
            (dataObj.sponsors as AllData[]).some(
              (item) => item.id === 2 || item.id === 3 || item.id === 4
            ) && (
              <Grid item sm={6} xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.message }}
                  open={singleMessageField("amount")}
                  onClose={() => handleMessageField("amount", false)}
                  title={messagesList.validNumber2}
                  placement="bottom-start"
                >
                  <div>
                    {getSubtitle(sponsorList.amount, "amount", true)}
                    {getInputField("amount", sponsorList.amount, "number", 14)}
                  </div>
                </Tooltip>
              </Grid>
            )}
          {typeof dataObj.sponsors !== "string" &&
            (dataObj.sponsors as AllData[]).some(
              (item) => item.id === 2 || item.id === 3 || item.id === 4
            ) && (
              <Grid item sm={12} xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.message }}
                  open={singleMessageField("financialAssistanceInfo")}
                  onClose={() =>
                    handleMessageField("financialAssistanceInfo", false)
                  }
                  title={messagesList.maxCharCount + "1500"}
                  placement="bottom-start"
                >
                  <div>
                    {getSubtitle(
                      sponsorList.moreInfo,
                      "financialAssistanceInfo",
                      true
                    )}
                    {getInputField(
                      "financialAssistanceInfo",
                      undefined,
                      undefined,
                      1500,
                      true
                    )}
                  </div>
                </Tooltip>
              </Grid>
            )}
          {typeof dataObj.sponsors !== "string" &&
            (dataObj.sponsors as AllData[]).some((item) => item.id === 2) && (
              <Grid item xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.message }}
                  open={singleMessageField("arguments")}
                  onClose={() => handleMessageField("arguments", false)}
                  title={messagesList.maxCharCount + "1500"}
                  placement="bottom-start"
                >
                  <div>
                    {getSubtitle(sponsorList.arguments, "arguments", true)}
                    {getInputField(
                      "arguments",
                      undefined,
                      undefined,
                      1500,
                      true
                    )}
                  </div>
                </Tooltip>
              </Grid>
            )}
          {typeof dataObj.sponsors !== "string" &&
            (dataObj.sponsors as AllData[]).some((item) => item.id === 3) && (
              <Grid item xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.message }}
                  open={singleMessageField("funding")}
                  onClose={() => handleMessageField("funding", false)}
                  title={messagesList.maxCharCount + "1500"}
                  placement="bottom-start"
                >
                  <div>
                    {getSubtitle(sponsorList.sites, "funding", true)}
                    {getInputField("funding", undefined, undefined, 1500, true)}
                  </div>
                </Tooltip>
              </Grid>
            )}
          {typeof dataObj.sponsors !== "string" &&
            (dataObj.sponsors as AllData[]).some((item) => item.id === 4) && (
              <Grid item xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.message }}
                  open={singleMessageField("advertising")}
                  onClose={() => handleMessageField("advertising", false)}
                  title={messagesList.maxCharCount + "1500"}
                  placement="bottom-start"
                >
                  <div>
                    {getSubtitle(sponsorList.advertising, "advertising", true)}
                    {getInputField(
                      "advertising",
                      undefined,
                      undefined,
                      1500,
                      true
                    )}
                  </div>
                </Tooltip>
              </Grid>
            )}
          {dataObj.effectiveness || editable ? (
            <Grid item xs={12}>
              <Tooltip
                classes={{ tooltip: classes.message }}
                open={singleMessageField("effectiveness")}
                onClose={() => handleMessageField("effectiveness", false)}
                title={messagesList.maxCharCount + "200"}
                placement="bottom-start"
              >
                <div>
                  {getSubtitle(
                    practiceDetailList.review,
                    "effectiveness",
                    false
                  )}
                  {getInputField(
                    "effectiveness",
                    practiceDetailList.review,
                    undefined,
                    200,
                    true
                  )}
                </div>
              </Tooltip>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Box mb={2} />
      </Paper>
      <Box mb={3} />
    </>
  );
};

export default Finance;
