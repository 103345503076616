import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },

  subTitle2: {
    fontSize: "0.95rem",
    marginBottom: theme.spacing(2),
    letterSpacing: "2px",
  },

  listItem: {
    display: "flex",
    flexDirection: "column",
  },

  addBtn: {
    textTransform: "none",
  },

  editBtn: {
    width: "36px",
    maxHeight: "36px",
  },

  addBtnSmall: {
    "& span > svg": {
      [theme.breakpoints.down(300)]: {
        height: "20px",
        width: "20px",
      },
    },
  },

  sectionWrapper: {
    [theme.breakpoints.down(400)]: {
      marginLeft: 0,
    },
  },

  boxBg: {
    borderRadius: "4px",
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.secondary.contrastText,
    padding: "4px 8px",
    margin: "2px",
    marginTop: 0,
    "& p": {
      fontSize: "0.8rem",
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
      fontWeight: 700,
    },
  },

  loader: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  roleWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexDirection:"column"
    },
  },

  roleWrapperInner: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  }
}));
