export const settings = {
  de: {
    topic: "Thema",
    categories: "Kategorien",
    objectives: "Zielsetzung",
    personsInvolved: "Beteiligte Personen",
    duration: "Dauer",
    scope: "Umfang",
    financialCosts: "Finanzielle Kosten",
    sponsors: "Sponsoren",
    funding: "Fördermittel",
    clubType: "Vereinstyp",
    noOfMembers: "Mitgliederanzahl",
    noOfTeams: "Anzahl Teams",
    infrastructure: "Infrastruktur",
    consent: "Einwilligungen",
    displayed: "Angezeigte Auswahlmöglichkeiten",
    addAnother: "weiteres Feld hinzufügen...",
    choices: "Auswahlmöglichkeit",
    inputs: "Eingabe",
    forChoice: "Bei Auswahl",
    marketingConsent: "Marketingeinverständniserklärung",
    mandatoryOptional: "Pflichtfeld / Optional"

  },
  
  en: {
    topic: "Topic",
    categories: "Categories",
    objectives: "Objectives",
    personsInvolved: "Persons involved",
    duration: "Duration",
    scope: "Scope",
    financialCosts: "Financial Costs",
    sponsors: "Sponsors",
    funding: "Funding",
    clubType: "Club Type",
    noOfMembers: "Number of Members",
    noOfTeams: "Number of Teams",
    infrastructure: "Infrastructure",
    consent: "Consent",
    displayed: "Displayed Choices",
    addAnother: "add another field...",
    choices: "Choices",
    inputs: "Inputs",
    forChoice: "For choice",
    marketingConsent: "Marketing consent",
    mandatoryOptional: "Mandatory / Optional"
  },
};