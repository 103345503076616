import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },

  title: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },

  logo: {
    [theme.breakpoints.down("xs")]: {
      width: '35px',
      height: '35px',
    }
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  avatar: {
    cursor: 'pointer',
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
