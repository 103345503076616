import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: {
      fontWeight: 700,
      fontSize: "1rem",
      [theme.breakpoints.down('xs')]: {
        fontWeight: 400
      },
  },

  buttons: {
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
  },

  buttonsBg: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.disabled,
    '&:hover': {
      backgroundColor: theme.palette.text.secondary,
    }
  }
}));
