import React, { FC, useContext, useEffect, useState } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../../context/LanguageContext";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import * as Icon from "../../../utils/icons";
import CustomAccardion from "../../CustomAccardion/CustomAccardion";
import CustomInput from "../../CustomInput/CustomInput";
import CustomAlert from "../../CustomAlert/CustomAlert";
import OptionMenu from "../../OptionMenu/OptionMenu";
import {
  handleCheckboxValueChange,
  deleteSingleFunction,
  addSingleFunction,
  editSingleFunction,
  handleInputValueChange,
} from "../../../utils/settingFunctions";
import { post, postRequest } from "../../../utils/apiRequests";
import { categoryAdd, categoryUpdate } from "../../../apiURL/httpCategory";
import { objectiveAdd, objectiveUpdate } from "../../../apiURL/httpObjective";
import {
  personsInvolvedAdd,
  personsInvolvedUpdate,
} from "../../../apiURL/httpPersonsInvolved";
import { durationAdd, durationUpdate } from "../../../apiURL/httpDuration";
import { costsAdd, costsUpdate } from "../../../apiURL/httpCosts";
import { teamTypeAdd, teamTypeUpdate } from "../../../apiURL/httpTeamType";
import { membersAdd, membersUpdate } from "../../../apiURL/httpMembers";
import {
  teamCompositionAdd,
  teamCompositionUpdate,
} from "../../../apiURL/httpTeamComposition";
import { useStyles } from "./SettingsWithCheckboxesStyles";
import { marketingConsentSet } from "../../../apiURL/httpMarketingConsent";

interface SettingData {
  id: number | string;
  name: string;
  active: boolean;
}

interface ButtonData {
  btnLabel?: string;
  btnHandleClick?: (e: React.MouseEvent<HTMLElement>, exp: boolean) => void;
  btnIcon?: JSX.Element;
  btnStyle?: "contained" | "outlined";
  btnColor?: "secondary" | "primary" | "default";
}

type Props = {
  settingData: SettingData[];
  title: string;
  hideSubtitle?: boolean;
  disableFunctions?: boolean;
};

const SettingsWithCheckboxes: FC<Props> = ({
  settingData,
  title,
  hideSubtitle,
  disableFunctions,
}) => {
  const [data, setData] = useState(settingData); // original data
  const [mockData, setMockData] = useState(settingData); // changing on inputs
  const [isEditable, setIsEditable] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [newChValue, setNewChValue] = useState(false);
  const [newInputs, setNewInputs] = useState<SettingData[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [
    optionMenuAnchorEl,
    setOptionMenuAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const isOptionMenuOpen = Boolean(optionMenuAnchorEl);

  const classes = useStyles();
  const { buttonList, settingsList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  useEffect(() => {
    setMockData(settingData);
    setData(settingData);
  }, [settingData]);

  const handleEdit = (e: any, exp?: boolean) => {
    (exp === true || exp === undefined || isEditable) && e.stopPropagation();
    setIsEditable(!isEditable);
    if (isEditable) {
      const newData = editSingleFunction(data, mockData);
      const newNames = newInputs.map((item) => ({ name: item.name }));

      const makeSuccess = () => {
        setSuccess(true);
        setErrorMessage(messagesList.successfulSave);
        const merged = newData.concat(newInputs);
        setData(merged);
        setMockData(merged);
        setNewInputs([]);
      };

      const makeFailed = () => {
        setSuccess(false);
        setErrorMessage(messagesList.unableToSave);
      };
      switch (title) {
        case settingsList.categories:
          Promise.all([
            postRequest(categoryUpdate, newData),
            postRequest(categoryAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.objectives:
          Promise.all([
            postRequest(objectiveUpdate, newData),
            postRequest(objectiveAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.personsInvolved:
          Promise.all([
            postRequest(personsInvolvedUpdate, newData),
            postRequest(personsInvolvedAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.duration:
          Promise.all([
            postRequest(durationUpdate, newData),
            postRequest(durationAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.financialCosts:
          Promise.all([
            postRequest(costsUpdate, newData),
            postRequest(costsAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.clubType:
          Promise.all([
            postRequest(teamTypeUpdate, newData),
            postRequest(teamTypeAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.noOfMembers:
          Promise.all([
            postRequest(membersUpdate, newData),
            postRequest(membersAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.noOfTeams:
          Promise.all([
            postRequest(teamCompositionUpdate, newData),
            postRequest(teamCompositionAdd, newNames),
          ])
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        case settingsList.marketingConsent:
          post(marketingConsentSet(settingData[0].active))
            .then(() => makeSuccess())
            .catch(() => makeFailed());
          break;
        default:
          break;
      }
    }
  };

  const handleCancelBtn = (e: any) => {
    e.stopPropagation();
    setIsEditable(!isEditable);
    setMockData(data);
    setNewInputs([]);
    setNewValue("");
  };

  const saveNewValue = () => {
    if (newValue) {
      const lastId = +data[data.length - 1].id;
      setNewInputs(addSingleFunction(newInputs, newValue, lastId));
    }
    setNewValue("");
  };

  const handleDelete = (id: number | string) => {
    setNewInputs(deleteSingleFunction(newInputs, id));
  };

  const handleOptionMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOptionMenuAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setOptionMenuAnchorEl(null);
  };

  const optionMenuId = "option-menu";
  const moreOptionsData = [
    {
      name: isEditable ? buttonList.save : buttonList.edit,
      icon: isEditable ? (
        <Icon.Save fontSize="small" />
      ) : (
        <Icon.Edit fontSize="small" />
      ),
      handleClick: function (e: React.MouseEvent<HTMLElement>) {
        setIsEditable(!isEditable);
        handleEdit(e);
        handleMenuClose();
      },
    },
  ];
  const moreOptionsDataEdit = [
    {
      name: buttonList.cancel,
      icon: <Icon.DeleteIcon fontSize="small" />,
      handleClick: function (e: React.MouseEvent<HTMLElement>) {
        setIsEditable(!isEditable);
        handleCancelBtn(e);
        handleMenuClose();
      },
    },
    {
      name: isEditable ? buttonList.save : buttonList.edit,
      icon: isEditable ? (
        <Icon.Save fontSize="small" />
      ) : (
        <Icon.Edit fontSize="small" />
      ),
      handleClick: function (e: React.MouseEvent<HTMLElement>) {
        setIsEditable(!isEditable);
        handleEdit(e);
        handleMenuClose();
      },
    },
  ];

  const buttonDataEdit: ButtonData[] = [
    {
      btnLabel: buttonList.cancel,
      btnHandleClick: function (e: React.MouseEvent<HTMLElement>) {
        handleCancelBtn(e);
      },
      btnIcon: <Icon.DeleteIcon />,
      btnStyle: "outlined",
      btnColor: "default",
    },
    {
      btnLabel: !isEditable ? buttonList.edit : buttonList.save,
      btnHandleClick: function (
        e: React.MouseEvent<HTMLElement>,
        exp: boolean
      ) {
        handleEdit(e, exp);
      },
      btnIcon: !isEditable ? <Icon.Edit /> : <Icon.Save />,
      btnStyle: isEditable ? "contained" : "outlined",
      btnColor: !isEditable ? "secondary" : "default",
    },
  ];
  const buttonData: ButtonData[] = [
    {
      btnLabel: !isEditable ? buttonList.edit : buttonList.save,
      btnHandleClick: function (
        e: React.MouseEvent<HTMLElement>,
        exp: boolean
      ) {
        handleEdit(e, exp);
      },
      btnIcon: !isEditable ? <Icon.Edit /> : <Icon.Save />,
      btnStyle: !isEditable ? "contained" : "outlined",
      btnColor: !isEditable ? "secondary" : "default",
    },
  ];

  return (
    <>
      <CustomAccardion
        title={title}
        subTitle={hideSubtitle ? "" : settingsList.displayed}
        handleOptionMenuOpen={(e) => handleOptionMenuOpen(e)}
        optionMenuId={optionMenuId}
        buttonData={isEditable ? buttonDataEdit : buttonData}
      >
        <Grid container>
          {mockData.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}
                key={item.id + "key"}
                className={classes.gridRoot}
              >
                <FormControlLabel
                  className={classes.listItem}
                  control={
                    <Checkbox
                      id={item.id + "checkBox"}
                      className={!isEditable ? classes.checkBox : ""}
                      disabled={!isEditable}
                      checked={item.active}
                      onChange={(e) => {
                        const current = handleCheckboxValueChange(
                          mockData,
                          item.id,
                          e.target.checked
                        );
                        setMockData(current as SettingData[]);
                      }}
                      color="secondary"
                    />
                  }
                  label={
                    !disableFunctions && isEditable ? (
                      <CustomInput
                        id={item.id + "customInput" + title}
                        value={item.name}
                        fullWidth
                        onChange={(e) => {
                          const current = handleInputValueChange(
                            mockData,
                            item.id,
                            e.target.value
                          );
                          setMockData(current as SettingData[]);
                        }}
                      />
                    ) : (
                      <Box
                        className={
                          title === settingsList.noOfTeams
                            ? classes.textBackground
                            : ""
                        }
                      >
                        {item.name}
                      </Box>
                    )
                  }
                />
              </Grid>
            );
          })}
          {newInputs.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}
                key={item.id + "key"}
              >
                <FormControlLabel
                  className={`${classes.listItem} ${classes.listItem2}`}
                  control={
                    <Checkbox
                      id={item.id + "checkBox"}
                      className={!isEditable ? classes.checkBox : ""}
                      disabled={true}
                      checked={item.active}
                      onChange={(e) => {
                        const current = handleCheckboxValueChange(
                          newInputs,
                          item.id,
                          e.target.checked
                        );
                        setNewInputs(current as SettingData[]);
                      }}
                      color="secondary"
                    />
                  }
                  label={
                    isEditable ? (
                      <CustomInput
                        id={item.id + "customInput" + title}
                        value={item.name}
                        fullWidth
                        onChange={(e) => {
                          const current = handleInputValueChange(
                            newInputs,
                            item.id,
                            e.target.value
                          );
                          setNewInputs(current as SettingData[]);
                        }}
                      />
                    ) : (
                      <Box
                        className={
                          title === settingsList.noOfTeams
                            ? classes.textBackground
                            : ""
                        }
                      >
                        {item.name}
                      </Box>
                    )
                  }
                />
                {isEditable && (
                  <IconButton
                    className={classes.deleteButton}
                    size="small"
                    onClick={() => handleDelete(item.id)}
                  >
                    <Icon.DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            );
          })}
          {!disableFunctions && (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <FormControlLabel
                className={`${classes.listItem}
              ${classes.new}`}
                control={
                  <Checkbox
                    disabled={true}
                    value={newChValue}
                    onChange={(e) => {
                      setNewChValue(e.target.checked);
                    }}
                    id={"new_checkBox" + title}
                    color="secondary"
                  />
                }
                label={
                  <CustomInput
                    value={newValue}
                    id={"new_input" + title}
                    placeholder={settingsList.addAnother}
                    onChange={(e) => {
                      setNewValue(e.target.value);
                      !isEditable && setIsEditable(true);
                    }}
                    transparent
                    fullWidth
                    icon={<Icon.KeyboardReturnIcon />}
                    iconClick={saveNewValue}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      </CustomAccardion>
      <OptionMenu
        anchorEl={optionMenuAnchorEl}
        menuId={optionMenuId}
        isOpen={isOptionMenuOpen}
        handleClose={handleMenuClose}
        items={isEditable ? moreOptionsDataEdit : moreOptionsData}
      />
      <CustomAlert
        open={errorMessage ? true : false}
        onClose={() => setErrorMessage("")}
        severity={success ? "success" : "error"}
        message={errorMessage}
      />
    </>
  );
};

export default SettingsWithCheckboxes;
