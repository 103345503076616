import React, { createContext, useState, FC } from "react";
import { menu, options, buttons, practiceDetail, userDetail, settings, 
  pageTitles, messages, sponsors, statuses, roles, consent, templates } from '../locales/translations';
import { Menu, Options, Buttons, PracticeDetail, UserDetail, Settings, 
  PageTitles, Messages, Sponsors, Statuses, Roles, Consent, Templates } from '../locales/types';

export const LanguageContext = createContext<LanguageContextType>(null!);

export type LanguageContextType = {
  setSiteLanguage: React.Dispatch<React.SetStateAction<boolean>>,
  siteLanguage: boolean,
  menuList: Menu,
  optionList: Options,
  buttonList: Buttons,
  practiceDetailList: PracticeDetail,
  userDetailList: UserDetail,
  settingsList: Settings,
  pageTitleList: PageTitles,
  messagesList: Messages,
  sponsorList: Sponsors,
  statusList: Statuses,
  roleList: Roles,
  consentList: Consent,
  templatesList: Templates
}

export const LanguageProvider:FC = ({ children }) => {
  const [siteLanguage, setSiteLanguage] = useState(true);
  
  const menuList = siteLanguage === true ? menu.de : menu.en;
  const optionList = siteLanguage === true ? options.de : options.en;
  const buttonList = siteLanguage === true ? buttons.de : buttons.en;
  const practiceDetailList = siteLanguage === true ? practiceDetail.de : practiceDetail.en;
  const userDetailList = siteLanguage === true ? userDetail.de : userDetail.en;
  const settingsList = siteLanguage === true ? settings.de : settings.en;
  const pageTitleList = siteLanguage === true ? pageTitles.de : pageTitles.en;
  const messagesList = siteLanguage === true ? messages.de : messages.en;
  const sponsorList = siteLanguage === true ? sponsors.de : sponsors.en;
  const statusList = siteLanguage === true ? statuses.de : statuses.en;
  const roleList = siteLanguage === true ? roles.de : roles.en;
  const consentList = siteLanguage === true ? consent.de : consent.en;
  const templatesList = siteLanguage === true ? templates.de : templates.en;


  return (
    <LanguageContext.Provider
      value={{
        menuList,
        optionList,
        buttonList,
        practiceDetailList,
        userDetailList,
        setSiteLanguage,
        siteLanguage,
        settingsList,
        pageTitleList,
        messagesList,
        sponsorList,
        statusList,
        roleList,
        consentList,
        templatesList
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
