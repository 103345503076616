import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as Icon from "../../utils/icons";
import { useStyles } from "./SearchBarStyles";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  id: string;
};

const SearchBar: FC<Props> = ({ value, onChange, label, id }) => {
  const classes = useStyles();

  return (
      <TextField
        id={id}
        label={label}
        variant="filled"
        className={classes.searchBar}
        onChange={onChange}
        value={value}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className="searchIcon">
              <Icon.SearchIcon color="disabled" />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
  );
};

export default SearchBar;