import React, { useContext, useState, FC } from "react";
import axios from "axios";
import { LanguageContext, LanguageContextType } from "../../context/LanguageContext";
import { ThemeContext, ThemeContextType } from "../../context/ThemeContext";
import { TitleContext, TitleContextType } from "../../context/TitleContext";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import defaultImage from "../../assets/images/user.jpg";

import * as Icon from "../../utils/icons";
import CustomAlert from "../CustomAlert/CustomAlert";
import OptionMenu from "../OptionMenu/OptionMenu";
import logo from "../../assets/images/DFB_Logo.svg";
import { logout } from "../../apiURL/httpAuth";
import { useStyles } from "./HeaderStyles";

type Props = {
  handleDrawerOpen: () => void;
};

const Header: FC<Props> = ({ handleDrawerOpen }) => {
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<null | HTMLElement>(null);

  const { optionList, messagesList, setSiteLanguage } = useContext(LanguageContext) as LanguageContextType;
  const { siteTheme, setSiteTheme } = useContext(ThemeContext) as ThemeContextType;
  const { siteTitle } = useContext(TitleContext) as TitleContextType;

  const isMenuOpen = Boolean(anchorEl);
  const isLangMenuOpen = Boolean(langMenuAnchorEl);
  const classes = useStyles();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleLangMenuClose();
  };

  const handleLangMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setLangMenuAnchorEl(null);
  };

  const handleThemeSwitch = () => {
    setSiteTheme(!siteTheme);
    handleMenuClose();
  };

  // profile dropdown
  const menuId = "profile-menu";
  const profileData = [
    {
      name: optionList.language,
      icon: <Icon.LanguageIcon fontSize="small" />,
      handleClick: function (event: React.MouseEvent<HTMLElement>) {
        return handleLangMenuOpen(event);
      },
    },
    {
      name: siteTheme ? optionList.darkTheme : optionList.lightTheme,
      icon: <Icon.Brightness4 fontSize="small" />,
      handleClick: function () {
        return handleThemeSwitch();
      },
    },
    {
      name: optionList.signOut,
      icon: <Icon.ExitToApp fontSize="small" />,
      handleClick: function () {
        handleMenuClose();
        axios.get(logout, {withCredentials: true})
        .then((res) => {
          window.location.href = res.headers["custom-location"]
        })
        .catch(() => {
          setError(true)
        })
      },
    },
  ];

  // language dropdown
  const langMenuId = "language-menu";
  const languageData = [
    {
      name: optionList.english,
      handleClick: function() {
        setSiteLanguage(false);
        handleMenuClose();
      },
    },
    {
      name: optionList.german,
      handleClick: function(){
        setSiteLanguage(true);
        handleMenuClose();
      },
    },
  ];

  return (
    <div className={classes.grow}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerOpen()}
        >
          <Icon.MenuIcon />
        </IconButton>
        <img src={logo} alt="DFB-logo" className={classes.logo} />
        <Typography className={classes.title} variant="h6" noWrap>
          {siteTitle}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <Avatar
            alt="User"
            src={defaultImage}
            aria-label="user-account"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            className={classes.avatar}
          />
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
          >
            <Icon.MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
      <OptionMenu
        anchorEl={anchorEl}
        menuId={menuId}
        isOpen={isMenuOpen}
        handleClose={handleMenuClose}
        items={profileData}
        styles={true}
      />
      <OptionMenu
        anchorEl={langMenuAnchorEl}
        menuId={langMenuId}
        isOpen={isLangMenuOpen}
        handleClose={handleLangMenuClose}
        items={languageData}
      />
      <CustomAlert
        open={error}
        onClose={() => setError(false)}
        severity="error"
        message={messagesList.failedToLogout}
      />
    </div>
  );
};

export default Header;