import { PracticeType } from "../../types/practiceType";

export const initialValues: PracticeType = {
  advertising: "",
  amount: "",
  dateApproved: null,
  approvedBy: null,
  arguments: "",
  cabins: 0,
  cabinType: [],
  consent: false,
  categoryId: 0,
  clubhouseAvailable: "",
  costs: "",
  costsComment: "",
  description: "",
  duration: "",
  dateEdited: null,
  editedBy: null,
  effectiveness: "",
  financialAssistanceInfo: "",
  funding: "",
  id: 0,
  members: "",
  nrOfPitches: 0,
  nrOfTeams: 0,
  objective: [],
  ownership: "",
  personsInvolved: "",
  pitchType: [],
  dateRejected: null,
  rejectedBy: null,
  scope: 0,
  scopeAmount: "",
  scopePerUnit: "",
  sponsors: [],
  startingPosition: "",
  status: "",
  submitted: "",
  submitterId: 0,
  dateCreated: null,
  teamComposition: [],
  club: 0,
  teamType: "",
  title: "",
  fileDTOS: [],
  teamName: "Name"
};
