import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  addButton: {
    position: "fixed",
    right: "3.3rem",
    bottom: "3rem",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    padding: 0,
    minWidth: "auto",
    [theme.breakpoints.down("xs")]: {
      right: "1.5rem",
      bottom: "2rem",
      width: "40px",
      height: "40px",
    },
    zIndex: 999,
  },
}));
