/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, FC } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import * as Icon from "../../utils/icons";
import defaultImage from "../../assets/images/user.jpg";
import CustomInput from "../CustomInput/CustomInput";
import { useStyles } from "./UserDetailProfileStyles";
import { UserType } from "../../types/userType";

type Props = {
  data: UserType;
  hideClub?: boolean;
  disableEdit?: boolean;
};

const UserDetailProfile: FC<Props> = ({ data, hideClub, disableEdit }) => {
  const [isEditableProfile, setIsEditableProfile] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [telephoneValue, setTelephoneValue] = useState("");
  const [clubValue, setClubValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [fileValue, setFileValue] = useState<FileList | null>(null);

  const { buttonList, userDetailList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const classes = useStyles();
  const dataObj = data;

  useEffect(() => {
    if (dataObj) {
      setFirstNameValue(dataObj.name);
      setLastNameValue(dataObj.surname);
      setEmailValue(dataObj.email);
      setTelephoneValue(dataObj.phone);
      setClubValue(dataObj.club);
      if (dataObj.clubLocation) {
        setLocationValue(dataObj.clubLocation);
      }
    }
  }, []);

  const handleEdit = () => {
    setIsEditableProfile(!isEditableProfile);
  };

  return (
    <Paper elevation={1} style={{ padding: "1.5rem" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="subtitle1">{userDetailList.profile}</Typography>
        {disableEdit ? (
          ""
        ) : (
          <Button
            size="small"
            color="default"
            startIcon={isEditableProfile ? <Icon.Save /> : <Icon.Edit />}
            onClick={handleEdit}
          >
            {isEditableProfile ? buttonList.save : buttonList.edit}
          </Button>
        )}
      </Box>
      <Divider />
      <Box display="flex" flexWrap="wrap" className={classes.container}>
        {/* Profile picture */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexGrow={0}
          mr={3}
          mt={2}
          className={classes.content}
        >
          <Box className={classes.imgWrapper} mb={isEditableProfile ? 2 : 0}>
            <img
              className={classes.img}
              src={defaultImage}
              alt={`${firstNameValue} ${lastNameValue}`}
            />
          </Box>
          {isEditableProfile && (
            <>
              <Button
                color="secondary"
                startIcon={<Icon.Publish />}
                variant="contained"
                component="label"
                htmlFor="hi"
              >
                {buttonList.upload}
                <input
                  id="hi"
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    if (e.target.files) {
                      if (e.target.files[0]) {
                        setFileValue(e.target.files);
                      }
                    }
                  }}
                />
              </Button>
              {fileValue && (
                <Box mt={1} display="flex" alignItems="center">
                  <Typography variant="body2">{fileValue[0].name}</Typography>
                  <Icon.DeleteIcon
                    style={{ marginLeft: "0.3rem", cursor: "pointer" }}
                    onClick={() => setFileValue(null)}
                    fontSize="small"
                  />
                </Box>
              )}
            </>
          )}
        </Box>
        {/* Personal info */}
        <Box mr={2} flexGrow={1} mt={2}>
          <Typography className={classes.subTitle2} variant="subtitle2">
            {userDetailList.personalInfo}
          </Typography>
          {firstNameValue && (
            <Box mb={isEditableProfile ? 2 : 1}>
              <Typography variant="subtitle2">
                {userDetailList.firstName}
              </Typography>
              {isEditableProfile ? (
                <Box className={classes.inputWrapper}>
                  <CustomInput
                    id="firstName-input"
                    value={firstNameValue}
                    onChange={(e) => {
                      setFirstNameValue(e.target.value);
                    }}
                    fullWidth
                  />
                </Box>
              ) : (
                <Typography variant="body2">{firstNameValue}</Typography>
              )}
            </Box>
          )}
          {lastNameValue && (
            <Box mb={isEditableProfile ? 2 : 1}>
              <Typography variant="subtitle2">
                {userDetailList.lastName}
              </Typography>
              {isEditableProfile ? (
                <Box className={classes.inputWrapper}>
                  <CustomInput
                    id="lastName-input"
                    value={lastNameValue}
                    onChange={(e) => {
                      setLastNameValue(e.target.value);
                    }}
                    fullWidth
                  />
                </Box>
              ) : (
                <Typography variant="body2">{lastNameValue}</Typography>
              )}
            </Box>
          )}
          <Box mb={isEditableProfile ? 2 : 1}>
            <Typography variant="subtitle2">{userDetailList.email}</Typography>
            {isEditableProfile ? (
              <Box className={classes.inputWrapper}>
                <CustomInput
                  id="email-input"
                  value={emailValue}
                  fullWidth
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                  }}
                />
              </Box>
            ) : (
              <Typography variant="body2">{emailValue}</Typography>
            )}
          </Box>
          {telephoneValue && (
            <Box>
              <Typography variant="subtitle2">
                {userDetailList.telephone}
              </Typography>
              {isEditableProfile ? (
                <Box className={classes.inputWrapper}>
                  <CustomInput
                    id="number-input"
                    value={telephoneValue}
                    fullWidth
                    onChange={(e) => {
                      setTelephoneValue(e.target.value);
                    }}
                  />
                </Box>
              ) : (
                <Typography variant="body2">{telephoneValue}</Typography>
              )}
            </Box>
          )}
        </Box>
        {/* Club */}
        {hideClub === true || (!clubValue && !locationValue)
          ? ""
          : !isEditableProfile && (
              <Box flexGrow={1} mt={2}>
                <Typography className={classes.subTitle2} variant="subtitle2">
                  {userDetailList.club}
                </Typography>
                {clubValue && (
                  <Box mb={isEditableProfile ? 2 : 1}>
                    <Typography variant="subtitle2">
                      {userDetailList.name}
                    </Typography>
                    <Typography variant="body2">{clubValue}</Typography>
                  </Box>
                )}
                {locationValue && (
                  <Box mb={isEditableProfile ? 2 : 1}>
                    <Typography variant="subtitle2">
                      {userDetailList.location}
                    </Typography>
                    <Typography variant="body2">{locationValue}</Typography>
                  </Box>
                )}
              </Box>
            )}
      </Box>
    </Paper>
  );
};

export default UserDetailProfile;
