export const menu = {
  de: {
    home: "Startseite",
    praxisprofi: "Praxisprofi",
    cases: "Best Practices",
    overview: "Übersicht",
    templates: "Vorlagen",
    users: "Nutzer",
    settings: "Einstellungen",
    lottery: "Gewinnspiel",
    lotteries: "Gewinnspiele",
    system: "System",
    general: "Allgemeines",
    access: "Zugriff",
  },
  
  en: {
    home: "Home",
    praxisprofi: "Praxisprofi",
    cases: "Best Practices",
    overview: "Overview",
    templates: "Templates",
    users: "Users",
    settings: "Settings",
    lottery: "Lottery",
    lotteries: "Lotteries",
    system: "System",
    general: "General",
    access: "Access",
  },
};