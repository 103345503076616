type DataObj = {
  [key: string]: {
    id: number | string;
    name: string;
    active: boolean;
    options?: { id: number; value: string }[];
    activeOption?: number | string;
  }[];
};

type DataObj2 = {
  id: number | string;
  name: string;
  active: boolean;
  options?: { id: number; value: string }[];
  activeOption?: number | string;
}[];

// delete (with multiple sections) - settingsWithOptions / settingsWithSections
export const deleteFunction = (
  obj: DataObj,
  name: string,
  id: number | string
) => {
  const index = obj[name].findIndex((it) => it.id === id);
  const length = obj[name].length;
  const rest = length - 1 - index;

  const newArr = obj[name].filter((item) => item.id !== id);

  let counter = 0;
  for (let i = index; counter < rest; i++) {
    newArr[i].id = Number(id) + counter;
    counter++;
  }
  
  const newObj = { ...obj };
  newObj[name] = newArr;
  return newObj;
};

// delete (with single section) - settingsWithCheckboxes
export const deleteSingleFunction = (obj: DataObj2, id: number | string) => {
  const index = obj.findIndex((it) => it.id === id);
  const length = obj.length;
  const rest = length - 1 - index;
  
  const newArr = obj.filter((item) => item.id !== id);
  
  let counter = 0;
  for (let i = index; counter < rest; i++) {
    newArr[i].id = Number(id) + counter;
    counter++;
  }

  return newArr;
};

// add new - settingsWithSections
export const addFunction = (obj: DataObj, name: string, newName: string, lastId:number) => {
  const newArr = [
    ...obj[name],
    {
      id: obj[name].length + 1 + lastId,
      name: newName,
      active: true,
    },
  ];
  const newObj = { ...obj };
  newObj[name] = newArr;
  return newObj;
};

// add new - settingsWithCheckboxes
export const addSingleFunction = (
  obj: DataObj2,
  newName: string,
  lastId: number
) => {
  const newObj = [
    ...obj,
    {
      id: obj.length + 1 + lastId,
      name: newName,
      active: true,
    },
  ];
  return newObj;
};

// edit - settingsWithCheckboxes
export const editSingleFunction = (
  originalData: DataObj2,
  newData: DataObj2
) => {
  const changedData = originalData.map((item) => {
    newData.forEach((it) => {
      if (
        item.id === it.id &&
        (item.name !== it.name || item.active !== it.active)
      ) {
        item.active = it.active;
        item.name = it.name;
      }
    });
    return item;
  });

  return changedData;
};

// edit - settingsWithSections
export const editFunction = (originalData: DataObj, newData: DataObj) => {
  const keys = Object.keys(originalData);
  const clone: DataObj = JSON.parse(JSON.stringify(originalData));

  keys.forEach((key) => {
    clone[key].forEach((item) => {
      newData[key].forEach((it) => {
        if (
          item.id === it.id &&
          (item.name !== it.name || item.active !== it.active)
        ) {
          item.active = it.active;
          item.name = it.name;
        }
      });
    });
  });
  return clone;
};

// handle input value change - settingsWithCheckboxes
export const handleInputValueChange = (
  obj: DataObj2,
  id: number | string,
  newValue: string
) => {
  if (typeof obj === "boolean") {
    return;
  }
  const clone: DataObj2 = JSON.parse(JSON.stringify(obj));
  const newData = clone.map((item) => {
    item.id === id && (item.name = newValue);
    return item;
  });
  return newData;
};

export const handleCheckboxValueChange = (
  obj: DataObj2,
  id: number | string,
  newValue: boolean
) => {
  if (typeof obj === "boolean") {
    return;
  }
  const clone: DataObj2 = JSON.parse(JSON.stringify(obj));
  const newData = clone.map((item) => {
    if (item.id === id) {
      item.active = newValue;
    }
    return item;
  });
  return newData;
};

// handle input value change - settingsWithSections
export const handleInputValueChange2 = (
  obj: DataObj,
  id: number | string,
  name: string,
  newValue: string
) => {
  const clone: DataObj = JSON.parse(JSON.stringify(obj));
  const newData = clone[name].map((item) => {
    item.id === id && (item.name = newValue);
    return item;
  });

  clone[name] = newData;
  return clone;
};

export const handleSelectValueChange = (
  obj: DataObj,
  id: number | string,
  name: string,
  newValue: number | string
) => {
  const clone: DataObj = JSON.parse(JSON.stringify(obj));
  const newData = clone[name].map((item) => {
    item.id === id && (item.activeOption = +newValue);
    return item;
  });
  clone[name] = newData;
  return clone;
};

export const handleCheckboxValueChange2 = (
  obj: DataObj,
  id: number | string,
  name: string,
  newValue: boolean
) => {
  const clone: DataObj = JSON.parse(JSON.stringify(obj));
  const newData = obj[name].map((item) => {
    item.id === id && (item.active = newValue);
    return item;
  });
  clone[name] = newData;
  return clone;
};
