import React, { createContext, useState, FC } from "react";
import { useEffect } from "react";
import { usersGetCurrent } from "../apiURL/httpUsers";
import Error from "../components/Error/Error";
import { UserType } from '../types/userType';
import { getRequest } from "../utils/apiRequests";

export const UserContext = createContext<UserContextType>(null!);

export type UserContextType = {
  currentUser: UserType,
  isAdmin: boolean,
  userCategories: number[],
  userId: number
}

export const UserProvider:FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<UserType>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRequest(usersGetCurrent)
    .then((res) => {
      setCurrentUser(res);
      setLoading(false)
    })
    .catch((err) => {
      if(!err.response) {
        setError(true);
        setLoading(false);
      }
    }) 
  }, [])

  if (loading || error || !currentUser) {
    return <Error error={error} loading={loading} errorMessage="Server error, please try again later" />
  }
  
  const isAdmin = currentUser.userRoles.find(role => role.name === "ADMIN") ? true : false;
  const userCategories = currentUser.categoryDTOSet.map(it => it.id);
  const userId = currentUser.id;

  return (
    <UserContext.Provider
      value={{
        currentUser,
        isAdmin,
        userCategories,
        userId
      }}
    >
      {children}
    </UserContext.Provider>
  );
};