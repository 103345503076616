import React, { FC, useState } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import * as Icon from "../../utils/icons";
import { useStyles } from "./CustomAccardionStyles";
import { withStyles } from "@material-ui/core/styles";

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down(300)]: {
      padding: theme.spacing(1),
    },
  },
}))(MuiAccordionDetails);

const Accordion = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    boxShadow: `0px 3px 6px ${theme.palette.grey.A100}`,
    marginBottom: theme.spacing(2),
    "&:before": {
      display: "none",
    },
  },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    borderTop: "none",
    minHeight: 56,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      boxShadow: `0px 3px 6px ${theme.palette.grey.A100}`,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

type Props = {
  title: string;
  subTitle?: string;
  optionMenuId?: string;
  handleOptionMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  buttonData?: {
    btnLabel?: string;
    btnHandleClick?: (event: React.MouseEvent<HTMLElement>, exp: boolean) => void;
    btnIcon?: JSX.Element;
    btnStyle?: "contained" | "outlined";
    btnColor?: "secondary" | "primary" | "default";
  }[];
  hideMenu?: boolean;
  extraTitle?: JSX.Element;
  padding?: number;
};

const CustomAccardion: FC<Props> = ({
  title,
  subTitle,
  children,
  optionMenuId,
  handleOptionMenuOpen,
  buttonData,
  hideMenu,
  extraTitle,
  padding
}) => {
  const [exp, setExp] = useState(false)
  const classes = useStyles();

  return (
    <Accordion
      expanded={exp}
      onChange={(e, expanded) => setExp(expanded)}
    >
      <AccordionSummary
        expandIcon={<Icon.ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <Box flexDirection="column" mt={2} mb={2} ml={padding ? padding : 0}>
            <Typography variant="h6" className={classes.heading}>
              {title}
            </Typography>
          {extraTitle && extraTitle}
          </Box>
          {hideMenu ? (
            ""
          ) : (
            <>
              <Box display="flex">
                <Hidden xsDown>
                  {buttonData &&
                    buttonData.map((item) => {
                      return (
                        <Button
                          key={item.btnLabel}
                          className={`${classes.buttons} ${
                            item.btnColor === "default" &&
                            item.btnStyle === "contained"
                              ? classes.buttonsBg
                              : ""
                          }`}
                          startIcon={item.btnIcon}
                          disableElevation={item.btnColor !== "secondary"}
                          onClick={(e) => item.btnHandleClick && item.btnHandleClick(e, exp)}
                          size="small"
                          variant={item.btnStyle}
                          color={item.btnColor}
                        >
                          {item.btnLabel}
                        </Button>
                      );
                    })}
                </Hidden>
              </Box>
              <Hidden smUp>
                <IconButton
                  aria-controls={optionMenuId}
                  aria-haspopup="true"
                  onClick={handleOptionMenuOpen}
                >
                  <Icon.MoreHoriz />
                </IconButton>
              </Hidden>
            </>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }} ml={padding ? padding : 0} mr={padding ? padding : 0}>
          <Typography variant="body2">{subTitle}</Typography>
          {subTitle && <br />}
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccardion;
