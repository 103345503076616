export const sponsors = {
  de: {
    forChoice: "Bei Auswahl",
    amount: "Betrag in Euro",
    moreInfo: "Weitere Informationen zur finanziellen Unterstützung?",
    arguments:
      "Welche Argumente haben den Sponsor überzeugt, euch zu unterstützen?",
    sites:
      "An welche Stellen habt ihr euch gewendet, um die Fördermittel zu erhalten?",
    advertising: "Wie habt ihr für euren Spendenaufruf Werbung gemacht?",
  },
  en: {
    forChoice: "For choice",
    amount: "Amount in Euro",
    moreInfo: "For more information on financial assistance?",
    arguments: "What arguments convinced the sponsor to support you?",
    sites: "At which sites you've turned to get the funding?",
    advertising: "How did you advertise your appeal for donations?",
  },
};