import React, { useState, FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useStyles } from "./NavDropdownStyles";

type Props = {
  menu?: {
    name: string;
    path: string;
    icon: JSX.Element;
  }[];
  icon: JSX.Element;
  name: string;
  path?: string;
  isOpen: boolean;
};

const NavDropdown: FC<Props> = ({ menu, icon, name, path, isOpen }) => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {!menu && path ? (
        <NavLink
          activeClassName={location.pathname === "/" ? classes.active : ""}
          to={{ pathname: path, state: { prevPath: location.pathname}}}
          className={classes.linkstyle}
        >
          <ListItem button onClick={handleClick} className={classes.linkstyle}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        </NavLink>
      ) : (
        <>
          <ListItem button onClick={handleClick} className={classes.linkstyle}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {menu &&
              menu.map((item) => {
                return (
                  <List component="div" disablePadding key={item.name}>
                    <NavLink
                      activeClassName={classes.active}
                      to={{ pathname: item.path, state: { prevPath: location.pathname}}}
                      className={`${classes.linkstyle} 
                      ${item.path === "/overview" &&
                        location.pathname.includes("practice-detail") ? classes.active : ""}
                      ${item.path === "/users" &&
                        location.pathname.includes("user-detail") ? classes.active : "" }`}
                    >
                      <ListItem
                        button
                        className={`${isOpen ? classes.nestedOpen : classes.nested}`}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} className={classes.listItemText}/>
                      </ListItem>
                    </NavLink>
                  </List>
                );
              })}
          </Collapse>
        </>
      )}
    </List>
  );
};

export default NavDropdown;
