import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    maxWidth: "500px",
    width: "100%",
    margin: theme.spacing(2),
  },

  title: {
    marginRight: theme.spacing(3),
  },

  closeBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

  closeBtnSmall: {
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },

  saveBtn: {
    textTransform: "none",
  },

  required: {
    color: theme.palette.error.main,
  },

  errorMessage: {
    fontSize: "12px",
    color: theme.palette.error.main,
  },
}));
