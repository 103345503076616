export const roles = {
  de: {
    admin: "Systemadministrator",
    editor: "Bearbeiter",
    auditor: "Prüfer",
    web_user: "Webuser",
    typo3: "Typo3",
  },
  en: {
    admin: "System administrator",
    editor: "Editor",
    auditor: "Auditor",
    web_user: "Web user",
    typo3: "Typo3",
  },
};