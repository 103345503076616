import React, { FC, useContext, useState } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import * as Icon from "../../utils/icons";
import CustomAlert from "../CustomAlert/CustomAlert";
import CustomInput from "../CustomInput/CustomInput";
import { post } from "../../utils/apiRequests";
import { usersAdd } from "../../apiURL/httpUsers";
import { emailValidation } from "../../utils/helperFunctions";
import { useStyles } from "./AddNewUserStyles";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const AddNewUser: FC<Props> = ({ open, handleClose }) => {
  const [userValue, setUserValue] = useState<string>("");
  const [failedValid, setFailedValid] = useState(false);
  const [postError, setPostError] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { buttonList, userDetailList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const handleCloseBtn = () => {
    setUserValue("");
    setFailedValid(false);
    handleClose();
  };

  const handleSaveBtn = () => {
    if (!userValue) {
      setFailedValid(true);
      return;
    }
    if (!emailValidation(userValue)) {
      setEmailValid(true);
      return;
    }
    post(usersAdd(userValue))
      .then((res) => {
        handleCloseBtn();
        history.push({
          pathname: `/user-detail/${res.data.id}`,
          state: { prevPath: location.pathname },
        });
        sessionStorage.setItem("added", "true");
      })
      .catch((err) => {
        const { response } = err;
        if (response && response.status === 400) {
          setEmailExists(true);
        } 
        setPostError(true);
      });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography variant="subtitle1" className={classes.title}>
                {userDetailList.addUser}
              </Typography>
              <Hidden xsDown>
                <Button
                  size="small"
                  variant="contained"
                  className={classes.closeBtn}
                  startIcon={
                    <Icon.AddCircle style={{ transform: "rotate(45deg)" }} />
                  }
                  onClick={handleCloseBtn}
                  disableElevation
                >
                  {buttonList.cancel}
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  size="small"
                  className={classes.closeBtnSmall}
                  onClick={handleCloseBtn}
                >
                  <Icon.AddCircle
                    style={{ transform: "rotate(45deg)" }}
                    fontSize="small"
                  />
                </IconButton>
              </Hidden>
            </Box>
            <Divider />
            <Box mt={2} />
            <Typography
              variant="subtitle2"
              className={failedValid && !userValue ? classes.required : ""}
            >
              User email*
            </Typography>
            <CustomInput
              value={userValue}
              fullWidth
              onChange={(e) => {
                if (emailValid || emailExists) {
                  setEmailValid(false);
                  setEmailExists(false);
                }
                setUserValue(e.target.value);
              }}
              id={"user-input"}
            />
            {emailValid && (
              <Typography className={classes.errorMessage}>
                {messagesList.validEmail}
              </Typography>
            )}
             {emailExists && (
              <Typography className={classes.errorMessage}>
                {messagesList.emailExists}
              </Typography>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                className={classes.saveBtn}
                color="secondary"
                startIcon={<Icon.Save />}
                onClick={handleSaveBtn}
                disableElevation
              >
                {buttonList.save}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
      <CustomAlert
        open={postError}
        onClose={() => setPostError(false)}
        severity="error"
        message={messagesList.unableToSave}
      />
    </>
  );
};

export default AddNewUser;
