/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import defaultImage from "../../assets/images/user.jpg";
import { useStyles } from "./ProfileCardStyles";
import { UserType } from "../../types/userType";
import Error from "../Error/Error";

type Props = {
  authorId: number | null;
  data: UserType | undefined;
};

const ProfileCard: FC<Props> = ({ authorId, data }) => {
  const { userDetailList, buttonList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const userData = data;

  return (
    <Paper elevation={1} className={classes.root}>
      {!userData ? (
        <>
          <Error
            error={!data ? true : false}
            loading={false}
            size="small"
            errorMessage={messagesList.noLoadUser}
          />
          <Box mb={2} />
        </>
      ) : (
        <>
          <Box display="flex" className={classes.wrapper}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar
                alt="User"
                src={defaultImage}
                className={classes.avatar}
              />
              <Typography variant="h6">
                {userData.name} {userData.surname}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.content}
              mb={2}
            >
              <Typography variant="subtitle2" className={classes.subTitle}>
                {userDetailList.email}
              </Typography>
              <Typography variant="body2" className={classes.textBreak}>
                {userData.email}
              </Typography>
              {userData.club && (
                <>
                  <Typography variant="subtitle2" className={classes.subTitle}>
                    {userDetailList.club}
                  </Typography>
                  <Typography variant="body2">{userData.club}</Typography>
                </>
              )}
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.profileBtn}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  history.push({
                    pathname: `/user-detail/${authorId}`,
                    state: { prevPath: location.pathname },
                  })
                }
              >
                {buttonList.showProfile}
              </Button>
            </Box>
          </Box>
          <Box
            mt={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.profileBtnDown}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                history.push({
                  pathname: `/user-detail/${authorId}`,
                  state: { prevPath: location.pathname },
                })
              }
            >
              {buttonList.showProfile}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default ProfileCard;
