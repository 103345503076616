/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useContext, useEffect } from "react";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import * as Icon from "../utils/icons";
import Error from "../components/Error/Error";
import SearchBar from "../components/SearchBar/SearchBar";
import CardMain from "../components/CardMain/CardMain";
import CardLong from "../components/CardLong/CradLong";
import AddButton from "../components/AddButton/AddButton";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomAutocomplete from "../components/CustomAutocomplete/CustomAutocomplete";
import CustomAutocompleteSingle from "../components/CustomAutocompleteSingle/CustomAutocompleteSingle";
import AddNewUser from "../components/AddNewUser/AddNewUser";
import defaultImage from "../assets/images/user.jpg";
import {
  getRequest,
  getRequestWithParams,
  getRequestWithTwoParams,
} from "../utils/apiRequests";
import { capitalize, changeSpaceCount } from "../utils/helperFunctions";
import { Role, UserType } from "../types/userType";
import { categoryGetActive } from "../apiURL/httpCategory";
import { DataType } from "../utils/getAllData";
import { AllData } from "../utils/practiceDetailFunctions";
import { makeStyles } from "@material-ui/core/styles";
import {
  usersGetAll,
  usersGetAllByCategories,
  usersGetAllByCategoriesAndRoles,
  usersGetAllByRoles,
  usersGetAllRoles,
} from "../apiURL/httpUsers";
import { UserContext, UserContextType } from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },

  iconButton: {
    borderRadius: "3px",
  },

  subTitle: {
    marginTop: theme.spacing(1),
  },

  filterWrapper: {
    minWidth: "210px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(607)]: {
      minWidth: "100% !important",
    },
  },

  filterWrapper_large: {
    minWidth: "260px",
  },

  extraSpace: {
    minWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
  },

  extraSpace_2: {
    display: "none",
    minWidth: "200px",
    [theme.breakpoints.up(1448)]: {
      display: "block",
    },
  },

  extraSpace_3: {
    display: "none",
    minWidth: "200px",
    [theme.breakpoints.up(1784)]: {
      display: "block",
    },
  },

  container: {
    alignItems: "stretch",
    display: "flex",
    [theme.breakpoints.down(777)]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up(2120)]: {
      flexGrow: 0,
    },
  },
}));

let space = [1];

const SystemAccess: FC = () => {
  const [userData, setUserData] = useState<UserType[]>();
  const [originalData, setOriginalData] = useState<UserType[]>();
  const [showColumns, setShowColumns] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [category, setCategory] = useState<AllData[]>([]);
  const [application, setApplication] = useState<AllData | null>(null);
  const [role, setRole] = useState<AllData[]>([]);
  const [allRoles, setAllRoles] = useState<AllData[]>();
  const [allCategories, setAllCategories] = useState<AllData[]>();
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState(10);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorFilter, setErrorFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;
  const { isAdmin } = useContext(UserContext) as UserContextType;
  const {
    buttonList,
    optionList,
    userDetailList,
    pageTitleList,
    roleList,
    messagesList,
    siteLanguage,
  } = useContext(LanguageContext) as LanguageContextType;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSiteTitle(pageTitleList.systemAccess);
  }, [siteLanguage]);

  useEffect(() => {
    Promise.all([getRequest(categoryGetActive), getRequest(usersGetAllRoles)])
      .then(([categories, roles]) => {
        setAllCategories(
          categories.map((it: DataType) => ({ id: it.id, value: it.name }))
        );
        setAllRoles(
          roles.map((it: Role) => ({
            id: it.roleId,
            value: capitalize(it.name.toLowerCase()),
          }))
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getRequest(usersGetAll) // all users, except inactive webusers
      .then((res) => {
        const filteredUsers = res.filter(
          (user: UserType) =>
            !(
              user.userRoles.find(
                (role) => role.name.toLowerCase() === "web_user"
              ) && !user.active
            )
        );
        setUserData(filteredUsers);
        setOriginalData(filteredUsers);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!allRoles) {
      return;
    }
    const categoryIds = category.map((cat) => cat.id);
    const roleIds = role.map((rol) => rol.id);
    // role filter
    if (!category[0] && role[0]) {
      setLoadingFilter(true);
      getRequestWithParams(usersGetAllByRoles, "roleId", roleIds)
        .then((res) => {
          setUserData(res);
          setErrorFilter(false);
          setLoadingFilter(false);
        })
        .catch(() => {
          setErrorFilter(true);
          setLoadingFilter(false);
        });
      // category filter
    } else if (category[0] && !role[0]) {
      setLoadingFilter(true);
      getRequestWithParams(usersGetAllByCategories, "categoryIds", categoryIds)
        .then((res) => {
          setUserData(res);
          setErrorFilter(false);
          setLoadingFilter(false);
        })
        .catch(() => {
          setErrorFilter(true);
          setLoadingFilter(false);
        });
      // role + category filter
    } else if (category[0] && role[0]) {
      setLoadingFilter(true);
      getRequestWithTwoParams(
        usersGetAllByCategoriesAndRoles,
        "categoryId",
        "roleId",
        categoryIds,
        roleIds
      )
        .then((res) => {
          setUserData(res);
          setErrorFilter(false);
          setLoadingFilter(false);
        })
        .catch(() => {
          setErrorFilter(true);
          setLoadingFilter(false);
        });
      // default all
    } else {
      setUserData(originalData);
    }
    setCount(10);
  }, [category, role]);

  const userApplications = [
    {
      id: 1,
      value: userDetailList.praxisprofi,
    },
    {
      id: 2,
      value: userDetailList.lotteries,
    },
  ];

  if (error || loading || !userData || !allCategories || !allRoles) {
    return <Error loading={loading} error={error} />;
  }

  // search filter
  const filtered = userData?.filter(
    (item) =>
      item?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
      item?.surname?.toLowerCase().includes(searchValue.toLowerCase()) ||
      item?.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
      item?.email?.toLowerCase().includes(searchValue.toLowerCase())
  );

  // show only 10 per page
  const sorted = filtered?.filter((item, i) => i < count);

  // sort by roles - admin first
  sorted?.forEach((item) => {
    item.userRoles.sort(function (a, b) {
      return a.roleId - b.roleId;
    });
  });

  const checkLength = sorted < filtered;

  space = changeSpaceCount(sorted.length);

  return (
    <>
      {showFilter && (
        <Box display="flex" flexWrap="wrap">
          <Box mb={2} flexShrink={1} className={classes.filterWrapper}>
            <Typography variant="subtitle2">{userDetailList.role}</Typography>
            <CustomAutocomplete
              valueId={role}
              placeholder={
                role[0]
                  ? ""
                  : siteLanguage
                  ? `${userDetailList.role} ${optionList.select.toLowerCase()}`
                  : `${optionList.select} ${userDetailList.role.toLowerCase()}`
              }
              id="role-select"
              onChange={(e, newValue) => setRole([...newValue])}
              items={allRoles.map((role) => ({
                id: role.id,
                value: roleList[role.value.toLowerCase()],
              }))}
            />
          </Box>
          <Box
            mb={2}
            flexShrink={1}
            className={`${classes.filterWrapper_large} ${classes.filterWrapper}`}
          >
            <Typography variant="subtitle2">
              {userDetailList.categories}
            </Typography>
            <CustomAutocomplete
              valueId={category}
              placeholder={
                category[0]
                  ? ""
                  : siteLanguage
                  ? `${
                      userDetailList.categories
                    } ${optionList.select.toLowerCase()}`
                  : `${
                      optionList.select
                    } ${userDetailList.categories.toLowerCase()}`
              }
              id="category-select"
              onChange={(e, newValue) => setCategory([...newValue])}
              items={allCategories}
            />
          </Box>
          <Box mb={2} className={classes.filterWrapper} flexShrink={1}>
            <Typography variant="subtitle2">
              {userDetailList.application}
            </Typography>
            <CustomAutocompleteSingle
              valueId={application}
              placeholder="Praxisprofi"
              id="app-select"
              disabled={true}
              onChange={(e, newValue) => setApplication(newValue)}
              items={userApplications}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" mb={1} justifyContent="flex-end">
        <Box flexGrow={1} mb={1}>
          <SearchBar
            label={optionList.search}
            value={searchValue}
            id="overview-search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box display="flex">
          <Box mx={1}>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                setShowColumns(!showColumns);
              }}
              disableRipple
            >
              {showColumns ? <Icon.ViewList /> : <Icon.ViewArray />}
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              className={`${classes.iconButton} ${
                showFilter && classes.activeIcon
              }`}
              disableRipple
            >
              <Icon.FilterList />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="body2" component="p">
          {sorted?.length} / {filtered?.length}
        </Typography>
      </Box>

      {errorFilter || loadingFilter ? (
        <Error loading={loadingFilter} error={errorFilter} size="medium" />
      ) : (
        <>
          {userData && !userData[0] && (
            <Typography>{messagesList.noResults}</Typography>
          )}
          <Box display="flex" flexBasis="100%" flexWrap="wrap">
            {showColumns ? (
              <>
                {sorted?.map((item) => {
                  return (
                    <Box
                      flexGrow={1}
                      className={classes.container}
                      key={item.id}
                    >
                      <CardMain
                        key={item.id}
                        onClick={() =>
                          history.push({
                            pathname: `/user-detail/${item.id}`,
                            state: { prevPath: location.pathname },
                          })
                        }
                        imgSrc={defaultImage}
                        defaultImgSrc={defaultImage}
                        header={false}
                        imgText={
                          item.name && item.surname
                            ? `${item.name} ${item.surname}`
                            : item.name && !item.surname
                            ? `${item.name}`
                            : !item.name && item.surname
                            ? `${item.surname}`
                            : !item.name && !item.surname && item.email
                            ? `${item.email}`
                            : ""
                        }
                        footer={false}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.subTitle}
                        >
                          {userDetailList.application}
                        </Typography>
                        <Typography variant="body2">Praxisprofi</Typography>
                        {item.userRoles[0] && (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={classes.subTitle}
                            >
                              {userDetailList.role}
                            </Typography>
                            <Typography variant="body2">
                              {item.userRoles
                                .map((it) => roleList[it.name.toLowerCase()])
                                .join(", ")}
                            </Typography>
                          </>
                        )}
                        {item.categoryDTOSet[0] && (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={classes.subTitle}
                            >
                              {userDetailList.categories}
                            </Typography>
                            <Typography variant="body2">
                              {item.categoryDTOSet
                                .map((it) => capitalize(it.name))
                                .join(", ")}
                            </Typography>
                          </>
                        )}
                      </CardMain>
                    </Box>
                  );
                })}
                {space.map((item, index) => {
                  return (
                    <Box flexGrow={1} mx={1} key={item}>
                      <Box
                        className={`${index === 0 ? classes.extraSpace : ""}
                          ${index === 1 ? classes.extraSpace_2 : ""}
                          ${index === 2 ? classes.extraSpace_3 : ""}`}
                        style={{
                          maxWidth: "320px",
                          width: "100%",
                          marginRight: "120px",
                        }}
                      ></Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              sorted?.map((item) => {
                return (
                  <CardLong
                    key={item.id}
                    onClick={() =>
                      history.push({
                        pathname: `/user-detail/${item.id}`,
                        state: { prevPath: location.pathname },
                      })
                    }
                    title={
                      item.name && item.surname
                        ? `${item.name} ${item.surname}`
                        : item.name && !item.surname
                        ? `${item.name}`
                        : !item.name && item.surname
                        ? `${item.surname}`
                        : !item.name && !item.surname && item.email
                        ? `${item.email}`
                        : ""
                    }
                    lastLine={
                      item.userRoles
                        .map((it) => roleList[it.name.toLowerCase()])
                        .includes(roleList.editor)
                        ? item.userRoles
                            .map((it) => {
                              if (
                                roleList[it.name.toLowerCase()] ===
                                  roleList.editor &&
                                item.categoryDTOSet[0]
                              ) {
                                return (
                                  roleList.editor +
                                  ": " +
                                  item.categoryDTOSet
                                    .map((cat) => capitalize(cat.name))
                                    .join(", ")
                                );
                              }
                              return roleList[it.name.toLowerCase()];
                            })
                            .join(", ")
                        : item.userRoles
                            .map((it) => roleList[it.name.toLowerCase()])
                            .join(", ")
                    }
                    firstLine="Praxisprofi"
                    imgSrc={defaultImage}
                    defaultImgSrc={defaultImage}
                    imgRound={true}
                    footer={false}
                    order="variant-2"
                  />
                );
              })
            )}
          </Box>
        </>
      )}
      {checkLength && (
        <Box display="flex" justifyContent="center" mt={2} mx={1.8}>
          <CustomButton
            size="large"
            variant="contained"
            disableRound={true}
            onClick={() => setCount(count + 10)}
          >
            {buttonList.loadMore}
          </CustomButton>
        </Box>
      )}
      {isAdmin && (
        <AddButton
          onClick={() => {
            setOpenAdd(true);
          }}
        />
      )}
      <AddNewUser open={openAdd} handleClose={() => setOpenAdd(false)} />
    </>
  );
};

export default SystemAccess;
