import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    "& .MuiButtonBase-root:hover": {
      backgroundColor: "inherit",
    },
    "& .MuiListItem-root": {
      color: theme.palette.text.disabled,
    },
  },

  nested: {
    "& > :first-child": {
      color: theme.palette.action.disabled,
      transition: ".3s",
    },
    "&.MuiListItem-button": {
      transition: ".3s",
    },
  },

  nestedOpen: {
    paddingLeft: theme.spacing(4),
    transition: ".3s",
  },

  linkstyle: {
    textDecoration: "none",

    "&:after": {
      position: "absolute",
      content: `' '`,
      height: "2px",
      top: "44px",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: "0px",
      backgroundColor: theme.palette.action.selected,
    },

    "&:hover:after": {
      position: "absolute",
      width: "80%",
      transition: ".5s",
    },
  },

  active: {
    "&:after": {
      position: "absolute",
      content: `' '`,
      height: "2px",
      top: "44px",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: "80%",
      backgroundColor: theme.palette.secondary.main,
    },
   '& div > .MuiListItemText-root': {
     color: theme.palette.text.primary,
   }
  },

  listItemText: {
    "& > span": {
      fontSize: "0.95rem",
    },
  },
}));
