import { FileType } from "../types/fileType";

export const capitalize = (str: string) => {
  if (typeof str !== "string") {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const truncate = (str: string, num: number) => {
  if (typeof str !== "string") {
    return str;
  }

  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const removeLast = (str: string) => {
  if (typeof str !== "string") {
    return str;
  }
  const newString = str.split("");
  newString.pop();
  return newString.join("");
};

export const removeRest = (str: string, startingNum: number) => {
  if (typeof str !== "string") {
    return str;
  }
  const result = str.substring(startingNum, -1)
  return result
};

export const openPDF = (url: string) => {
  const win = window.open();
  if (!win) {
    return;
  }
  win.document.write(
    '<iframe src="' +
      url +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );
};

export const findMainImg = (arr: FileType[]) => {
  if (!arr) {
    return '';
  }
  const main = arr.find((file) => file.mainFile);
  let url = "";
  main ? (url = main.storageUri) : (url = "");
  return url;
};

export const changeSpaceCount = (length: number) => {
  if (length % 5 === 2) {
    return [1, 2, 3];
  } else if (length % 4 === 2 || length % 5 === 3) {
    return [1, 2];
  } else {
    return [1];
  }
};

export const emailValidation = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const decimalCount = (num: number) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
     return numStr.split('.')[1].length;
  };
  return 0;
}