import { base } from "./baseURL";

export const usersAssignCategory = `${base}/user/assignCategory`;
export const usersAssignRole = `${base}/user/assignRole`;
export const usersGetAll = `${base}/user/getAll`;
export const userGetById = (id: number) => `${base}/user/getById/${id}`;
export const usersGetAllRoles = `${base}/user/getAllRoles`;
export const usersGetAllByCategories = `${base}/user/getUsersByCategories`;
export const usersGetAllByRoles = `${base}/user/getUsersByRoles`;
export const usersGetAllByCategoriesAndRoles = `${base}/user/getUsersByCategoriesAndRoles`;
export const usersGetAllByCategory = (id: number) => `${base}/user/getAllByCategory/${id}`;
export const usersGetAllByRole = (id: number) => `${base}/user/getAllByRole/${id}`;
export const usersGetAllByRoleAndCategory = (roleId: number, categoryId: number) => `${base}/user/getAllByRoleAndCategory/${roleId}/${categoryId}`;
export const usersAdd = (email: string) => `${base}/user/addUser/${email}`;
export const usersGetWebusers = `${base}/user/getWebUsers`;
export const usersGetCurrent =  `${base}/user/getSystemCurrentUser`;
export const userRefreshSession = `${base}/user/refreshSession`;
