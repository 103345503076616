import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  listItem: {
    width: "100%",
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    alignItems: "flex-start",

    "& > span:first-child": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },

    "& .MuiFormControlLabel-label": {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    
    "& > :nth-child(2) .MuiTextField-root": {
      marginTop: theme.spacing(-1),
    }
  },

  listItem2: {
    width: "85%",
    marginRight: 0,
    padding: 0,
  },

  new: {
    "& input": {
      "&::placeholder": {
        fontStyle: "italic",
      },
      paddingLeft: 0,
    },
  },

  deleteButton: {
    color: theme.palette.error.main,
    opacity: 0.4,
    width: "10%",
    borderRadius: "4px",
    "&:hover": {
      opacity: 1,
      transition: ".2s",
      backgroundColor: theme.palette.background.paper,
    },
  },

  textBackground: {
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(1),
    maxWidth: "270px",
    width: '100%',
    marginTop: theme.spacing(-1),
  },

  checkBox: {
    "& + .MuiFormControlLabel-label.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.main,
    },
  },

  gridRoot: {
    "&.MuiGrid-grid-sm-6": {
      [theme.breakpoints.down(700)]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    "&.MuiGrid-grid-md-4": {
      [theme.breakpoints.down(1000)]: {
        flexGrow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
      },
    }
  }
}));
