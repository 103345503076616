import { ThemeOptions } from "@material-ui/core/styles";

export const dark: ThemeOptions = {

  overrides: {
    MuiPaper:{
      elevation1: {
        boxShadow: '0px 2px 6px -1px rgb(0 0 0 / 15%)'
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        textTransform: 'none',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: '"Helvetica", "Roboto", "Arial", sans-serif !important'
      },
      subtitle1: {
        color: '#adadad',
        letterSpacing: '2px',
        fontSize: '1.1rem',
        fontWeight: 700,
        display: 'block',
        textTransform: 'uppercase',
        '@media (max-width:600px)': {
          fontSize: '1rem',
        },
      },
      subtitle2: {
        color: '#787878',
        letterSpacing: '1px',
        fontSize: '0.65rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      }
    }
  },
  palette: {
    type:"dark",
    primary: { 
      main: '#656565',
      contrastText: "#fff",
    },
    secondary: {
      main: '#20AE80',
      contrastText: "#fff",
    },
    error:{
      main: '#d32f2f',
      dark: '#ad1f1f',
    },
    grey: {
      A100: 'rgba(0,0,0,0.2)',
      A200: 'rgba(0,0,0,0.6)',
    },
  },
};

export const light: ThemeOptions = {
  overrides: {
    MuiPaper:{
      elevation1: {
        boxShadow: '0px 2px 6px -1px rgb(0 0 0 / 15%)'
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        textTransform: 'none',
      },
    },
    
    MuiTypography: {
      root: {
        fontFamily: '"Helvetica", "Arial", sans-serif !important',
      },
      subtitle1: {
        color: '#c5c8cb',
        letterSpacing: '2px',
        fontSize: '1.1rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        '@media (max-width:600px)': {
          fontSize: '1rem',
        },
      },
      subtitle2: {
        color: '#c5c8cb',
        letterSpacing: '1px',
        fontSize: '0.65rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      }
    }
  },
  palette: {
    background: {
      default: '#fff',
    },
    primary: { 
      main: '#656565',
      contrastText: "#333333",
    },
    secondary: {
      main: '#20AE80',
      contrastText: "#fff",
    },
    error:{
      main: '#d32f2f',
      dark: '#ad1f1f',
    },
    grey: {
      A100: 'rgba(0,0,0,0.1)',
      A200: 'rgba(0,0,0,0.2)',
    },
  },
};