import React, { FC } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./OptionMenuStyles";

type Props = {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  handleClose: () => void;
  menuId: string;
  items: {
    name: string;
    handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
    icon?: JSX.Element;
  }[];
  styles?: boolean;
};

const OptionMenu: FC<Props> = ({
  anchorEl,
  isOpen,
  handleClose,
  menuId,
  items,
  styles,
}) => {
    const classes = useStyles();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      onClose={handleClose}
      className={styles ? classes.menu : ''}
    >
      {items.map((item) => {
        return (
          <MenuItem onClick={item.handleClick} key={item.name}>
            {item.icon && (
              <ListItemIcon className={classes.menuIcon}>
                {item.icon}
              </ListItemIcon>
            )}
            <Typography variant="inherit">
                {item.name}
            </Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default OptionMenu;
