/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, FC, useEffect } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import CustomInput from "../CustomInput/CustomInput";
import CustomAutocompleteSingle from "../CustomAutocompleteSingle/CustomAutocompleteSingle";
import Header from "./components/Header";
import { PracticeType } from "../../types/practiceType";
import { AllDataType, modifyData } from "../../utils/getAllData";
import { messageFields } from "../../data/practice-detail/practiveDetail-messageFields";
import {
  handleActiveMessages,
  AllData,
  findValueId,
} from "../../utils/practiceDetailFunctions";
import { useStyles } from "./PracticeStyles";

type Props = {
  data: PracticeType;
  allData: AllDataType;
  validated: boolean;
  validateBtn: boolean;
  editable: boolean;
  disableEditing: boolean;
  validatedDraft: boolean;
  handleEdit: () => void;
  changeSelect: (newValue: AllData | null, objectKey: string) => void;
  changeArray: (
    e: React.ChangeEvent<HTMLInputElement>,
    objectKey: string,
    index: number
  ) => void;
};

const YesNo = [
  { id: 1, value: "Ja" },
  { id: 2, value: "Nein" },
];

const ClubInfo: FC<Props> = ({
  data,
  editable,
  handleEdit,
  changeSelect,
  changeArray,
  validated,
  validateBtn,
  disableEditing,
  allData,
  validatedDraft,
}) => {
  const [messageField, setMessageField] = useState(messageFields);

  const { practiceDetailList, settingsList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const classes = useStyles();
  const all = modifyData(allData);
  const dataObj: PracticeType = { ...data };
  const hide = dataObj.status === "APPROVED" || dataObj.status === "DENIED";
  const draft = dataObj.status === "DRAFT";

  const handleMessageField = (objectKey: string, newValue: boolean) => {
    setMessageField(handleActiveMessages(messageField, objectKey, newValue));
  };

  const singleMessageField = (objectKey: string) => {
    const message = messageField.find((item) => item.key === objectKey);
    return message?.active;
  };

  useEffect(() => {
    const cabins = all.cabinType.map((item, index) => ({
      key: `cabin-${index}`,
      active: false,
    }));
    const pitches = all.pitchType.map((item, index) => ({
      key: `pitch-${index}`,
      active: false,
    }));
    const teams = all.teamComposition.map((item, index) => ({
      key: `team-${index}`,
      active: false,
    }));
    setMessageField([...messageField, ...cabins, ...pitches, ...teams]);
  }, []);

  const getSelectField = (objectKey: string) => {
    return editable ? (
      <Box className={classes.selectWrapper}>
        <CustomAutocompleteSingle
          valueId={
            dataObj[objectKey] === ""
              ? null
              : {
                  id: findValueId(
                    all[objectKey],
                    dataObj[objectKey] as string
                  ) as number,
                  value: dataObj[objectKey] as string,
                }
          }
          items={all[objectKey]}
          id={`${objectKey}-select`}
          onChange={(e, newValue) => {
            changeSelect(newValue, objectKey);
          }}
        />
      </Box>
    ) : (
      getText(dataObj[objectKey] as string)
    );
  };

  const getSubtitle = (title: string, objectKey: string, required: boolean) => {
    return (
      <Typography
        variant="subtitle2"
        className={`
        ${
          !draft && !dataObj[objectKey] && !validated && editable && required
            ? classes.required
            : ""
        }
        ${
          draft &&
          validatedDraft &&
          !dataObj[objectKey] &&
          !validated &&
          required &&
          editable
            ? classes.required
            : ""
        }
        `}
      >
        {title}
        {required ? <span>*</span> : ""}
      </Typography>
    );
  };

  const getText = (value: string | number | boolean, title?: string) => {
    return (
      <Typography
        className={classes.text}
        variant={title === practiceDetailList.name ? "h6" : "body2"}
      >
        {value}
      </Typography>
    );
  };

  return (
    <>
      <Paper elevation={1} style={{ padding: "1.5rem" }}>
        <Header
          title={practiceDetailList.club}
          editable={editable}
          onClick={handleEdit}
          validated={validateBtn}
          hide={hide || disableEditing}
        />
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            {getSubtitle(settingsList.clubType, "teamType", true)}
            {getSelectField("teamType")}
          </Grid>
          <Grid item sm={6} xs={12}>
            {getSubtitle(settingsList.noOfMembers, "members", true)}
            {getSelectField("members")}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography
              className={`${
                !draft &&
                (!dataObj.teamComposition ||
                  (dataObj.teamComposition?.length &&
                    (dataObj.teamComposition as string[][]).every(
                      (it) => +it[1] === 0
                    ))) &&
                editable &&
                !validated
                  ? classes.required
                  : ""
              }${
                draft &&
                validatedDraft &&
                (!dataObj.teamComposition ||
                  (dataObj.teamComposition?.length &&
                    (dataObj.teamComposition as string[][]).every(
                      (it) => +it[1] === 0
                    ))) &&
                !validated &&
                editable
                  ? classes.required
                  : ""
              }`}
              variant="subtitle2"
            >
              {settingsList.noOfTeams}*
            </Typography>
            {dataObj.teamComposition && dataObj.teamComposition?.length
              ? (dataObj.teamComposition as string[][]).map(
                  (team: string[], index: number) => {
                    return (
                      (editable || (!editable && +team[1] !== 0)) && (
                        <Box
                          display="flex"
                          mb={1}
                          alignItems="center"
                          key={team[0]}
                        >
                          <Box
                            mr={2}
                            className={editable ? classes.groupItemXs : ""}
                          >
                            {messageField.some(
                              (item) => item.key === `team-${index}`
                            ) && (
                              <Tooltip
                                classes={{ tooltip: classes.message }}
                                disableHoverListener
                                disableTouchListener
                                open={singleMessageField(`team-${index}`)}
                                onClose={() =>
                                  handleMessageField(`team-${index}`, false)
                                }
                                title={messagesList.validNumber}
                                placement="bottom-start"
                              >
                                <div>
                                  {editable ? (
                                    <CustomInput
                                      id="teamValue-input"
                                      type="number"
                                      value={+team[1]}
                                      onChange={(e) => {
                                        if (
                                          +e.target.value < 0 ||
                                          +e.target.value > 99
                                        ) {
                                          handleMessageField(
                                            `team-${index}`,
                                            true
                                          );
                                        }
                                        changeArray(
                                          e,
                                          "teamComposition",
                                          index
                                        );
                                      }}
                                    />
                                  ) : (
                                    getText(team[1])
                                  )}
                                </div>
                              </Tooltip>
                            )}
                          </Box>
                          <Typography
                            variant="body2"
                            className={classes.groupItemXsText}
                          >
                            {team[0]}
                          </Typography>
                        </Box>
                      )
                    );
                  }
                )
              : ""}
          </Grid>
          {dataObj.pitchType?.length &&
          ((dataObj.pitchType as string[][]).some((pitch) => +pitch[1]) ||
            editable) ? (
            <Grid item sm={6} xs={12}>
              {getSubtitle(
                practiceDetailList.noOfPitches,
                "nrOfPitches",
                false
              )}
              {dataObj.pitchType && dataObj.pitchType?.length
                ? (dataObj.pitchType as string[][]).map((pitch, index) => {
                    return (
                      (editable || (!editable && +pitch[1] !== 0)) && (
                        <Box
                          display="flex"
                          mb={1}
                          alignItems="center"
                          key={pitch[0]}
                        >
                          <Box
                            mr={2}
                            className={editable ? classes.groupItemXs : ""}
                          >
                            {messageField.some(
                              (item) => item.key === `pitch-${index}`
                            ) && (
                              <Tooltip
                                classes={{ tooltip: classes.message }}
                                open={singleMessageField(`pitch-${index}`)}
                                disableHoverListener
                                disableTouchListener
                                onClose={() =>
                                  handleMessageField(`pitch-${index}`, false)
                                }
                                title={messagesList.validNumber}
                                placement="bottom-start"
                              >
                                <div>
                                  {editable ? (
                                    <CustomInput
                                      id="pitch-input"
                                      type="number"
                                      value={+pitch[1]}
                                      onChange={(e) => {
                                        if (
                                          +e.target.value < 0 ||
                                          +e.target.value > 99
                                        ) {
                                          handleMessageField(
                                            `pitch-${index}`,
                                            true
                                          );
                                        }
                                        changeArray(e, "pitchType", index);
                                      }}
                                    />
                                  ) : (
                                    getText(pitch[1])
                                  )}
                                </div>
                              </Tooltip>
                            )}
                          </Box>
                          <Typography
                            variant="body2"
                            className={classes.groupItemXsText}
                          >
                            {pitch[0]}
                          </Typography>
                        </Box>
                      )
                    );
                  })
                : ""}
            </Grid>
          ) : (
            ""
          )}
          {dataObj.ownership || editable ? (
            <Grid item sm={6} xs={12}>
              {getSubtitle(
                practiceDetailList.sportsFacilityOwn,
                "ownership",
                false
              )}
              {getSelectField("ownership")}
            </Grid>
          ) : (
            ""
          )}
          {dataObj.clubhouseAvailable || editable ? (
            <Grid item sm={6} xs={12}>
              {getSubtitle(
                practiceDetailList.clubhouseAvailable,
                "clubhouseAvailable",
                false
              )}
              {editable ? (
                <Box className={classes.selectWrapper}>
                  <CustomAutocompleteSingle
                    valueId={
                      dataObj.clubhouseAvailable === ""
                        ? null
                        : {
                            id: findValueId(
                              YesNo,
                              dataObj.clubhouseAvailable as string
                            ) as number,
                            value: dataObj.clubhouseAvailable as string,
                          }
                    }
                    items={YesNo}
                    id="clubhouse-select"
                    onChange={(e, newValue) => {
                      changeSelect(newValue, "clubhouseAvailable");
                    }}
                  />
                </Box>
              ) : (
                getText(
                  !dataObj.clubhouseAvailable ? "" : dataObj.clubhouseAvailable
                )
              )}
            </Grid>
          ) : (
            ""
          )}
          {(dataObj.cabinType?.length &&
            (dataObj.cabinType as string[][]).some((cabin) => +cabin[1])) ||
          editable ? (
            <Grid item sm={6} xs={12}>
              {getSubtitle(
                practiceDetailList.cabinsAvailable,
                "cabinType",
                false
              )}
              {dataObj.cabinType && dataObj.cabinType?.length
                ? (dataObj.cabinType as string[][]).map((cabin, index) => {
                    return (
                      (editable || (!editable && +cabin[1] !== 0)) && (
                        <Box
                          display="flex"
                          mb={1}
                          alignItems="center"
                          key={cabin[0]}
                        >
                          <Box
                            mr={2}
                            className={editable ? classes.groupItemXs : ""}
                          >
                            {messageField.some(
                              (item) => item.key === `cabin-${index}`
                            ) && (
                              <Tooltip
                                classes={{ tooltip: classes.message }}
                                open={singleMessageField(`cabin-${index}`)}
                                disableHoverListener
                                disableTouchListener
                                onClose={() =>
                                  handleMessageField(`cabin-${index}`, false)
                                }
                                title={messagesList.validNumber}
                                placement="bottom-start"
                              >
                                <div>
                                  {editable ? (
                                    <CustomInput
                                      id="cabin-input"
                                      type="number"
                                      value={+cabin[1]}
                                      onChange={(e) => {
                                        if (
                                          +e.target.value < 0 ||
                                          +e.target.value > 99
                                        ) {
                                          handleMessageField(
                                            `cabin-${index}`,
                                            true
                                          );
                                        }
                                        changeArray(e, "cabinType", index);
                                      }}
                                    />
                                  ) : (
                                    getText(cabin[1])
                                  )}
                                </div>
                              </Tooltip>
                            )}
                          </Box>

                          <Typography
                            className={classes.groupItemXsText}
                            variant="body2"
                          >
                            {cabin[0]}
                          </Typography>
                        </Box>
                      )
                    );
                  })
                : ""}
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Box mb={2} />
      </Paper>
    </>
  );
};

export default ClubInfo;
