/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useContext, useEffect } from "react";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import * as Icon from "../utils/icons";
import defaultImage from "../assets/images/user.jpg";
import SearchBar from "../components/SearchBar/SearchBar";
import CardMain from "../components/CardMain/CardMain";
import CardLong from "../components/CardLong/CradLong";
import CustomButton from "../components/CustomButton/CustomButton";
import Error from "../components/Error/Error";
import { makeStyles } from "@material-ui/core/styles";
import { changeSpaceCount } from "../utils/helperFunctions";
import { getRequest } from "../utils/apiRequests";
import { usersGetWebusers } from "../apiURL/httpUsers";
import { UserType } from "../types/userType";

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },

  iconButton: {
    borderRadius: "3px",
  },

  subTitle: {
    marginTop: theme.spacing(1),
  },

  extraSpace: {
    minWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
  },

  extraSpace_2: {
    display: "none",
    minWidth: "200px",
    [theme.breakpoints.up(1448)]: {
      display: "block",
    },
  },

  extraSpace_3: {
    display: "none",
    minWidth: "200px",
    [theme.breakpoints.up(1784)]: {
      display: "block",
    },
  },

  container: {
    alignItems: "stretch",
    display: "flex",
    [theme.breakpoints.down(777)]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up(2120)]: {
      flexGrow: 0,
    },
  },
}));

let space = [1];

const PracticeUsers: FC = () => {
  const [userData, setUserData] = useState<UserType[]>();
  const [showColumns, setShowColumns] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState(10);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;
  const {
    buttonList,
    optionList,
    userDetailList,
    pageTitleList,
    siteLanguage,
    messagesList
  } = useContext(LanguageContext) as LanguageContextType;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSiteTitle(pageTitleList.praxisprofiUsers);
  }, [siteLanguage]);

  useEffect(() => {
    getRequest(usersGetWebusers)
      .then((res) => {
        setUserData(res);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  if (error || loading || !userData) {
    return <Error loading={loading} error={error} />;
  }

  // search filter
  const filtered = userData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.email.toLowerCase().includes(searchValue.toLowerCase())
  );

  // show only 10 per page
  const sorted = filtered.filter((item, i) => i < count);

  space = changeSpaceCount(sorted.length);

  return (
    <>
      <Box display="flex" flexWrap="wrap" mb={1} justifyContent="flex-end">
        <Box flexGrow={1} mb={1}>
          <SearchBar
            label={optionList.search}
            value={searchValue}
            id="overview-search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box ml={1}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              setShowColumns(!showColumns);
            }}
            disableRipple
          >
            {showColumns ? <Icon.ViewList /> : <Icon.ViewArray />}
          </IconButton>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="body2" component="p">
          {sorted.length} / {filtered.length}
        </Typography>
      </Box>

      {userData && !userData[0] && (
        <Typography>{messagesList.noResults}</Typography>
      )}
      <Box display="flex" flexBasis="100%" flexWrap="wrap">
        {showColumns ? (
          <>
            {sorted.map((item) => {
              return (
                <Box flexGrow={1} className={classes.container} key={item.id}>
                  <CardMain
                    onClick={() =>
                      history.push({
                        pathname: `/user-detail/${item.id}`,
                        state: { prevPath: location.pathname },
                      })
                    }
                    imgSrc={defaultImage}
                    defaultImgSrc={defaultImage}
                    header={false}
                    imgText={`${item.name} ${item.surname}`}
                    footer={false}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.subTitle}
                    >
                      {userDetailList.email}
                    </Typography>
                    <Typography variant="body2">{item.email}</Typography>
                    {item.club && (
                      <>
                    <Typography
                      variant="subtitle2"
                      className={classes.subTitle}
                    >
                      {userDetailList.club}
                    </Typography>
                    <Typography variant="body2">{item.club}</Typography>
                    </>
                    )}
                  </CardMain>
                </Box>
              );
            })}
            {space.map((item, index) => {
              return (
                <Box flexGrow={1} mx={1} key={item}>
                  <Box
                    className={`${index === 0 ? classes.extraSpace : ""}
                      ${index === 1 ? classes.extraSpace_2 : ""}
                      ${index === 2 ? classes.extraSpace_3 : ""}`}
                    style={{
                      maxWidth: "320px",
                      width: "100%",
                      marginRight: "120px",
                    }}
                  ></Box>
                </Box>
              );
            })}
          </>
        ) : (
          sorted.map((item) => {
            return (
              <CardLong
                key={item.id}
                onClick={() =>
                  history.push({
                    pathname: `/user-detail/${item.id}`,
                    state: { prevPath: location.pathname },
                  })
                }
                title={`${item.name} ${item.surname}`}
                lastLine={item.email}
                firstLine={item.club}
                imgSrc={defaultImage}
                defaultImgSrc={defaultImage}
                imgRound={true}
                footer={false}
              />
            );
          })
        )}
      </Box>
      {sorted < filtered && (
        <Box display="flex" justifyContent="center" mt={2} mx={1.8}>
          <CustomButton
            size="large"
            variant="contained"
            disableRound={true}
            onClick={() => setCount(count + 10)}
          >
            {buttonList.loadMore}
          </CustomButton>
        </Box>
      )}
    </>
  );
};

export default PracticeUsers;
