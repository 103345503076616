import { getRequest } from "./apiRequests";
import { categoryGetActive } from "../apiURL/httpCategory";
import { objectiveGetActive } from "../apiURL/httpObjective";
import { personsInvolvedGetActive } from "../apiURL/httpPersonsInvolved";
import { durationGetActive } from "../apiURL/httpDuration";
import { scopeAmountGetActive } from "../apiURL/httpScopeAmount";
import { scopePerUnitGetActive } from "../apiURL/httpScopePerUnit";
import { costsGetActive } from "../apiURL/httpCosts";
import { teamTypeGetActive } from "../apiURL/httpTeamType";
import { membersGetActive } from "../apiURL/httpMembers";
import { teamCompositionGetActive } from "../apiURL/httpTeamComposition";
import { ownershipGetActive } from "../apiURL/httpOwnership";
import { pitchTypeGetActive } from "../apiURL/httpPitchType";
import { cabinTypeGetActive } from "../apiURL/httpCabinType";
import { sponsorsGetActive } from "../apiURL/httpSponsors";
import { clubGetAll } from "../apiURL/httpClub";
import { capitalize } from "./helperFunctions";

export interface DataType {
  id: number;
  name: string;
  active: boolean;
}

interface DataTypeModified {
  id: number;
  value: string;
}

export interface AllDataTypeModified {
  [key: string]: DataTypeModified[];
  categories: DataTypeModified[];
  objectives: DataTypeModified[];
  personsInvolved: DataTypeModified[];
  duration: DataTypeModified[];
  scopeAmount: DataTypeModified[];
  scopePerUnit: DataTypeModified[];
  costs: DataTypeModified[];
  teamType: DataTypeModified[];
  members: DataTypeModified[];
  teamComposition: DataTypeModified[];
  ownership: DataTypeModified[];
  pitchType: DataTypeModified[];
  cabinType: DataTypeModified[];  
  sponsors: DataTypeModified[];
  clubs: DataTypeModified[];
}

export interface AllDataType {
  [key: string]: DataType[];
  categories: DataType[];
  objectives: DataType[];
  personsInvolved: DataType[];
  duration: DataType[];
  scopeAmount: DataType[];
  scopePerUnit: DataType[];
  costs: DataType[];
  teamType: DataType[];
  members: DataType[];
  teamComposition: DataType[];
  ownership: DataType[];
  pitchType: DataType[];
  cabinType: DataType[];
  sponsors: DataType[];
  clubs: DataType[];
}

export const initialSettings: AllDataType = {
  categories: [],
  objectives: [],
  personsInvolved: [],
  duration: [],
  scopeAmount: [],
  scopePerUnit: [],
  costs: [],
  teamType: [],
  members: [],
  teamComposition: [],
  ownership: [],
  pitchType: [],
  cabinType: [],
  sponsors: [],
  clubs: [],
};

export const getAllData = async (bool: boolean) => {
  if (bool) {
    return;
  }
  await Promise.all([
    getRequest(categoryGetActive),
    getRequest(objectiveGetActive),
    getRequest(personsInvolvedGetActive),
    getRequest(durationGetActive),
    getRequest(scopeAmountGetActive),
    getRequest(scopePerUnitGetActive),
    getRequest(costsGetActive),
    getRequest(teamTypeGetActive),
    getRequest(membersGetActive),
    getRequest(teamCompositionGetActive),
    getRequest(ownershipGetActive),
    getRequest(pitchTypeGetActive),
    getRequest(cabinTypeGetActive),
    getRequest(sponsorsGetActive),
    getRequest(clubGetAll),
  ])
    .then(
      ([
        categories,
        objectives,
        personsInvolved,
        duration,
        scopeAmount,
        scopePerUnit,
        costs,
        teamType,
        members,
        teamComposition,
        ownership,
        pitchType,
        cabinType,
        sponsors,
        clubs
      ]) => {
        initialSettings.categories = categories;
        initialSettings.objectives = objectives;
        initialSettings.personsInvolved = personsInvolved;
        initialSettings.duration = duration;
        initialSettings.scopeAmount = scopeAmount;
        initialSettings.scopePerUnit = scopePerUnit;
        initialSettings.costs = costs;
        initialSettings.teamType = teamType;
        initialSettings.members = members;
        initialSettings.teamComposition = teamComposition;
        initialSettings.ownership = ownership;
        initialSettings.pitchType = pitchType;
        initialSettings.cabinType = cabinType;
        initialSettings.sponsors = sponsors;
        initialSettings.clubs = clubs;
      }
    )
    .catch(() => {
      console.log("couldn't get the data");
    });
  return initialSettings;
};

export const modifyData = (obj: AllDataType): AllDataTypeModified => {
  const keys = Object.keys(obj);
  const clone = JSON.parse(JSON.stringify(obj));


  keys.forEach((key) => {
    clone[key] = clone[key].map((item: DataType) => ({
      id: item.id,
      value: capitalize(item.name),
    }));
  });

  return clone;
};
