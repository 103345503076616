import React, { createContext, useState, FC } from "react";

export const ThemeContext = createContext<ThemeContextType>(null!);

export type ThemeContextType = {
  setSiteTheme: React.Dispatch<React.SetStateAction<boolean>>,
  siteTheme: boolean,
}

export const ThemeProvider:FC = ({ children }) => {
  const [siteTheme, setSiteTheme] = useState(true);

  return (
    <ThemeContext.Provider
      value={{
        setSiteTheme,
        siteTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
