import React, { FC, useContext, useEffect, useState } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import * as Icon from "../../utils/icons";
import { useStyles } from "./ConfirmationModalStyles";
import { Typography } from "@material-ui/core";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSecondBtn: () => void;
  handleFirstBtn?: () => void;
  message: string;
  disableFocus?: boolean;
  subText?: string;
  disableClose?: boolean;
  hideFirstButton?: boolean;
  disable?: boolean;
  secondButtonData?: {
    buttonText: string;
    buttonColor: string;
    buttonIcon: JSX.Element;
  };
  firstButtonData?: {
    buttonText: string;
    buttonIcon?: JSX.Element;
  };
};

const ConfirmationModal: FC<Props> = ({
  open,
  handleClose,
  handleSecondBtn,
  message,
  subText,
  secondButtonData,
  children,
  disableFocus,
  disableClose,
  hideFirstButton,
  firstButtonData,
  handleFirstBtn,
  disable,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const classes = useStyles();
  const { buttonList } = useContext(LanguageContext) as LanguageContextType;
  const btn = firstButtonData;
  const btn2 = secondButtonData;

  useEffect(() => {
    if (disable) {
      setDisableButton(false);
    }
  }, [disable]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        disableAutoFocus={disableFocus}
        open={open}
        onClose={(e, r) => {
          if (disableClose && r !== "backdropClick") {
            handleClose();
          } else if (!disableClose) {
            handleClose();
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={`${disableFocus ? classes.disableFocus : ""} ${
              classes.paper
            }`}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              mt={disableClose ? 2 : 0}
            >
              {!disableClose && (
                <IconButton
                  size="small"
                  color="default"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Icon.Clear fontSize="small" />
                </IconButton>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"center"}
              mb={2}
            >
              <Typography variant="body1" className={classes.message}>
                {message}
              </Typography>
              <Box p={1}>
                <Typography variant="body2" className={classes.subtext}>
                  {subText}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent={"space-around"}
              mr={hideFirstButton ? 1 : 0}
              mb={1}
            >
              {!hideFirstButton && (
                <Button
                  onClick={
                    handleFirstBtn
                      ? () => {
                          handleFirstBtn();
                          setDisableButton(true);
                        }
                      : () => {
                          handleClose();
                        }
                  }
                  startIcon={
                    btn?.buttonIcon ? (
                      btn.buttonIcon
                    ) : (
                      <Icon.AddCircle style={{ transform: "rotate(45deg)" }} />
                    )
                  }
                  variant="contained"
                  color="default"
                  size="small"
                  disableElevation
                  disabled={disableButton}
                  className={`${classes.button} ${classes.closeBtn}`}
                >
                  {btn ? btn.buttonText : buttonList.cancel}
                </Button>
              )}
              <Button
                onClick={() => {
                  handleSecondBtn();
                  setDisableButton(true);
                }}
                startIcon={btn2 ? btn2.buttonIcon : <Icon.DeleteIcon />}
                variant="contained"
                color={
                  btn2?.buttonColor === "secondary" ? "secondary" : "default"
                }
                className={`${classes.button} 
                ${btn2?.buttonColor !== "secondary" ? classes.deleteBtn : ""}`}
                size="small"
                disableElevation
                disabled={disableButton}
              >
                {btn2 ? btn2.buttonText : buttonList.delete}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center" flexBasis="100%">
              {children}
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
