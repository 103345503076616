import React, { FC, useContext } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { useStyles } from "./ErrorStyles";

type Props = {
  error?: boolean | string;
  errorMessage?: string;
  loading: boolean;
  size?: "small" | "large" | "medium" | "extraSmall";
};

const Error: FC<Props> = ({ error, loading, size, errorMessage }) => {
  const { messagesList } = useContext(LanguageContext) as LanguageContextType;

  const classes = useStyles();

  return (
    <>
      {loading && (
        <Box
          mt={2}
          className={`${classes.loader} 
          ${size === "small" ? classes.loaderSmall : ""}
          ${size === "medium" ? classes.loaderMedium : ""}
          ${size === "extraSmall" ? classes.loaderXS : ""}
          `}
        >
          <CircularProgress style={{color: "#20AE80"}} color="secondary" size={size === "extraSmall" ? 30 : 40 }/>
        </Box>
      )}
      {error && (
        <Box className={`${classes.loader}
        ${size === "small" ? classes.messageSmall : ""}
        ${size === "medium" ? classes.loaderMedium : ""}
        ${size === "extraSmall" ? classes.loaderXS : ""}
        `}>
          <Typography variant="subtitle2">
            {errorMessage ? errorMessage : messagesList.server}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Error;
