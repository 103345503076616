import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-root": {
      border: "none",
      borderBottom: "none",
      borderRadius: "4px",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      backgroundColor: theme.palette.action.hover,
    },
    "& .MuiInputBase-multiline": {
      paddingTop: "10px",
      "&:focus": {
        boxShadow: "2px 4px 8px rgba(0,0,0,0.1)",
      },
      "&:hover": {
        boxShadow: "2px 4px 8px rgba(92,92,92,0.1)",
        transition: ".3s",
      },
    },
    "& input": {
      padding: "10px 10px",
      borderRadius: "4px",
      "&:focus": {
        boxShadow: "2px 4px 8px rgba(0,0,0,0.1)",
      },
      "&:hover": {
        boxShadow: "2px 4px 8px rgba(92,92,92,0.1)",
        transition: ".3s",
      },
    },
    "& .MuiSelect-root": {
      padding: "10px 10px",
    },
  },

  inputWithLabel: {
    "& .MuiInputBase-root": {
      border: "none",
      borderBottom: "none",
      borderRadius: "4px",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiInputBase-multiline": {
      padding: 0,
    },
    "& textarea": {
      padding: "27px 10px 10px",
      "&:focus": {
        boxShadow: "2px 4px 8px rgba(0,0,0,0.1)",
      },
      "&:hover": {
        boxShadow: "2px 4px 8px rgba(92,92,92,0.1)",
        transition: ".3s",
      },
    },
    "& input": {
      borderRadius: "4px",
      "&:focus": {
        boxShadow: "2px 4px 8px rgba(0,0,0,0.1)",
      },
      "&:hover": {
        boxShadow: "2px 4px 8px rgba(92,92,92,0.1)",
        transition: ".3s",
      },
    },
  },

  transparent: {
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.background.paper,
    },
    "& input:focus + div > button": {
      opacity: 1,
    },
    "& div > button": {
      opacity: 0,
    },
  },

  icon: {
    position: "absolute",
    right: theme.spacing(1),
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },

  withIcon: {
    "& input": {
      paddingRight: theme.spacing(5),
    },
    "& > div": {
      paddingRight: 0,
    },
  },
}));