import { PracticeType } from "../types/practiceType";
import { AllData } from "./practiceDetailFunctions";

export const validateOverview = (obj: PracticeType) => {
  if (
    obj.objective === null ||
    !obj.objective ||
    !obj.objective[0] ||
    !obj.startingPosition ||
    obj.categoryId === null ||
    obj.categoryId === 0 ||
    typeof obj.categoryId === 'string' ||
    !obj.title ||
    !obj.description ||
    !obj.personsInvolved ||
    !obj.duration ||
    !obj.scope ||
    !obj.scopeAmount ||
    !obj.scopePerUnit ||
    !obj.club
  ) {
    return false;
  }
  return true;
};

export const validateFinancials = (obj: PracticeType) => {
  if (
    !obj.costs ||
    !obj.sponsors ||
    !obj.sponsors[0] ||
    ((obj.sponsors as AllData[]).some(item => item.id === 2 || item.id === 3 || item.id === 4) && !obj.financialAssistanceInfo) ||
    ((obj.sponsors as AllData[]).some(item => item.id === 2 || item.id === 3 || item.id === 4) && 
    (obj.amount === null || obj.amount === undefined)) ||
    ((obj.sponsors as AllData[]).some(item => item.id === 2) && !obj.arguments) ||
    ((obj.sponsors as AllData[]).some(item => item.id === 3) && !obj.funding) ||
    ((obj.sponsors as AllData[]).some(item => item.id === 4) && !obj.advertising)
  ) {
    return false;
  }
  return true;
};

export const validateClubInfo = (obj: PracticeType) => {
  if (
    !obj.teamType ||
    !obj.members ||
    !obj.teamComposition ||
    (obj.teamComposition as string[][]).every(item => +item[1] === 0)
  ) {
    return false;
  }
  return true;
};

export const validateDraft = (obj: PracticeType) => {
  if(!obj.title ||
    !obj.categoryId ||
    typeof obj.categoryId === 'string'
  ) {
    return false;
  }
  return true;
}