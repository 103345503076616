import React, { FC } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useStyles } from "./CustomAlertStyles";

const Alert = React.forwardRef((props: AlertProps, ref) => (
  <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />
));

const ref = React.createRef();

type Props = {
  open: boolean;
  onClose: () => void;
  severity: "error" | "success" | "warning";
  message: string;
};

const CustomAlert: FC<Props> = ({ open, onClose, severity, message}) => {

  const classes = useStyles();

  return (
    <Snackbar
      ref={ref}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      className={classes.alertWrapper}
    >
      <Alert
        onClose={onClose}
        severity={severity === "success" ? undefined : severity}
        className={severity === "success" ? classes.alertSuccess : ""}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
