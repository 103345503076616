import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },

  required: {
    color: theme.palette.error.main
  },

  checkbox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));
