/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, FC, useContext } from "react";
import axios from "axios";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";

import * as Icon from "../../utils/icons";
import { userRefreshSession } from "../../apiURL/httpUsers";
import ComfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { messages } from "../../locales/translations";

const SessionModal: FC = () => {
  const [modalMessage, setModalMessage] = useState("");
  const [modalSubText, setModalSubText] = useState("");
  const [counterStart, setCounterStart] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [checkTime, setCheckTime] = useState(2700000); // 45 min
  const [lang, setLang] = useState(true)

  const { buttonList, messagesList, siteLanguage } = useContext(
    LanguageContext
  ) as LanguageContextType;

  useEffect(() => {
    setLang(siteLanguage);
  }, [siteLanguage])

  useEffect(() => {
    if (!counterStart) {
      return;
    }
    const timer = setTimeout(() => {
      setSessionEnded(true);
      setModalMessage(messagesList.sessionEnded);
      setModalSubText("");
    }, 900000); // 15 min
    return () => clearTimeout(timer);
  }, [counterStart]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const reqDate = sessionStorage.getItem("lastRequest");
      const currDate = new Date();
      const startDate = reqDate && new Date(JSON.parse(reqDate));
      if (!startDate) {
        return;
      }

      const diff = currDate.getTime() - startDate.getTime();
      const endTime = 3600000 - diff; // time left in the session(60min)

      // if the time left is less than 15 min, show notification
      if (endTime <= 900000) {
        setSessionEnded(false);
        setCounterStart(true);
        setOpenModal(true);
        setModalSubText(lang ? messages.de.sessionWillEnd : messages.en.sessionWillEnd);
        setModalMessage(lang ? messages.de.continue : messages.en.continue);
        return () => clearTimeout(timer);
      } else {
        setCheckTime(endTime - 900000); // 15 min
      }
    }, checkTime);
    return () => clearTimeout(timer);
  }, [checkTime]);

  const handleClose = () => {
    setOpenModal(false);
    setModalMessage("");
    setModalSubText("");
  };

  return (
    <ComfirmationModal
      open={openModal}
      handleClose={handleClose}
      subText={modalSubText}
      disableClose
      disableFocus
      hideFirstButton
      handleSecondBtn={() => {
        sessionEnded
          ? window.location.reload()
          : axios
              .get(userRefreshSession, { withCredentials: true })
              .then(() => {
                handleClose();
                setCheckTime(2700000); // 45 min
                setCounterStart(false);
              })
              .catch(() => {
                console.log("Could not extend session time");
              })
              .finally(() => {
                sessionStorage.setItem(
                  "lastRequest",
                  JSON.stringify(new Date())
                );
              });
      }}
      message={modalMessage}
      secondButtonData={
        sessionEnded
          ? {
              buttonText: buttonList.refresh,
              buttonColor: "secondary",
              buttonIcon: <Icon.RefreshIcon />,
            }
          : {
              buttonText: buttonList.continueSession,
              buttonColor: "secondary",
              buttonIcon: <></>,
            }
      }
    />
  );
};

export default SessionModal;
