export const userDetail = {
  de: {
    email: "E-Mail",
    club: "Verein",
    profile: "Profil",
    name: "Name",
    firstName: "Vorname",
    lastName: "Nachname",
    telephone: "Telefon",
    location: "Standort",
    personalInfo: "Persönliche Informationen",
    role: "Rolle",
    category: "Kategorie",
    categories: "Kategorien",
    application: "Applikation",
    systemAdmin: "Systemadministrator",
    editor: "Bearbeiter",
    noRole: "Keine Rolle",
    praxisprofi: "Praxisprofi",
    lotteries: "Gewinnspiele",
    authorisation: "Berechtigungen",
    auditor: "Prüfer",
    admin: "Admin",
    addUser: "User hinzufügen",
  },
  
  en: {
    email: "E-mail",
    club: "Club",
    profile: "Profile",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    telephone: "Telephone",
    location: "Location",
    personalInfo: "Personal Information",
    role: "Role",
    category: "Category",
    categories: "Categories",
    application: "Application",
    systemAdmin: "System administrator",
    editor: "Editor",
    noRole: "No Role",
    praxisprofi: "Praxisprofi",
    lotteries: "Lotteries",
    authorisation: "Authorisations",
    auditor: "Auditor",
    admin: "Admin",
    addUser: "Add a user",
  },
};