import React, { FC, useContext, useEffect, useState } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";

import * as Icon from "../../utils/icons";
import { useStyles } from "./UserAuthModalStyles";
import CustomAutocompleteSingle from "../CustomAutocompleteSingle/CustomAutocompleteSingle";
import CustomAutocomplete from "../CustomAutocomplete/CustomAutocomplete";
import { AllData } from "../../utils/practiceDetailFunctions";
import { Role, Category } from "../../types/userType";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

type Props = {
  allCategories: AllData[];
  currentValues: {
    applications: AllData[];
    roles: Role[];
    categories: Category[];
  };
  initialValues: {
    application: AllData | null;
    role: AllData | null;
    categories: AllData[];
  };
  open: boolean;
  handleClose: () => void;
  functionality: "edit" | "add";
  handleSave: () => void;
  handleDelete: () => void;
  handleCloseBtn: () => void;
  handleValueChange: (key: string, value: AllData | null) => void;
  handleMultiValueChange: (value: AllData[]) => void;
  allRoles: AllData[];
};

const UserAuthModal: FC<Props> = ({
  open,
  handleClose,
  allCategories,
  functionality,
  handleSave,
  handleDelete,
  handleCloseBtn,
  handleValueChange,
  handleMultiValueChange,
  currentValues,
  initialValues,
  allRoles,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [successVal, setSuccessVal] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    functionality === "edit" && setActiveStep(1);
  }, [functionality]);

  const classes = useStyles();
  const {
    buttonList,
    userDetailList,
    optionList,
    messagesList,
    roleList,
    siteLanguage,
  } = useContext(LanguageContext) as LanguageContextType;

  const allRolesLott = [{ id: 1, value: userDetailList.auditor }];

  const allApps = [
    { id: 1, value: userDetailList.praxisprofi },
    // { id: 2, value: userDetailList.lotteries },
  ];

  const steps = [
    userDetailList.application,
    userDetailList.role,
    userDetailList.authorisation,
  ];

  const filteredRoles = allRoles.filter(
    (role) => !currentValues.roles.map((it) => it.roleId).includes(role.id)
  ).map(it => ({id: it.id, value: roleList[it.value.toLowerCase()]}));

  const currRoleValue: AllData[] = currentValues.roles.map((it) => ({
    id: it.roleId,
    value: roleList[it.name.toLowerCase()],
  }));

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <CustomAutocompleteSingle
              id="application-select"
              valueId={initialValues.application}
              onChange={(event, newInputValue) => {
                handleValueChange("application", newInputValue);
              }}
              disabled={functionality === "edit"}
              items={allApps}
              placeholder={
                siteLanguage
                  ? `${
                      userDetailList.application
                    } ${optionList.select.toLowerCase()}`
                  : `${
                      optionList.select
                    } ${userDetailList.application.toLowerCase()}`
              }
            />
            {!successVal && !initialValues.application && (
              <Typography variant="body2" className={classes.errorMessage}>
                {messagesList.emptyFields}
              </Typography>
            )}
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="subtitle2">
              {userDetailList.application}
            </Typography>
            <Typography variant="body2">
              {initialValues.application && initialValues.application.value}
            </Typography>
            <Box mb={2} />
            <CustomAutocompleteSingle
              id="role-select"
              valueId={initialValues.role}
              disabled={functionality === "edit"}
              onChange={(event, newInputValue) => {
                handleValueChange("role", newInputValue);
              }}
              items={
                initialValues.application && initialValues.application.id === 1
                  ? functionality === "edit"
                    ? currRoleValue
                    : filteredRoles
                  : allRolesLott
              }
              placeholder={
                siteLanguage
                  ? `${userDetailList.role} ${optionList.select.toLowerCase()}`
                  : `${optionList.select} ${userDetailList.role.toLowerCase()}`
              }
            />
            {!successVal && !initialValues.role && (
              <Typography variant="body2" className={classes.errorMessage}>
                {messagesList.emptyFields}
              </Typography>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="subtitle2">
              {userDetailList.application}
            </Typography>
            <Typography variant="body2">
              {initialValues.application && initialValues.application.value}
            </Typography>
            <Box mb={2} />
            <Typography variant="subtitle2">{userDetailList.role}</Typography>
            <Typography variant="body2">
              {initialValues.role && initialValues.role.value}
            </Typography>
            <Box mb={2} />
            {initialValues.role && initialValues.role.value === roleList.editor && (
              <>
                <CustomAutocomplete
                  id="category-select"
                  valueId={initialValues.categories}
                  onChange={(event, newValue) => {
                    handleMultiValueChange(newValue);
                  }}
                  items={allCategories}
                  placeholder={
                    siteLanguage
                      ? `${
                          userDetailList.categories
                        } ${optionList.select.toLowerCase()}`
                      : `${
                          optionList.select
                        } ${userDetailList.categories.toLowerCase()}`
                  }
                />

                {!successVal && !initialValues.categories[0] && (
                  <Typography variant="body2" className={classes.errorMessage}>
                    {messagesList.emptyFields}
                  </Typography>
                )}
              </>
            )}
          </>
        );
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    if (activeStep === 0 && !initialValues.application) {
      setSuccessVal(false);
    } else if (activeStep === 1 && !initialValues.role) {
      setSuccessVal(false);
    } else {
      setActiveStep(activeStep + 1);
      setSuccessVal(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseButton = () => {
    handleClose();
    handleCloseBtn(); //comes from parent (sets values to 0)
    setSuccessVal(true);
    setActiveStep(0);
  };

  const handleSaveBtn = () => {
    if (!initialValues.role || !initialValues.application) {
      return;
    }
    if (initialValues.application.id !== 1) {
      handleCloseButton();
      return;
    }
    if (
      activeStep === 2 &&
      initialValues.role.value.toLowerCase() === roleList.editor.toLowerCase() &&
      !initialValues.categories[0]
    ) {
      setSuccessVal(false);
    } else {
      handleSave();
      handleCloseButton();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography variant="subtitle1" className={classes.title}>
                {userDetailList.authorisation}
              </Typography>
              <Hidden xsDown>
                <Button
                  size="small"
                  variant="contained"
                  className={classes.closeBtn}
                  startIcon={
                    <Icon.AddCircle style={{ transform: "rotate(45deg)" }} />
                  }
                  onClick={handleCloseButton}
                  disableElevation
                >
                  {buttonList.cancel}
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  size="small"
                  className={classes.closeBtnSmall}
                  onClick={handleCloseButton}
                >
                  <Icon.AddCircle
                    style={{ transform: "rotate(45deg)" }}
                    fontSize="small"
                  />
                </IconButton>
              </Hidden>
            </Box>
            <Divider />
            <Box mt={2} />

            <Stepper
              activeStep={activeStep}
              nonLinear={functionality === "edit"}
              className={classes.stepperRoot}
            >
              {steps.map((label, index) => (
                <Step key={label} className={classes.stepIconWrapper}>
                  <StepButton icon={""} onClick={() => setActiveStep(index)}>
                    <div className={classes.stepIcon}>
                      <Hidden xsDown>{label}</Hidden>
                      <Hidden smUp>{index + 1}</Hidden>
                    </div>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{ minHeight: "200px" }}
            >
              <Box px={1}>{getStepContent(activeStep)}</Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Box>
                  {activeStep === 1 && functionality === "edit" && (
                    <>
                      <Hidden xsDown>
                        <Button
                          onClick={() => setConfirmModalOpen(true)}
                          size="small"
                          className={classes.closeBtn}
                          startIcon={<Icon.DeleteIcon />}
                        >
                          {buttonList.deleteRole}
                        </Button>
                      </Hidden>
                      <Hidden smUp>
                        <IconButton
                          size="small"
                          className={classes.closeBtnSmall}
                          onClick={() => setConfirmModalOpen(true)}
                        >
                          <Icon.DeleteIcon fontSize="small" />
                        </IconButton>
                      </Hidden>
                    </>
                  )}
                </Box>
                <Box>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} size="small">
                      {buttonList.back}
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation
                      className={classes.button}
                      onClick={handleSaveBtn}
                      startIcon={<Icon.Save fontSize="small" />}
                    >
                      {buttonList.save}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation
                      className={classes.button}
                      onClick={handleNext}
                      endIcon={<Icon.ArrowForwardIcon fontSize="small" />}
                    >
                      {buttonList.next}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
      <ConfirmationModal
        open={confirmModalOpen}
        message={messagesList.reallyDelete}
        handleClose={() => setConfirmModalOpen(false)}
        handleSecondBtn={() => {
          setConfirmModalOpen(false);
          handleDelete();
          handleCloseButton();
        }}
      />
    </>
  );
};

export default UserAuthModal;
