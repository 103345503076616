import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import * as Icon from "../../utils/icons";
import { useStyles } from "./AddButtonStyles";

type Props = {
  onClick?: () => void;
};

const AddButton: FC<Props> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={onClick}
      className={classes.addButton}
    >
      <Icon.Add />
    </Button>
  );
};

export default AddButton;
