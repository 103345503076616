type MessageFields = {
  key: string;
  active: boolean;
};

export const messageFields: MessageFields[] = [
  {
    key: "scope",
    active: false,
  },
  {
    key: "startingPosition",
    active: false,
  },
  {
    key: "description",
    active: false,
  },
  {
    key: "amount",
    active: false,
  },
  {
    key: "advertising",
    active: false,
  },
  {
    key: "funding",
    active: false,
  },
  {
    key: "arguments",
    active: false,
  },
  {
    key: "financialAssistanceInfo",
    active: false,
  },
  {
    key: "effectiveness",
    active: false,
  }
];