/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, FC, useEffect, useState, useRef } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import * as Icon from "../../utils/icons";
import IconButton from "@material-ui/core/IconButton";
import CustomAlert from "../CustomAlert/CustomAlert";
import Error from "../Error/Error";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useStyles } from "./DocumentCardStyles";
import { FileType } from "../../types/fileType";
import { deleteById, getRequest, post, upload } from "../../utils/apiRequests";
import {
  fileDelete,
  fileGetAllById,
  fileSetMain,
  fileUpload,
} from "../../apiURL/httpFiles";
import { truncate } from "../../utils/helperFunctions";

const allowedTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

type Props = {
  bpId: number;
  files: FileType[];
  validateImg: boolean;
  disableEditing: boolean;
};

const DocumentCard: FC<Props> = ({
  bpId,
  files,
  validateImg,
  disableEditing,
}) => {
  const [fileData, setFileData] = useState<FileType[]>(files);
  const [updated, setUpdated] = useState("");
  const [validateMessage, setValidateMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [activeFile, setActiveFile] = useState<FileType>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletableId, setDeletableId] = useState(-1);

  const { practiceDetailList, buttonList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;
  const classes = useStyles();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getRequest(fileGetAllById(bpId))
        .then((res) => {
          setFileData(res);
          setError(false);
          setLoading(false);
          setLoadingFile(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
          setLoadingFile(false);
        });
    }
  }, [updated]);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    if (fileData && fileData.length >= 5) {
      setValidateMessage(messagesList.fileCount);
      return;
    }
    if (!allowedTypes.includes(e.target.files[0].type)) {
      setValidateMessage(messagesList.fileType + "JPEG, JPG, PNG, PDF");
      return;
    }
    if (e.target.files[0].size > 5000000) {
      setValidateMessage(messagesList.fileSize + "5MB");
      return;
    }

    const newFile = e.target.files[0];
    const main =
      fileData.every((file) => !file.mainFile) &&
      newFile.type !== "application/pdf";

    const formData = new FormData();
    formData.append("file", newFile);
    setLoadingFile(true);
    upload(fileUpload(bpId), formData, main)
      .then(() => {
        setUpdated(newFile.name);
      })
      .catch(() => {
        setLoadingFile(false);
        setValidateMessage(messagesList.unableToUploadImg);
      });
  };

  const handleDelete = (id: number) => {
    deleteById(fileDelete(id))
      .then(() => {
        setUpdated(id.toString() + "delete");
      })
      .catch(() => setValidateMessage(messagesList.unableToDeleteImg));
  };

  const handleMain = (id: number) => {
    post(fileSetMain(id))
      .then(() => setUpdated(id.toString() + "setMain"))
      .catch(() => setValidateMessage(messagesList.unableToSetMain));
  };

  const handleOpenModal = (id: number) => {
    if (!fileData) {
      return;
    }
    const file = fileData.find((file) => file.id === id);
    if (file) {
      setActiveFile(file);
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };

  const fullScreen = () => {
    if (!activeFile) {
      return;
    }
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
      >
        <Fade in={modalOpen}>
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.modalContent}
          >
            <div className={classes.imageWrapperFull}>
              <img
                src={activeFile.storageUri}
                className={classes.imageFull}
                alt={activeFile.name}
              />
              <IconButton
                className={classes.closeBtn}
                onClick={() => setModalOpen(false)}
              >
                <Icon.Clear />
              </IconButton>
            </div>
          </Box>
        </Fade>
      </Modal>
    );
  };

  return (
    <>
      <Paper elevation={1} className={classes.root}>
        <Typography
          variant="body1"
          className={
            validateImg && fileData.every((it) => !it.mainFile)
              ? classes.required
              : ""
          }
        >
          {practiceDetailList.documents}
        </Typography>
        {error || loading || !fileData ? (
          <Box mb={4}>
            <Error loading={loading} size="small" error={error} />
          </Box>
        ) : fileData && !fileData[0] ? (
          <Box my={2}>
            <Typography variant="body2">{messagesList.noFiles}</Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            flexBasis="100%"
            my={2}
          >
            {fileData.map((file) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={file.id}
                  ml={1}
                  mb={1}
                >
                  <div
                    className={`${classes.imageWrapper} ${
                      file.mainFile ? classes.mainImage : ""
                    }`}
                  >
                    {file.type !== "application/pdf" ? (
                      <img
                        className={classes.image}
                        src={file.storageUri}
                        alt={file.name}
                      />
                    ) : (
                      <Box className={classes.documentBg}>
                        <Icon.PictureAsPdfIcon fontSize="large" />
                      </Box>
                    )}

                    <div
                      className={classes.overlay}
                      onClick={() =>
                        file.type !== "application/pdf"
                          ? handleOpenModal(file.id)
                          : window.location.href = file.storageUri
                      }
                    />
                    {!disableEditing && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setConfirmModalOpen(true);
                          setDeletableId(file.id);
                        }}
                        className={classes.deleteIcon}
                      >
                        <Icon.DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                    {!disableEditing && file.type !== "application/pdf" && (
                      <IconButton
                        size="small"
                        color={file.mainFile ? "secondary" : "default"}
                        onClick={() => handleMain(file.id)}
                        className={
                          file.mainFile
                            ? classes.starIconMain
                            : classes.starIcon
                        }
                      >
                        {file.mainFile ? (
                          <Icon.StarIcon fontSize="small" />
                        ) : (
                          <Icon.StarBorder fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </div>
                  <Typography variant="body2">
                    {truncate(file.name, 11)}
                  </Typography>
                  {file.mainFile && (
                    <Box display="flex" className={classes.mainText}>
                      <Icon.StarIcon
                        className={classes.mainTextIcon}
                        color="secondary"
                      />
                      {practiceDetailList.mainPic}
                    </Box>
                  )}
                </Box>
              );
            })}
            <Box className={loadingFile ? classes.imageWrapper : ""}>
              <Error loading={loadingFile} size="extraSmall" />
            </Box>
          </Box>
        )}
        {!disableEditing && (
          <Box display="flex" justifyContent="center" mt={0}>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              component="label"
              htmlFor="upload"
            >
              {buttonList.upload}
              <input
                id="upload"
                type="file"
                hidden
                onChange={handleUpload}
                accept=".jpeg,.jpg,.pdf,.png"
              />
            </Button>
          </Box>
        )}
      </Paper>
      <CustomAlert
        open={validateMessage ? true : false}
        onClose={() => setValidateMessage("")}
        severity="error"
        message={validateMessage}
      />
      <ConfirmationModal
        open={confirmModalOpen}
        message={messagesList.reallyDeleteFile}
        handleClose={() => setConfirmModalOpen(false)}
        handleSecondBtn={() => {
          setConfirmModalOpen(false);
          handleDelete(deletableId);
        }}
      />
      {fullScreen()}
    </>
  );
};

export default DocumentCard;
