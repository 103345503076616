/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { PracticeType } from "../../types/practiceType";
import dayjs from "dayjs";
import { useStyles } from "./StatusCardStyles";
import { capitalize } from "../../utils/helperFunctions";
import { UserType } from "../../types/userType";

type Props = {
  data: PracticeType;
  userName: string | undefined;
};

const dateFormat = "HH:mm DD.MM.YYYY"

const StatusCard: FC<Props> = ({ data, userName }) => {
  const { practiceDetailList, statusList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const classes = useStyles();
  
  const dataObj = data;
  const draft = dataObj.status === "DRAFT";
  const approved = dataObj.status === "APPROVED";
  const denied = dataObj.status === "DENIED";
  const created = dataObj.status === "CREATED";
  const submitted = dataObj.status === "SUBMITTED";
  const published = dataObj.status === "PUBLISHED";
  const edited = (dataObj.dateEdited) ? true : false;

  const getName = (obj: UserType) => {
    if (obj.name && obj.surname) {
      return `${obj.name} ${obj.surname}`
    } else if (obj.name && !obj.surname) {
      return obj.name
    } else if (!obj.name && obj.surname) {
      return obj.surname
    } else {
      return ""
    }
  }

  return (
    <Paper elevation={1} style={{ padding: "1.5rem" }}>
      <Typography variant="subtitle2" className={classes.subTitle}>
        STATUS
      </Typography>
      <Typography variant="body2">
        {capitalize(statusList[dataObj.status.toLowerCase()])}
      </Typography>

      {/* .... AT */}
      <Typography variant="subtitle2" className={classes.subTitle}>
        {(draft || created) && practiceDetailList.createdAt}
        {(approved || published) && practiceDetailList.approvedAt}
        {denied && practiceDetailList.deniedAt}
        {submitted && practiceDetailList.submittedAt}
      </Typography>
      <Typography variant="body2">
        {(draft || submitted) && dayjs(dataObj.submitted).format(dateFormat)}
        {(created && dataObj.dateCreated) && dayjs(dataObj.dateCreated).format(dateFormat)}
        {((approved || published) && dataObj.dateApproved) && dayjs(dataObj.dateApproved).format(dateFormat)}
        {(denied && dataObj.dateRejected) && dayjs(dataObj.dateRejected).format(dateFormat)}
      </Typography>

      {/* EDITED */}
      {((created || submitted) && edited) && (
        <>
          <Typography variant="subtitle2" className={classes.subTitle}>
            {practiceDetailList.lastEdit}
          </Typography>
          <Typography variant="body2">
            {dayjs(dataObj.dateEdited!).format(dateFormat)}
          </Typography>
          <Typography variant="subtitle2" className={classes.subTitle}>
            {practiceDetailList.editedBy}
          </Typography>
          <Typography variant="body2">
            {dataObj.editedBy && getName(dataObj.editedBy)}
          </Typography>
        </>
      )}

      {/* ..... BY */}
      <Typography variant="subtitle2" className={classes.subTitle}>
        {userName && draft && practiceDetailList.createdBy}
        {userName && (created && !edited) && practiceDetailList.createdBy}
        {(approved || published) && practiceDetailList.approvedBy}
        {denied && practiceDetailList.deniedBy}
        {userName && (submitted && !edited) && practiceDetailList.submittedBy}
      </Typography>
      <Typography variant="body2">
        {userName && (draft || (submitted && !edited)) && userName}
        {userName && (created && !edited) && userName}
        {((approved || published) && dataObj.approvedBy) && getName(dataObj.approvedBy)}
        {(denied && dataObj.rejectedBy) && getName(dataObj.rejectedBy)}
      </Typography>
    </Paper>
  );
};

export default StatusCard;
