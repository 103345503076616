import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(1),
  },

  selectWrapper: {
    maxWidth: "300px",
    [theme.breakpoints.down(620)]: {
      maxWidth: "100%",
    },
  },

  subTitle2: {
    fontSize: "0.95rem",
    letterSpacing: "2px",
  },

  groupItem: {
    width: "100%",
    maxWidth: "175px",
    "&:first-child": {
      maxWidth: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      width: "auto",
      maxWidth: "100%",
      "&:first-child": {
        marginBottom: theme.spacing(1),
        maxWidth: "100%",
        flexShrink: 0,
        width: "100%"
      },
    },
    [theme.breakpoints.down(400)]: {
      width: "100%",
    },
  },

  groupItemXs: {
    maxWidth: "60px",
    flexShrink: 0
  },

  groupItemXsText: {
    wordBreak: "break-word"
  },

  mainTitle: {
    [theme.breakpoints.down(490)]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "0.85rem",
    },
  },

  message: {
    padding: '10px',
    width: '200px',
    color: theme.palette.error.main,
    borderRadius: "4px",
    fontSize: "0.75rem",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 3px 8px rgba(0,0,0,0.1)",
  }, 
  
  required: {
    color: theme.palette.error.main,
  },

  text: {
    wordBreak: "break-word",
  }
}));
