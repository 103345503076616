import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxShadow: `0 3px 6px ${theme.palette.grey.A100}`,
    borderRadius: 0,
    minHeight: "410px",
    maxWidth: "320px",
    width: "100%",
    minWidth: "320px",
    margin: "16px 8px",
    paddingBottom: "50px",
    "& p": {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "16px 0",
    },
    "&:hover": {
      boxShadow: `0 5px 12px ${theme.palette.grey.A200}`,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "262px",
    },

  },

  cardRootXs: {
    minHeight: "300px",
    paddingBottom: 0,
  },

  content: {
    padding: "12px",
    "&:last-child": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },

  cardHead: {
    display: "flex",
    justifyContent: "space-between",
  },

  cardTitle: {
    fontSize: "1rem",
    margin: 0,
    [theme.breakpoints.down(450)]: {
      fontSize: "0.85rem",
    },
  },

  cardImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },

  cardFooter: {
    position: "absolute",
    alignSelf: "flexEnd",
    bottom: 0,
    right: 0,
    width: "100%",
  },

  imgWrapper: {
    position: "relative",
  },

  imgText: {
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.secondary.contrastText,
    zIndex: 2,
    fontSize: "1rem",
    fontWeight: 700,
  },

  imgOverlay: {
    position: "absolute",
    bottom: "3px",
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(62,62,62,0.1) 40%, rgba(255,255,255,0) 100%)",
  },

  actionArea: {
    width: "100%",
    paddingBottom: theme.spacing(2),
    "& .MuiCardActionArea-focusHighlight": {
      backgroundColor: theme.palette.background.paper,
    },
  },

  buttons: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiButtonBase-root:last-child": {
        display: "none",
      },
    },
  },

  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "120%",
  },
}));
