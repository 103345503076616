export const messages = {
  de: {
    server: "Serverfehler, bitte versuche es später erneut.",
    noData: "Die von dir gesuchte Seite existiert nicht.",
    empty: "Keine Daten zum anzeigen.",
    authorMissing: "Autorendaten fehlen.",
    noFiles: "Es wurden keine Dateien hinzugefügt.",
    noPraxisprofi: "Der Nutzer hat bisher noch keine Best Practices eingereicht.",
    noLoadPraxisprofi: "Praxisprofi konnte nicht geladen werden.",
    noLoadUser: "Die Nutzerdaten konnten nicht geladen werden.",
    successfulSave: "Erfolgreich gespeichert!",
    madatoryFields: "Wir bitten dich, alle erforderlichen Felder auszufüllen.",
    emptyFields: "Bitte fülle alle erforderlichen Felder aus.",
    unableToSave: "Deine Daten konnten nicht gespeichert werden.",
    unableToDeleteRole: "Die Rolle konnte nicht gelöscht werden.",
    unableToDeleteImg: "Die Datei konnte nicht gelöscht werden.",
    unableToUploadImg: "Die Datei konnte nicht hochgeladen werden.",
    reallyDelete: "Möchtest du die Rolle wirklich löschen?",
    reallyDeleteFile: "Möchtest du die Datei wirklich löschen?",
    fileSize:
      "Die ausgewählte Datei überschreitet die maximale Größe. Die maximale Dateigröße beträgt ",
    fileType:
      "Das ausgewählte Dateiformat entspricht nicht den Kriterien. Bitte wähle eines der folgenden Formate: ",
    fileCount: "Es können nur maximal 5 Dateien hochgeladen werden.",
    noResults: "Es wurden keine Ergebnisse gefunden.",
    reallyDeny: "Möchtest du das Best-Practice-Beispiel wirklich ablehnen?",
    reallyApprove: "Möchtest du das Best-Practice-Beispiel wirklich genehmigen?",
    reallySubmit: "Möchtest du das Best-Practice-Beispiel wirklich einreichen?",
    reallyCreate: "Möchtest du das Best-Practice-Beispiel wirklich erstellen?",
    noMainPic: "Es muss mindestens ein Bild hochgeladen werden.",
    saveBeforeSubmit: "Bitte speichere deine Daten vor dem Absenden!",
    successfulSubmit: "Das Best-Practice-Beispiel wurde erfolgreich eingereicht!",
    successfulCreate: "Das Best-Practice-Beispiel wurde erfolgreich erstellt!",
    successfulApprove: "Das Best-Practice-Beispiel wurde erfolgreich genehmigt!",
    successfulDenied: "Das Best-Practice-Beispiel wurde abgelehnt!",
    saveBeforeAction: "Bitte speichere deine Daten vor jeder weiteren Aktion!",
    copyright: "Bitte stelle sicher, dass alle Datenschutzrichtlinien und Nutzungsbestimmungen akzeptiert sind.",
    notifySubmitter: "Möchtest du wirklich eine Email Benachrichtigung an den Einreicher schicken?",
    lowCapacityWarning: "Warnung! Der Speicherplatz ist gering.",
    dataBaseSpace: "Die Speicherauslastung der Datenbank liegt bei 90%. Bitte veranlasse eine baldige Erhöhung des Speichers.",
    validEmail: "Bitte gib eine gültige E-Mail Adresse ein.",
    emailExists: "Die E-Mail Adresse existiert bereits.",
    failedToLogout: "Abmeldung nicht möglich! Bitte versuche es später erneut.",
    sessionWillEnd: "Deine Sitzung läuft in Kürze aus.",
    continue: "Möchtest du fortfahren?",
    sessionEnded: "Die aktuelle Sitzung wurde beendet. Bitte lade die Seite neu.",
    maxCharCount: "Die maximale Zeichenanzahl ist ",
    validNumber: "Bitte gib eine Zahl zwischen 1 - 99 ein.",
    validNumber2: "Bitte gib eine gültige Nummer ein.",
    noOptions: "Keine Auswahlmöglichkeiten",
    reallyDeleteDraft: "Möchtest du wirklich den Entwurf dieses Best Practices löschen?",
    unableToDeleteDraft: "Der Entwurf der Best Practice konnte nicht gelöscht werden.",
    successfulDeleteDraft: "Der Entwurf wurde erfolgreich gelöscht!",
    unableToSetMain: "Die Datei konnte nicht als Hauptbild festgelegt werden.",
    typeToFind: "Gib mindestens 3 Zeichen ein, um einen Verein zu finden"
  },
  
  en: {
    server: "Server error, please try again later",
    noData: "The page you are looking for doesn't exist",
    empty: "No data to show",
    authorMissing: "Author data missing",
    noFiles: "No files added",
    noPraxisprofi: "User hasn't submitted any best practice",
    noLoadPraxisprofi: "Couldn't load Praxisprofi",
    noLoadUser: "Couldn't load user data",
    successfulSave: "Successfully saved!",
    madatoryFields: "Please fill out all the mandatory fields!",
    emptyFields: "Please fill out all the fields",
    unableToSave: "Unfortunately, we were unable to save your data",
    unableToDeleteRole: "Unfortunately, we were unable to delete the role",
    unableToDeleteImg: "Unfortunately, we were unable to delete the file",
    unableToUploadImg: "Unfortunately, we were unable to upload the file",
    reallyDelete: "Do you really want to delete the role?",
    reallyDeleteFile: "Do you really want to delete the file?",
    fileSize: "Selected file exceeds maximum size. Maximum file size is ",
    fileType:
      "Selected file format doesn't match the criteria. Please choose one of the fallowing formats: ",
    fileCount: "You can only upload a maximum of 5 files",
    noResults: "No results found",
    reallyDeny: "Do you really want to deny?",
    reallyApprove: "Do you really want to approve?",
    reallySubmit: "Do you really want to submit?",
    reallyCreate: "Do you really want to create?",
    noMainPic: "Please upload a picture!",
    saveBeforeSubmit: "Please save your data before submitting!",
    successfulSubmit: "Successfully submitted!",
    successfulCreate: "Successfully created!",
    successfulApprove: "Successfully approved!",
    successfulDenied: "Praxisprofi denied!",
    saveBeforeAction: "Please save your data before any further action!",
    copyright: "Please make sure that Privacy Policy and Terms and conditions are accepted.",
    notifySubmitter: "Send email notification to submitter?",
    lowCapacityWarning: "Low capacity warning",
    dataBaseSpace: "90% of database storage capacity is being used. Please arrange for the capacity to be increased as soon as possible.",
    validEmail: "Please enter a valid email address",
    emailExists: "E-mail address already exists",
    failedToLogout: "Unable to logout, please try again later",
    sessionWillEnd: "Your current session will end shortly.",
    continue: "Would you like to continue?",
    sessionEnded: "Current session has ended, please reload the page.",
    maxCharCount: "Max character count is ",
    validNumber: "Please enter a number between 1 - 99",
    validNumber2: "Please enter a valid number",
    noOptions: "No options",
    reallyDeleteDraft: "Do you really want to delete the draft of the Best Practice?",
    unableToDeleteDraft: "Unfortunately, we were unable to delete the draft of the Best Practice.",
    successfulDeleteDraft: "Draft has been successfully deleted!",
    unableToSetMain: "Unfortunately, we were unable to set the main image.",
    typeToFind: "Type at least 3 characters to find a club"
  },
};
