import React, { useContext, FC } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../../context/LanguageContext";
import * as Icon from "../../../utils/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "../PracticeStyles";

type Props = {
  title: string;
  editable: boolean;
  validated: boolean;
  onClick: () => void;
  hide: boolean;
};

const PaperHeader: FC<Props> = ({ title, editable, onClick, validated, hide }) => {
  const {
    buttonList,
    practiceDetailList,
  } = useContext(LanguageContext) as LanguageContextType;

  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        flexWrap="wrap"
      >
        <Typography
          variant="subtitle1"
          className={title === practiceDetailList.club ? classes.mainTitle : ""}
        >
          {title}
        </Typography>
        {!hide && (
          <Button
            size="small"
            color="default"
            startIcon={editable ? <Icon.Save /> : <Icon.Edit />}
            onClick={onClick}
            className={validated && editable ? classes.required : ""}
          >
            {editable ? buttonList.save : buttonList.edit}
          </Button>
        )}
      </Box>
      <Divider />
      <Box mt={2} />
    </>
  );
};

export default PaperHeader;
