import React, { useContext, useState, FC } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import Header from "./components/Header";
import CustomInput from "../CustomInput/CustomInput";
import CustomAutocomplete from "../CustomAutocomplete/CustomAutocomplete";
import CustomAutocompleteSingle from "../CustomAutocompleteSingle/CustomAutocompleteSingle";
import { PracticeType } from "../../types/practiceType";
import { AllDataType, modifyData } from "../../utils/getAllData";
import { messageFields } from "../../data/practice-detail/practiveDetail-messageFields";
import {
  handleActiveMessages,
  findValueId,
  findValueById,
  AllData,
} from "../../utils/practiceDetailFunctions";
import { useStyles } from "./PracticeStyles";

type Props = {
  data: PracticeType;
  allData: AllDataType;
  validated: boolean;
  validateBtn: boolean;
  editable: boolean;
  disableEditing: boolean;
  validatedDraft: boolean;
  handleEdit: () => void;
  changeSelectMulti: (newValue: AllData[], objectKey: string) => void;
  changeSelect: (newValue: AllData | null, objectKey: string) => void;
  changeInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    objectKey: string,
    max?: number
  ) => void;
};

const General: FC<Props> = ({
  data,
  editable,
  handleEdit,
  changeInput,
  changeSelect,
  changeSelectMulti,
  validated,
  validateBtn,
  allData,
  disableEditing,
  validatedDraft,
}) => {
  const [messageField, setMessageField] = useState(messageFields);

  const { practiceDetailList, settingsList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const classes = useStyles();
  const dataObj: PracticeType = { ...data };
  const all = modifyData(allData);
  const hide = dataObj.status === "APPROVED" || dataObj.status === "DENIED";
  const draft = dataObj.status === "DRAFT";

  const handleMessageField = (objectKey: string, newValue: boolean) => {
    setMessageField(handleActiveMessages(messageField, objectKey, newValue));
  };

  const singleMessageField = (objectKey: string) => {
    const message = messageField.find((item) => item.key === objectKey);
    return message?.active;
  };

  const getSelectField = (objectKey: string) => {
    return editable ? (
      <Box className={classes.selectWrapper}>
        <CustomAutocompleteSingle
          valueId={
            dataObj[objectKey] === ""
              ? null
              : {
                  id: findValueId(
                    all[objectKey],
                    dataObj[objectKey] as string
                  ) as number,
                  value: dataObj[objectKey] as string,
                }
          }
          items={
            objectKey === "scopePerUnit" &&
            findValueId(all.scopeAmount, dataObj.scopeAmount) === 3
              ? all.scopePerUnit.filter((it) => it.id !== 1)
              : all[objectKey]
          }
          id={`${objectKey}-select`}
          onChange={(e, newValue) => {
            changeSelect(newValue, objectKey);
          }}
        />
      </Box>
    ) : (
      getText(dataObj[objectKey] as string)
    );
  };

  const getInputField = (
    objectKey: string,
    title?: string,
    type?: "number",
    max?: number,
    multiline?: boolean
  ) => {
    return editable ? (
      <CustomInput
        id={`${objectKey}-input`}
        fullWidth
        type={type ? type : "text"}
        multiline={multiline}
        value={dataObj[objectKey] as string | number}
        onChange={(e) => {
          if (
            type === "number" &&
            max &&
            (+e.target.value < 0 || +e.target.value > 99)
          ) {
            handleMessageField(objectKey, true);
          } else if (type !== "number" && max && e.target.value.length > max) {
            handleMessageField(objectKey, true);
          }
          changeInput(e, objectKey, max);
        }}
      />
    ) : (
      getText(
        (type && dataObj[objectKey] !== 0) || !type
          ? (dataObj[objectKey] as string | number)
          : "",
        title
      )
    );
  };

  const getSubtitle = (
    title: string,
    objectKey: string,
    required: boolean,
    draftMode?: boolean
  ) => {
    return (
      <Typography
        variant="subtitle2"
        className={`
        ${
          !draft && !dataObj[objectKey] && !validated && editable
            ? classes.required
            : ""
        }
        ${
          (draft &&
            !validatedDraft &&
            !dataObj[objectKey] &&
            !validated &&
            editable &&
            draftMode) ||
          (draft &&
            validatedDraft &&
            !dataObj[objectKey] &&
            !validated &&
            editable)
            ? classes.required
            : ""
        }
        `}
      >
        {title}
        {required ? <span>*</span> : ""}
      </Typography>
    );
  };

  const getText = (value: string | number, title?: string) => {
    return (
      <Typography
        className={classes.text}
        variant={title === practiceDetailList.name ? "h6" : "body2"}
      >
        {value}
      </Typography>
    );
  };

  const getTextFromSelect = (value: AllData[] | string) => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return <Typography variant="body2">{value}</Typography>;
    } else {
      return value.map((it) => {
        return (
          <Typography variant="body2" key={it.id}>
            {it.value}
          </Typography>
        );
      });
    }
  };

  return (
    <>
      <Paper style={{ padding: "1.5rem" }}>
        <Header
          title={practiceDetailList.overview}
          editable={editable}
          onClick={handleEdit}
          validated={validateBtn}
          hide={hide || disableEditing}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {getSubtitle(practiceDetailList.title, "title", true, draft)}
            {getInputField("title", practiceDetailList.name)}
          </Grid>
          <Grid item xs={12}>
            {getSubtitle(practiceDetailList.clubName, "club", true)}
            {editable ? (
              <CustomAutocompleteSingle
                valueId={
                  findValueById(
                    all.clubs,
                    dataObj.club as number
                  ) === ""
                    ? null
                    : {
                        id: findValueId(
                          all.clubs,
                          findValueById(
                            all.clubs,
                            Number(dataObj.club)
                          )
                        ) as number,
                        value: findValueById(
                          all.clubs,
                          Number(dataObj.club)
                        ),
                      }
                }
                items={all.clubs}
                id="club-select"
                charCount={3}
                onChange={(e, newValue) =>
                  changeSelect(newValue, "club")
                }
              />
            ) : (
              getText(findValueById(all.clubs, dataObj.club as number))
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography
              variant="subtitle2"
              className={
                (!draft && (!dataObj.objective || !dataObj.objective[0]) && !validated && editable) ||
                (draft &&
                  validatedDraft &&
                  !dataObj.objective[0] &&
                  !validated &&
                  editable)
                  ? classes.required
                  : ""
              }
            >
              {practiceDetailList.objectives}*
            </Typography>
            {editable ? (
              <Box className={classes.selectWrapper}>
                <CustomAutocomplete
                  valueId={dataObj.objective as AllData[]}
                  onChange={(e, newValue) =>
                    changeSelectMulti(newValue, "objective")
                  }
                  items={all.objectives}
                  id="objective-select"
                />
              </Box>
            ) : (
              getTextFromSelect(dataObj.objective as AllData[])
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            {getSubtitle(settingsList.topic, "categoryId", true, draft)}
            {editable ? (
              <Box className={classes.selectWrapper}>
                <CustomAutocompleteSingle
                  valueId={
                    findValueById(
                      all.categories,
                      dataObj.categoryId as number
                    ) === ""
                      ? null
                      : {
                          id: findValueId(
                            all.categories,
                            findValueById(
                              all.categories,
                              Number(dataObj.categoryId)
                            )
                          ) as number,
                          value: findValueById(
                            all.categories,
                            Number(dataObj.categoryId)
                          ),
                        }
                  }
                  items={all.categories}
                  id="category-select"
                  onChange={(e, newValue) =>
                    changeSelect(newValue, "categoryId")
                  }
                />
              </Box>
            ) : (
              getText(findValueById(all.categories, dataObj.categoryId))
            )}
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{ tooltip: classes.message }}
              open={singleMessageField("startingPosition")}
              onClose={() => handleMessageField("startingPosition", false)}
              title={messagesList.maxCharCount + "1500"}
              placement="bottom-start"
            >
              <div>
                {getSubtitle(
                  practiceDetailList.startingPoint,
                  "startingPosition",
                  true
                )}
                {getInputField(
                  "startingPosition",
                  practiceDetailList.startingPoint,
                  undefined,
                  1500,
                  true
                )}
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{ tooltip: classes.message }}
              open={singleMessageField("description")}
              onClose={() => handleMessageField("description", false)}
              title={messagesList.maxCharCount + "1500"}
              placement="bottom-start"
            >
              <div>
                {getSubtitle(
                  practiceDetailList.description,
                  "description",
                  true
                )}
                {getInputField(
                  "description",
                  practiceDetailList.description,
                  undefined,
                  1500,
                  true
                )}
              </div>
            </Tooltip>
          </Grid>
          <Grid item sm={6} xs={12}>
            {getSubtitle(settingsList.personsInvolved, "personsInvolved", true)}
            {getSelectField("personsInvolved")}
          </Grid>
          <Grid item sm={6} xs={12}>
            {getSubtitle(settingsList.duration, "duration", true)}
            {getSelectField("duration")}
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={
                (editable &&
                  !draft &&
                  !validated &&
                  (!dataObj.scopeAmount ||
                    !dataObj.scope ||
                    !dataObj.scopePerUnit)) ||
                (editable &&
                  draft &&
                  validatedDraft &&
                  !validated &&
                  (!dataObj.scopeAmount ||
                    !dataObj.scope ||
                    !dataObj.scopePerUnit))
                  ? classes.required
                  : ""
              }
              variant="subtitle2"
            >
              {settingsList.scope}*
            </Typography>
            <Box
              display="flex"
              alignItems="flex-start"
              flexWrap="wrap"
              flexBasis="100%"
            >
              <Box mr={2} className={editable ? classes.groupItem : ""}>
                <Tooltip
                  classes={{ tooltip: classes.message }}
                  open={singleMessageField("scope")}
                  onClose={() => handleMessageField("scope", false)}
                  title={messagesList.validNumber}
                  placement="bottom-start"
                >
                  <div>
                    {getInputField(
                      "scope",
                      settingsList.scope,
                      "number",
                      2,
                      false
                    )}
                  </div>
                </Tooltip>
              </Box>
              <Box className={editable ? classes.groupItem : ""}>
                {getSelectField("scopeAmount")}
              </Box>
              {!editable &&
              !dataObj.scopeAmount &&
              !dataObj.scope &&
              !dataObj.scopePerUnit ? (
                ""
              ) : (
                <Box mx={1} pt={editable ? 1.5 : 0}>
                  <Typography variant="body2"> / </Typography>
                </Box>
              )}
              <Box className={editable ? classes.groupItem : ""}>
                {getSelectField("scopePerUnit")}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box mb={2} />
      </Paper>
      <Box mb={3} />
    </>
  );
};

export default General;
