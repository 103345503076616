/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Error from "../components/Error/Error";
import ProfileCard from "../components/ProfileCard/ProfileCard";
import OptionMenu from "../components/OptionMenu/OptionMenu";
import CustomAlert from "../components/CustomAlert/CustomAlert";
import DocumentCard from "../components/DocumentCard/DocumentCard";
import StatusCard from "../components/StatusCard/StatusCard";
import ComfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import * as Icon from "../utils/icons";
import General from "../components/PracticeDetail/General";
import Finance from "../components/PracticeDetail/Finance";
import ClubInfo from "../components/PracticeDetail/ClubInfo";
import { initialValues } from "../data/practice-detail/practiceDetail-initialValues";
import { PracticeType } from "../types/practiceType";
import { UserType } from "../types/userType";
import {
  deleteById,
  getRequest,
  post,
  postRequest,
} from "../utils/apiRequests";
import { getAllData, AllDataType, initialSettings } from "../utils/getAllData";
import { userGetById } from "../apiURL/httpUsers";
import {
  bestPracticeDelete,
  bestPracticeGetById,
  bestPracticeSetStatusCreated,
  bestPracticeUpdate,
  bestPracticeUpdateDraft,
  bestPracticeUpdateStatus,
} from "../apiURL/httpBestPractice";
import {
  AllData,
  handleArrayChange,
  handleInputChange,
  handleMultiSelectChange,
  handleSelectChange,
  validation,
  fromRegularToDb,
  fromDbToRegular,
  draftValidation,
} from "../utils/practiceDetailFunctions";
import { makeStyles } from "@material-ui/core/styles";
import ConsentCard from "../components/ConsentCard/ConsentCard";
import { UserContext, UserContextType } from "../context/UserContext";
import { fileGetMain } from "../apiURL/httpFiles";

const useStyles = makeStyles((theme) => ({
  denyBtn: {
    textTransform: "uppercase",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  btn: {
    textTransform: "uppercase",
  },
  label: {
    fontSize: "14px",
  },
  radioLabel: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
}));

const editData: { [key: string]: boolean } = {
  general: false,
  finance: false,
  club: false,
};

let initials = cloneDeep(initialValues);

const valid: { [key: string]: boolean } = {
  editMode: false,
  image: false,
  fields: true,
  consent: false,
};

const uneditableStatuses = ["APPROVED", "DENIED", "PUBLISHED"];

const Detail = () => {
  const [originalData, setOriginalData] = useState<PracticeType>();
  const [dataObj, setDataObj] = useState<PracticeType>();
  const [generalData, setGeneralData] = useState<PracticeType>();
  const [financeData, setFinanceData] = useState<PracticeType>();
  const [clubData, setClubData] = useState<PracticeType>();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [edit, setEdit] = useState(editData);
  const [postError, setPostError] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [userData, setUserData] = useState<UserType>();
  const [all, setAll] = useState<AllDataType>(initialSettings);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [updated, setUpdated] = useState("");
  const [validated, setValidated] = useState(valid);
  const [errorMessage, setErrorMessage] = useState("");
  const [draftValidated, setDraftValidated] = useState(true);
  const [choice, setChoice] = useState<
    "APPROVED" | "DENIED" | "" | "CREATED" | "DELETED"
  >("");
  const [
    optionMenuAnchorEl,
    setOptionMenuAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const isOptionMenuOpen = Boolean(optionMenuAnchorEl);

  const { userCategories, isAdmin, userId } = useContext(
    UserContext
  ) as UserContextType;
  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;
  const { buttonList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const { slug } = useParams<{ slug: string }>();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getAllData(updated ? true : false)
      .then((res) => {
        if (initials.pitchType.length || !res) {
          return;
        }
        setAll(res);
        initials.pitchType = res.pitchType.map((p: { name: string }) => [
          p.name,
          "0",
        ]);
        initials.cabinType = res.cabinType.map((c: { name: string }) => [
          c.name,
          "0",
        ]);
        initials.teamComposition = res.teamComposition.map(
          (t: { name: string }) => [t.name, "0"]
        );
      })
      .catch(() => {})
      .finally(() => {
        getRequest(bestPracticeGetById(+slug))
          .then((res) => {
            let newObj = res;
            if (res.status === "DRAFT" && !res.dateEdited) {
              newObj = fromDbToRegular(
                Object.assign(initials, res),
                all.objectives,
                all.sponsors
              );
            } else {
              newObj = fromDbToRegular(res, all.objectives, all.sponsors);
            }
            setDataObj(newObj);
            setGeneralData(newObj);
            setFinanceData(newObj);
            setClubData(newObj);
            setOriginalData(newObj);
            setSiteTitle(res.title);
            setError(false);
            setLoading(false);
            setErrorMessage("");
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status &&
              err.response.status === 404
            ) {
              setErrorMessage(messagesList.noData);
            }
            setError(true);
            setLoading(false);
            setSiteTitle("DFB Praxisprofi");
          });
      });
  }, [updated]);

  useEffect(() => {
    if (!dataObj) {
      return;
    }
    getRequest(userGetById(dataObj?.submitterId))
      .then((res) => {
        setUserData(res);
        setLoadingUser(false);
      })
      .catch(() => {
        setLoadingUser(false);
      });
  }, [originalData]);

  const draft = dataObj?.status.toLowerCase() === "draft";

  useEffect(() => {
    if (draft) {
      setDataObj(originalData);
      return;
    }
    setGeneralData(originalData);
    setFinanceData(originalData);
    setClubData(originalData);
  }, [originalData]);

  const saveData = (
    original: PracticeType,
    modified: PracticeType,
    section: string,
    hideMessage?: boolean
  ) => {
    if (!all) {
      return;
    }
    const clone = cloneDeep(original);
    const modif = cloneDeep(modified);
    const changedData = Object.assign(clone, modif);
    draft && setDraftValidated(true);

    !draft
      ? settingValidation("fields", typeof validation(clone, all) === "boolean")
      : settingValidation(
          "fields",
          typeof draftValidation(clone) === "boolean"
        );

    const refactored = fromRegularToDb(changedData);
    refactored.submitterId = userId;
    delete refactored.fileDTOS;

    if (
      (draft && typeof draftValidation(clone) === "boolean") ||
      typeof validation(clone, all) === "boolean"
    ) {
      const newValue = { ...edit };
      newValue[section] = false;
      setEdit(newValue);

      postRequest(
        draft ? bestPracticeUpdateDraft : bestPracticeUpdate,
        refactored
      )
        .then(() => {
          setSuccessMessage(hideMessage ? "" : messagesList.successfulSave);
          setDataObj(changedData);
          setOriginalData(changedData);
          settingValidation("fields", true);
        })
        .catch(() => {
          setPostError(true);
          settingValidation("fields", false);
        });
    } else {
      const newValue = { ...edit };
      newValue[section] = true;
      setEdit(newValue);
      draft && setDraftValidated(false);
      setFailedMessage(messagesList.madatoryFields);
    }
  };

  const handleStatusChange = () => {
    if (!dataObj || !choice || !originalData) {
      return;
    }
    const data = {
      bestPracticeId: dataObj.id,
      status: choice,
      submitterId: userId,
    };

    settingValidation("editMode", false);
    settingValidation("image", false);
    settingValidation("consent", false);

    const validValue = validation(dataObj, all);

    if (choice === "CREATED") {
      if (
        typeof validValue === "boolean" &&
        Object.values(edit).every((it) => !it) &&
        dataObj.consent
      ) {
        getRequest(fileGetMain(dataObj.id))
          .then(() => {
            post(bestPracticeSetStatusCreated(dataObj.id, userId))
              .then(() => {
                setConfirmMessage("");
                setUpdated("status-change-created");
                setChoice("");
                setSuccessMessage(messagesList.successfulCreate);
              })
              .catch(() => {
                setPostError(true);
                setConfirmMessage("");
                setChoice("");
              });
          })
          .catch(() => {
            setConfirmMessage("");
            setChoice("");
            settingValidation("image", true);
            setFailedMessage(messagesList.noMainPic);
          });
      } else {
        setConfirmMessage("");
        setChoice("");
        if (typeof validValue === "object") {
          const newValue = { ...edit };
          validValue.forEach((it) => {
            newValue[it] = true;
          });
          setEdit(newValue);
          settingValidation("fields", false);
          setFailedMessage(messagesList.madatoryFields);
        } else if (Object.values(edit).some((it) => it)) {
          settingValidation("editMode", true);
          setFailedMessage(messagesList.saveBeforeSubmit);
        } else if (!dataObj.consent) {
          settingValidation("consent", true);
          setFailedMessage(messagesList.copyright);
        }
      }
    }
    if (choice === "APPROVED" || choice === "DENIED") {
      if (
        (choice === "APPROVED" &&
          Object.values(edit).every((it) => !it) &&
          dataObj.consent) ||
        choice === "DENIED"
      ) {
        getRequest(fileGetMain(dataObj.id))
          .then(() => {
            postRequest(bestPracticeUpdateStatus, data)
              .then(() => {
                setConfirmMessage("");
                setSuccessMessage(
                  choice === "APPROVED"
                    ? messagesList.successfulApprove
                    : messagesList.successfulDenied
                );
                setUpdated("status-change");
                setChoice("");
              })
              .catch(() => {
                setPostError(true);
                setConfirmMessage("");
                setChoice("");
              });
          })
          .catch(() => {
            setConfirmMessage("");
            setChoice("");
            settingValidation("image", true);
            setFailedMessage(messagesList.noMainPic);
          });
      } else {
        setConfirmMessage("");
        setChoice("");
        if (Object.values(edit).some((it) => it)) {
          settingValidation("editMode", true);
          setFailedMessage(messagesList.saveBeforeAction);
        } else if (!dataObj.consent) {
          settingValidation("consent", true);
          setFailedMessage(messagesList.copyright);
        }
      }
    }
    if (choice === "DELETED") {
      deleteById(bestPracticeDelete(dataObj.id, userId))
        .then(() => {
          sessionStorage.setItem("deleteDraft", "true");
          history.push({
            pathname: `/`,
            state: { prevPath: location.pathname },
          });
        })
        .catch(() => {
          setFailedMessage(messagesList.unableToDeleteDraft);
        });
    }
  };

  const handleEdit = (section: "general" | "finance" | "club") => {
    if (!originalData || !dataObj) {
      return;
    }

    settingValidation("editMode", false);
    const newValue = { ...edit };
    newValue[section] = !newValue[section];
    if (draft) {
      edit[section]
        ? saveData(originalData, dataObj, section)
        : setEdit(newValue);
      return;
    }
    if (!generalData || !financeData || !clubData) {
      return;
    }
    if (section === "general") {
      edit[section]
        ? saveData(originalData, generalData, section)
        : setEdit(newValue);
    } else if (section === "finance") {
      edit[section]
        ? saveData(originalData, financeData, section)
        : setEdit(newValue);
    } else if (section === "club") {
      edit[section]
        ? saveData(originalData, clubData, section)
        : setEdit(newValue);
    }
  };

  const handleOptionMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOptionMenuAnchorEl(null);
  };

  const optionMenuId = "option-menu";
  const moreOptionsData = [
    {
      name: buttonList.approve,
      icon: <Icon.DoneIcon fontSize="small" />,
      handleClick: function () {
        setConfirmMessage(messagesList.reallyApprove);
        setChoice("APPROVED");
        handleMenuClose();
      },
    },
    {
      name: buttonList.deny,
      icon: <Icon.Clear fontSize="small" />,
      handleClick: function () {
        setConfirmMessage(messagesList.reallyDeny);
        setChoice("DENIED");
        handleMenuClose();
      },
    },
  ];

  const moreOptionsDataDraft = [
    {
      name: buttonList.create,
      icon: <Icon.DoneIcon fontSize="small" />,
      handleClick: function () {
        setConfirmMessage(messagesList.reallyCreate);
        setChoice("CREATED");
        handleMenuClose();
      },
    },
    {
      name: buttonList.delete,
      icon: <Icon.Clear fontSize="small" />,
      handleClick: function () {
        setConfirmMessage(messagesList.reallyDeleteDraft);
        setChoice("DELETED");
        handleMenuClose();
      },
    },
  ];

  const settingObj = (obj: PracticeType, section: string) => {
    if (draft) {
      setDataObj(obj);
      return;
    }
    section === "general" && setGeneralData(obj);
    section === "finance" && setFinanceData(obj);
    section === "club" && setClubData(obj);
  };

  const settingValidation = (key: string, value: boolean) => {
    const checkedValid = { ...validated };
    checkedValid[key] = value;
    setValidated(checkedValid);
  };

  const handleMultiSelect = (
    newValue: AllData[],
    key: string,
    obj: PracticeType,
    section: string
  ) => {
    const newObj = handleMultiSelectChange(newValue, key, obj);
    settingObj(newObj, section);
  };

  const handleSelect = (
    newValue: AllData,
    key: string,
    obj: PracticeType,
    section: string
  ) => {
    const newObj = handleSelectChange(newValue, key, obj);
    settingObj(newObj, section);
  };

  const handleInput = (
    newValue: string | number,
    key: string,
    obj: PracticeType,
    section: string,
    max?: number
  ) => {
    const newObj = handleInputChange(newValue, key, obj, max, true);
    settingObj(newObj, section);
  };

  const handleArray = (
    newValue: string | number,
    key: string,
    index: number,
    obj: PracticeType
  ) => {
    const newObj = handleArrayChange(newValue, key, index, obj);
    draft ? setDataObj(newObj) : setClubData(newObj);
  };

  const handleCheckboxes = (newValue: boolean) => {
    if (!dataObj) {
      return;
    }
    const clone = cloneDeep(dataObj);
    clone.consent = newValue;
    saveData(dataObj, clone, "", true);
  };

  // Confirmation modal data
  const approveData = {
    buttonText: buttonList.approve,
    buttonIcon: <Icon.DoneIcon fontSize="small" />,
    buttonColor: "secondary",
  };
  const denyData = {
    buttonText: buttonList.deny,
    buttonIcon: <Icon.Clear fontSize="small" />,
    buttonColor: "error",
  };
  const submitData = {
    buttonText: buttonList.create,
    buttonIcon: <Icon.DoneIcon fontSize="small" />,
    buttonColor: "secondary",
  };

  if (
    error ||
    loading ||
    !clubData ||
    !financeData ||
    !generalData ||
    !dataObj ||
    !originalData ||
    !all
  ) {
    return (
      <Error
        loading={loading}
        error={error}
        errorMessage={errorMessage ? errorMessage : undefined}
      />
    );
  }
  
  const disableEditing =
    !userCategories.includes(originalData.categoryId) && !isAdmin;
  const uneditable = uneditableStatuses.includes(dataObj.status);
  const uneditableConsent =
    uneditable ||
    dataObj.status === "SUBMITTED" ||
    dataObj.status === "CREATED";

  if (loadingUser) {
    return <Error loading={loadingUser} error={false} />;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Button
          color="default"
          startIcon={<Icon.ArrowBack />}
          onClick={() => {
            if (location.state) {
              history.goBack();
              initials = cloneDeep(initialValues);
            } else {
              history.push("/overview");
              initials = cloneDeep(initialValues);
            }
          }}
        >
          {buttonList.back}
        </Button>
        <Box>
          <Hidden xsDown>
            {draft && !disableEditing && (
              <>
                <Button
                  color="secondary"
                  startIcon={
                    <Icon.AddCircle style={{ transform: "rotate(45deg)" }} />
                  }
                  variant="contained"
                  style={{ marginRight: "1rem" }}
                  className={classes.denyBtn}
                  onClick={() => {
                    setConfirmMessage(messagesList.reallyDeleteDraft);
                    setChoice("DELETED");
                  }}
                >
                  {buttonList.delete}
                </Button>
                <Button
                  color="secondary"
                  className={classes.btn}
                  startIcon={<Icon.DoneIcon />}
                  variant="contained"
                  onClick={() => {
                    setConfirmMessage(messagesList.reallyCreate);
                    setChoice("CREATED");
                  }}
                >
                  {buttonList.create}
                </Button>
              </>
            )}
            {(dataObj.status === "CREATED" || dataObj.status === "SUBMITTED") &&
              !disableEditing && (
                <>
                  <Button
                    color="secondary"
                    startIcon={
                      <Icon.AddCircle style={{ transform: "rotate(45deg)" }} />
                    }
                    variant="contained"
                    style={{ marginRight: "1rem" }}
                    className={classes.denyBtn}
                    onClick={() => {
                      setConfirmMessage(messagesList.reallyDeny);
                      setChoice("DENIED");
                    }}
                  >
                    {buttonList.deny}
                  </Button>
                  <Button
                    color="secondary"
                    className={classes.btn}
                    startIcon={<Icon.DoneIcon />}
                    variant="contained"
                    onClick={() => {
                      setConfirmMessage(messagesList.reallyApprove);
                      setChoice("APPROVED");
                    }}
                  >
                    {buttonList.approve}
                  </Button>
                </>
              )}
          </Hidden>
          <Hidden smUp>
            <IconButton
              style={{ borderRadius: "3px" }}
              disableRipple
              onClick={(e) => handleOptionMenuOpen(e)}
            >
              <Icon.MoreHoriz />
            </IconButton>
          </Hidden>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item lg={8} md={8} xs={12}>
          <General
            allData={all}
            data={draft ? dataObj : generalData}
            handleEdit={() => handleEdit("general")}
            changeSelectMulti={(newValue, key) => {
              handleMultiSelect(
                newValue as AllData[],
                key,
                draft ? dataObj : generalData,
                "general"
              );
            }}
            changeSelect={(newValue, key) => {
              handleSelect(
                newValue as AllData,
                key,
                draft ? dataObj : generalData,
                "general"
              );
            }}
            changeInput={(e, key, max) =>
              handleInput(
                e.target.value,
                key,
                draft ? dataObj : generalData,
                "general",
                max
              )
            }
            editable={edit.general}
            validated={validated.fields}
            validatedDraft={draftValidated}
            validateBtn={validated.editMode}
            disableEditing={uneditable || disableEditing}
          />
          <Finance
            allData={all}
            data={draft ? dataObj : financeData}
            handleEdit={() => handleEdit("finance")}
            changeSelectMulti={(newValue, key) => {
              handleMultiSelect(
                newValue as AllData[],
                key,
                draft ? dataObj : financeData,
                "finance"
              );
            }}
            changeSelect={(newValue, key) => {
              handleSelect(
                newValue as AllData,
                key,
                draft ? dataObj : financeData,
                "finance"
              );
            }}
            changeInput={(e, key, max) =>
              handleInput(
                e.target.value,
                key,
                draft ? dataObj : financeData,
                "finance",
                max
              )
            }
            editable={edit.finance}
            validated={validated.fields}
            validateBtn={validated.editMode}
            disableEditing={uneditable || disableEditing}
            validatedDraft={draftValidated}
          />
          <ClubInfo
            allData={all}
            data={draft ? dataObj : clubData}
            handleEdit={() => handleEdit("club")}
            changeSelect={(newValue, key) => {
              handleSelect(
                newValue as AllData,
                key,
                draft ? dataObj : clubData,
                "club"
              );
            }}
            changeArray={(e, key, index) =>
              handleArray(
                e.target.value,
                key,
                index,
                draft ? dataObj : clubData
              )
            }
            editable={edit.club}
            validated={validated.fields}
            validateBtn={validated.editMode}
            disableEditing={uneditable || disableEditing}
            validatedDraft={draftValidated}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <StatusCard
            data={dataObj}
            userName={
              userData && userData.name && userData.surname
                ? userData.name + " " + userData.surname
                : userData && userData.name && !userData.surname
                ? userData.name
                : userData && !userData.name && userData.surname
                ? userData.surname
                : ""
            }
          />
          <ProfileCard data={userData} authorId={dataObj.submitterId} />
          <DocumentCard
            bpId={dataObj.id}
            files={dataObj.fileDTOS!}
            validateImg={validated.image}
            disableEditing={disableEditing || uneditable}
          />
          <ConsentCard
            handleCheckbox={(e: any) => handleCheckboxes(e.target.checked)}
            checkboxValue={dataObj.consent}
            disabled={disableEditing || uneditableConsent}
            validateConsent={validated.consent}
            draft={draft}
          />
        </Grid>
      </Grid>

      <Hidden smUp>
        <OptionMenu
          anchorEl={optionMenuAnchorEl}
          menuId={optionMenuId}
          isOpen={isOptionMenuOpen}
          handleClose={handleMenuClose}
          items={draft ? moreOptionsDataDraft : moreOptionsData}
        />
      </Hidden>

      <CustomAlert
        open={failedMessage ? true : false}
        onClose={() => setFailedMessage("")}
        severity="error"
        message={failedMessage}
      />
      <CustomAlert
        open={successMessage ? true : false}
        onClose={() => setSuccessMessage("")}
        severity="success"
        message={successMessage}
      />
      <CustomAlert
        open={postError}
        onClose={() => setPostError(false)}
        severity="error"
        message={messagesList.unableToSave}
      />
      <ComfirmationModal
        open={confirmMessage ? true : false}
        handleClose={() => {
          setConfirmMessage("");
        }}
        handleSecondBtn={handleStatusChange}
        message={confirmMessage}
        disable={choice ? true : false}
        secondButtonData={
          choice === "APPROVED"
            ? approveData
            : choice === "DENIED"
            ? denyData
            : choice === "CREATED"
            ? submitData
            : undefined
        }
      />
    </>
  );
};

export default Detail;
