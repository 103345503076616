/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect } from "react";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import Box from '@material-ui/core/Box';
import { Typography } from "@material-ui/core";

const NotFound: FC = () => {
  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;
  const { messagesList, siteLanguage } = useContext(LanguageContext) as LanguageContextType;

  useEffect(() => {
    setSiteTitle("DFB Praxisprofi");
  }, [siteLanguage]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" style={{height: '80vh'}}>
      <Typography variant="subtitle2">
        {messagesList.noData}
      </Typography>
    </Box>
  );
};

export default NotFound;
