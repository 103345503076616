/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import { UserContext, UserContextType } from "../context/UserContext";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import * as Icon from "../utils/icons";
import defaultImage from "../assets/images/defImg.jpeg";
import CardLong from "../components/CardLong/CradLong";
import Error from "../components/Error/Error";
import UserDetailAuth from "../components/UserDetailAuth/UserDetailAuth";
import UserDetailProfile from "../components/UserDetailProfile/UserDetailProfile";
import { capitalize, findMainImg, truncate } from "../utils/helperFunctions";
import { UserType } from "../types/userType";
import { PracticeType } from "../types/practiceType";
import { getRequest } from "../utils/apiRequests";
import { userGetById } from "../apiURL/httpUsers";
import { bestPracticeGetAllByUserId } from "../apiURL/httpBestPractice";

const UserDetail = () => {
  const [dataObj, setDataObj] = useState<UserType>();
  const [praxisprofi, setPraxisprofi] = useState<PracticeType[]>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorPraxi, setErrorPraxi] = useState(false);
  const [loadingPraxi, setLoadingPraxi] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;
  const { isAdmin } = useContext(UserContext) as UserContextType;
  const { buttonList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getRequest(userGetById(+slug))
      .then((res: UserType) => {
        if (res.userRoles.find((role) => role.name.toLowerCase() === "typo3")) {
          setError(true);
          setErrorMessage(messagesList.noData);
          setLoading(false);
          return;
        }
        setDataObj(res);
        setSiteTitle(
          res && res.name && res.surname
            ? `${res.name} ${res.surname}`
            : res.name && !res.surname
            ? `${res.name}`
            : !res.name && res.surname
            ? `${res.surname}`
            : !res.name && !res.surname
            ? "DFB Praxisprofi"
            : ""
        );
        setError(false);
        setLoading(false);
        setErrorMessage("");
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 400
        ) {
          setErrorMessage(messagesList.noData);
        }
        setSiteTitle("DFB Praxisprofi");
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getRequest(bestPracticeGetAllByUserId(+slug))
      .then((res) => {
        setPraxisprofi(res);
        setErrorPraxi(false);
        setLoadingPraxi(false);
      })
      .catch((err) => {
        setErrorPraxi(true);
        setLoadingPraxi(false);
      });
  }, []);

  if (error || loading || !dataObj) {
    return (
      <Error
        loading={loading}
        error={error}
        errorMessage={errorMessage ? errorMessage : undefined}
      />
    );
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Button
          color="default"
          startIcon={<Icon.ArrowBack />}
          onClick={() => {
            location.state ? history.goBack() : history.push("/practice-users");
          }}
        >
          {buttonList.back}
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item lg={8} md={7} xs={12}>
          <UserDetailProfile
            data={dataObj}
            disableEdit
            hideClub={
              dataObj.userRoles.find(
                (item) =>
                  item.name.toLowerCase() === "admin" ||
                  item.name.toLowerCase() === "editor"
              )
                ? true
                : false
            }
          />
          <Box mb={3} />
          {dataObj.userRoles.find(
            (role) => role.name.toLowerCase() === "web_user"
          ) && !dataObj.active ? (
            ""
          ) : (
            <UserDetailAuth data={dataObj} disableEdit={!isAdmin} />
          )}
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <Paper elevation={1} style={{ padding: "1.5rem" }}>
            <Box mb={1}>
              <Typography variant="subtitle1">PRAXISPROFI</Typography>
            </Box>
            <Divider />
            {!praxisprofi ? (
              <Error
                size="small"
                loading={loadingPraxi}
                error={errorPraxi}
                errorMessage={messagesList.noLoadPraxisprofi}
              />
            ) : praxisprofi && praxisprofi[0] ? (
              praxisprofi.map((item) => {
                return (
                  <CardLong
                    key={item.id + "praxisprofi"}
                    onClick={() =>
                      history.push({
                        pathname: `/practice-detail/${item.id}`,
                        state: { prevPath: location.pathname },
                      })
                    }
                    firstLine={
                      typeof item.club === "object" && item.club.name
                        ? capitalize(item.club.name)
                        : ""
                    }
                    title={capitalize(item.title)}
                    lastLine={truncate(item.description, 45)}
                    imgSrc={findMainImg(item.fileDTOS!)}
                    defaultImgSrc={defaultImage}
                    imgRound={false}
                    imgSmall
                    footer={false}
                  />
                );
              })
            ) : (
              <Box mt={2}>
                <Typography variant="body2">
                  {messagesList.noPraxisprofi}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetail;
