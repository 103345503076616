import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import { useStyles } from "./CustomButtonStyles";

type Props = {
  onClick?: (event?:any) => void;
  size: "small" | "large" | "medium";
  icon?: JSX.Element;
  variant?: "contained" | "outlined";
  disableRound?: boolean; 
};

const CustomButton: FC<Props> = ({ onClick, children, size, icon, variant, disableRound }) => {
  const classes = useStyles();

  return (
    <Button
      className={`${size === "large" ? classes.buttonLarge : ""} 
        ${variant === "contained" ? classes.filled : ''}
        ${classes.root}
        ${disableRound ? classes.noRound : ''}`}
      variant={variant ? variant : "outlined"}
      onClick={onClick}
      startIcon={icon}
      size={size}
    >
      {children}
    </Button>
  );
};

export default CustomButton;