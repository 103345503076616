import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },

  content: {
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },

  imgWrapper: {
    maxWidth: "220px",
  },

  img: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },

  subTitle2: {
    fontSize: "0.95rem",
    marginBottom: theme.spacing(2),
    letterSpacing: "2px",
  },

  inputWrapper: {
    maxWidth: "400px",
  },
}));
