export const consent = {
  de: {
    consent: "Einwilligungen",
    privacyPolicy: "Datenschutzrichtlinien",
    terms: "Nutzungsbestimmungen",
    userHas: "Der Nutzer hat den",
    iAccept: "Hiermit akzeptiere ich die",
    agreed: "zugestimmt",
    privacy: "Datenschutz",
    and: "und",
  },

  en: {
    consent: "Consent",
    privacyPolicy: "Privacy Policy",
    terms: "Terms and conditions",
    userHas: "User has accepted the",
    iAccept: "I accept the",
    agreed: "agreed",
    privacy: "Privacy",
    and: "and",
  }
};