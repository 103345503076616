/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from "react";
import { UserContext, UserContextType } from "../../context/UserContext";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import * as Icon from "../../utils/icons";
import Error from "../Error/Error";
import CustomInput from "../CustomInput/CustomInput";
import CustomAlert from "../CustomAlert/CustomAlert";
import CustomAutocompleteSingle from "../CustomAutocompleteSingle/CustomAutocompleteSingle";
import { getAllNamesId, postRequest } from "../../utils/apiRequests";
import { categoryGetActive } from "../../apiURL/httpCategory";
import { AllData } from "../../utils/practiceDetailFunctions";
import { bestPracticeAddDraft } from "../../apiURL/httpBestPractice";
import { useStyles } from "./AddBestPracticeStyles";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const AddBestPractice: FC<Props> = ({ open, handleClose }) => {
  const [titleValue, setTitleValue] = useState("");
  const [categoryValue, setCategoryValue] = useState<AllData | null>(null);
  const [allCategories, setAllCategories] = useState<AllData[]>();
  const [failedValid, setFailedValid] = useState(false);
  const [postError, setPostError] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { userCategories, isAdmin, userId } = useContext(
    UserContext
  ) as UserContextType;
  const {
    buttonList,
    practiceDetailList,
    settingsList,
    messagesList,
  } = useContext(LanguageContext) as LanguageContextType;

  useEffect(() => {
    getAllNamesId(categoryGetActive)
      .then((res) => {
        if (isAdmin) {
          setAllCategories(res);
        } else {
          const filtered = res.filter((cat: { id: number; value: string }) =>
            userCategories.includes(cat.id)
          );
          setAllCategories(filtered);
        }
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const handleCloseBtn = () => {
    setTitleValue("");
    setCategoryValue(null);
    setFailedValid(false);
    handleClose();
  };

  const handleSaveBtn = () => {
    const newValue = {
      categoryId: categoryValue?.id,
      submitterId: userId,
      title: titleValue,
    };

    if (!titleValue || !categoryValue) {
      setFailedValid(true);
      return;
    }

    postRequest(bestPracticeAddDraft, newValue)
      .then((res) => {
        handleCloseBtn();
        history.push({
          pathname: `/practice-detail/${res.data.id}`,
          state: { prevPath: location.pathname },
        });
      })
      .catch(() => {
        setPostError(true);
      });
  };

  if (error || loading || !allCategories) {
    return <Error loading={loading} error={error} size="small" />;
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography variant="subtitle1" className={classes.title}>
                {practiceDetailList.addBestPractice}
              </Typography>
              <Hidden xsDown>
                <Button
                  size="small"
                  variant="contained"
                  className={classes.closeBtn}
                  startIcon={
                    <Icon.AddCircle style={{ transform: "rotate(45deg)" }} />
                  }
                  onClick={handleCloseBtn}
                  disableElevation
                >
                  {buttonList.cancel}
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  size="small"
                  className={classes.closeBtnSmall}
                  onClick={handleCloseBtn}
                >
                  <Icon.AddCircle
                    style={{ transform: "rotate(45deg)" }}
                    fontSize="small"
                  />
                </IconButton>
              </Hidden>
            </Box>
            <Divider />
            <Box mt={2} />
            <Typography
              variant="subtitle2"
              className={failedValid && !titleValue ? classes.required : ""}
            >
              {practiceDetailList.title}*
            </Typography>
            <CustomInput
              id="title-input"
              fullWidth
              value={titleValue}
              onChange={(e) => setTitleValue(e.target.value)}
            />
            <Box mb={2} />
            <Typography
              variant="subtitle2"
              className={failedValid && !categoryValue ? classes.required : ""}
            >
              {settingsList.topic}*
            </Typography>
            <CustomAutocompleteSingle
              valueId={categoryValue}
              onChange={(e, newValue) => setCategoryValue(newValue)}
              id={categoryValue + "category_input"}
              items={allCategories}
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                className={classes.saveBtn}
                color="secondary"
                startIcon={<Icon.Save />}
                onClick={handleSaveBtn}
                disableElevation
              >
                {buttonList.save}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
      <CustomAlert
        open={postError}
        onClose={() => setPostError(false)}
        severity="error"
        message={messagesList.unableToSave}
      />
    </>
  );
};

export default AddBestPractice;
