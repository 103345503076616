import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    maxWidth: "450px",
    width: "100%",
    margin: theme.spacing(2),
  },

  disableFocus: {
    "&:focus": {
      outline: "none"
    }
  },

  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    opacity: 0.8,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      minWidth: "92px"
    },
    [theme.breakpoints.down(300)]: {
      "& span span": {
        display: "none"
      }
    },
  },

  closeBtn: {
    textTransform: "none",
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.action.disabled,
  },

  subtext: {
    fontSize: "0.8rem",
  },

  message: {
    textAlign: "center",
    fontSize: "18px"
  }

}));