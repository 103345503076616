import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formContol: {
    minWidth: "120px",
    height: "100%",
    border: "none",

    "& .MuiInputBase-root": {
      border: "none",
      borderBottom: "none",
      borderRadius: "4px",
      padding: '1px',
      paddingLeft: "10px",

      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "&:hover": {
        boxShadow: "2px 4px 8px rgba(92,92,92,0.1)",
        transition: ".3s",
      },
      
      "&.Mui-disabled": {
        "&:hover": {
          boxShadow: "none",
        },
      },
      backgroundColor: theme.palette.action.hover,
    },

    "& .MuiSelect-filled": {
      borderRadius: "4px",
    },
  },

  menuList: {
    padding: 0,
    "& li": {
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
    },
  },

  hidden: {
    display: "none"
  },
}));
