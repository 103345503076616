import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  alertSuccess: {
    backgroundColor: theme.palette.secondary.main,
  },

  alertWrapper: {
    top: "80px",
    backgroundColor: "transparent",
  },
}));
