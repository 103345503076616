import { base } from './baseURL';

export const bestPracticeAdd = `${base}/bestPractice/add`;
export const bestPracticeAddDraft = `${base}/bestPractice/addDraft`;
export const bestPracticeGetAll = `${base}/bestPractice/getAll`;
export const bestPracticeGetByStatus = `${base}/bestPractice/getAllByStatus`;
export const bestPracticeGetByCategories = `${base}/bestPractice/getAllByCategories`;
export const bestPracticeGetByStatusesAndCategories = `${base}/bestPractice/getAllByStatusesAndCategories`;
export const bestPracticeGetById = (id: number) => (`${base}/bestPractice/getById/${id}`);
export const bestPracticeGetByCategory = (id: number) => (`${base}/bestPractice/getAllByCategory/${id}`);
export const bestPracticeGetByStatusAndCategory = (status: string, id: number) => (`${base}/bestPractice/getAllByStatusAndCategory/${status}/${id}`);
export const bestPracticeGetAllByUserCategory = (id: number) => `${base}/bestPractice/getAllByUserCategories/${id}`;
export const bestPracticeGetAllByUserId = (id: number) => `${base}/bestPractice/getAllByUserId/${id}`;
export const bestPracticeUpdate = `${base}/bestPractice/update`;
export const bestPracticeUpdateDraft = `${base}/bestPractice/updateDraft`;
export const bestPracticeUpdateStatus = `${base}/bestPractice/updateStatus`;
export const bestPracticeSetStatusCreated = (id: number, userId: number) => `${base}/bestPractice/setStatusCreated?bestPracticeId=${id}&submitterId=${userId}`;
export const bestPracticeGetStatus = `${base}/bestPractice/getStatus`;
export const bestPracticeGetAllByPage = (pageNo: number, pageSize: number) => `${base}/bestPractice/getAllByPage?pageNo=${pageNo}&pageSize=${pageSize}`;
export const bestPracticeDelete = (bpId: number, userId: number) => `${base}/bestPractice/delete?bestPracticeId=${bpId}&submitterId=${userId}`;
