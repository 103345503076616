export const options = {
  de: {
    language: "Sprache",
    german: "Deutsch",
    english: "Englisch",
    darkTheme: "Dark mode",
    lightTheme: "Light mode",
    profile: "Profil",
    signOut: "Ausloggen",
    search: "Tippe um zu suchen",
    select: "Auswählen",
    selection: "Auswahl",
  },
  en: {
    language: "Language",
    german: "German",
    english: "English",
    darkTheme: "Dark mode",
    lightTheme: "Light mode",
    profile: "Profile",
    signOut: "Sign Out",
    search: "Type to search",
    select: "Select",
    selection: "Selection",
  },
};