import axios from "axios";
import { base } from "../apiURL/baseURL";
import { capitalize } from "./helperFunctions";

// Will handle error
export const _getRequest = async (url: string, config: any = null) => {
  const _config: any = config || {};
  _config['withCredentials'] = true;
  try {
    return await axios.get(url, _config)
  } catch (err) {
    const {response}:any = err;
    if (response && (response.status === 401 || response.status === 403 || response.status === 412)) {
      window.location.href = `${base}/oauth2/authorization/dfb`
    }
    throw err;
  }
};

export const getRequest = async (url: string) => {
  const resp = await _getRequest(url);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp.data;
};

export const getRequestWithParams = async (
    url: string,
    key: string,
    values: string[] | number[]
) => {
  const params = new URLSearchParams();
  values.forEach((item: string | number) => {
    params.append(key, item.toString());
  });
  const request = {
    params: params,
  };
  const resp = await _getRequest(url, request);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp.data;
};

export const getRequestWithTwoParams = async (
    url: string,
    key1: string,
    key2: string,
    values1: string[] | number[],
    values2: string[] | number[]
) => {
  const params = new URLSearchParams();
  values1.forEach((item: string | number) => {
    params.append(key1, item.toString());
  });
  values2.forEach((item: string | number) => {
    params.append(key2, item.toString());
  });
  const request = {
    params: params,
  };
  const resp = await _getRequest(url, request);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp.data;
};

export const getById = async (url: string, id: number) => {
  const resp = await _getRequest(url);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp.data.find((item: { id: number }) => item.id === id);
};

export const getAllNames = async (url: string) => {
  const resp = await _getRequest(url);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp.data.map((item: { name: string }) => item.name);
};

export const getAllNamesId = async (url: string) => {
  const resp = await _getRequest(url);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp.data.map((item: { id: number; name: string }) => {
    return {
      id: item.id,
      value: capitalize(item.name),
    };
  });
};

export const postRequest = async (url: string, newValue: Object) => {
  const resp = await axios.post(url, newValue, { withCredentials: true });
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp;
};

export const post = async (url: string) => {
  const resp = await axios.post(url, "", { withCredentials: true });
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp;
};

export const upload = async (
    url: string,
    newValue: FormData,
    mainFile: boolean
) => {
  const resp = await axios.post(url, newValue, {
    params: { isMainFile: mainFile },
    withCredentials: true
  }, );
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp;
};

export const deleteById = async (url: string) => {
  const request = {
    params: null,
    withCredentials: true
  };
  const resp = await axios.delete(url, request);
  sessionStorage.setItem("lastRequest", JSON.stringify(new Date()))
  return resp;
};
