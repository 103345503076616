const getBase = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:8080/api";
  } else if (window.location.href.includes("intg-praxisprofi")) {
    return "https://intg-praxisprofi.fussball.de/api";
  } else if (window.location.href.includes("refz-praxisprofi")) {
    return "https://refz-praxisprofi.fussball.de/api";
  } else {
    return "https://praxisprofi.fussball.de/api"
  }
}

export const base = getBase();
