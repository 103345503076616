import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
//
import { UserContext, UserContextType } from "./context/UserContext";
import { TitleProvider } from "./context/TitleContext";
import { ThemeContext, ThemeContextType } from "./context/ThemeContext";
//
import { ThemeProvider } from "@material-ui/core/styles";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
//
import { dark, light } from "./theme";
import Menu from "./components/Menu/Menu";
import PracticeSettings from "./pages/PracticeSettings";
import Overview from "./pages/Overview";
import PracticeDetail from "./pages/PracticeDetail";
import UserDetail from "./pages/UserDetail";
import PracticeUsers from "./pages/PracticeUsers";
import SystemAccess from "./pages/SystemAccess";
import NotFound from "./pages/NotFound";
import SessionModal from "./components/SessionModal/SessionModal";
// import Lottery from "./pages/Lottery";
// import LotterySettings from "./pages/LotterySettings";
// import LotteryUsers from "./pages/LotteryUsers";
// import LotteryTemplates from "./pages/LotteryTemplates";

function App() {
  const { siteTheme } = useContext(ThemeContext) as ThemeContextType;
  const { isAdmin } = useContext(UserContext) as UserContextType;

  const appliedTheme = createMuiTheme(siteTheme ? light : dark);

  return (
    <ThemeProvider theme={appliedTheme}>
      <Router>
        <TitleProvider>
          <Menu
            pages={
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/overview" />} />
                <Route path="/overview" exact component={Overview} />
                <Route path="/practice-detail/:slug" exact component={PracticeDetail} />
                <Route path="/user-detail/:slug" exact component={UserDetail} />
                {isAdmin && (
                  <Route path="/practice-settings" exact component={PracticeSettings} />
                )}
                <Route path="/practice-users" exact component={PracticeUsers} />
                <Route path="/system-access" exact component={SystemAccess} />
                 {/* <Route path="/lottery" exact component={Lottery} /> */}
                {/*  <Route path="/lottery-settings" exact component={LotterySettings} />
                  <Route path="/lottery-users" exact component={LotteryUsers} />
                  <Route path="/lottery-templates" exact component={LotteryTemplates} /> */}
                <Route component={NotFound} />
              </Switch>
            }
          />
          <SessionModal />
        </TitleProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
