import React, { useContext, FC } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "./ConsentCardStyles";


type Props = {
  checkboxValue: boolean;
  handleCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  validateConsent: boolean;
  draft?: boolean;
};

const ConsentCard: FC<Props> = ({
  handleCheckbox,
  checkboxValue,
  disabled,
  validateConsent,
  draft,
}) => {
  const { consentList, siteLanguage } = useContext(
    LanguageContext
  ) as LanguageContextType;
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.root}>
      <Typography
        variant="body1"
        className={validateConsent && !checkboxValue ? classes.required : ""}
      >
        {consentList.privacy}
      </Typography>
      <Box mb={2} />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              id="consent-checkbox"
              checked={checkboxValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleCheckbox(e)
              }
              color="secondary"
            />
          }
          label={
            <Box
              display="flex"
              alignItems="center"
              className={classes.checkbox}
            >
              <Typography variant="body2">
                {`${draft ? consentList.iAccept : consentList.userHas} `}
                <b>
                  {consentList.privacyPolicy}
                </b>
                {` ${consentList.and} `}
                <b>{consentList.terms}</b>
                {siteLanguage && !draft && ` ${consentList.agreed}`}
              </Typography>
            </Box>
          }
        />
      </div>
    </Paper>
  );
};

export default ConsentCard;
