import React, { FC, useContext } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { AllData } from "../../utils/practiceDetailFunctions";
import * as Icon from "../../utils/icons";
import { useStyles } from "./CustomAutocompleteStyles";

const icon = <Icon.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <Icon.CheckBoxIcon fontSize="small" />;

type Props = {
  valueId: AllData[];
  onChange: (
    event: React.ChangeEvent<{}>,
    newValue: AllData[]
  ) => void;
  items: AllData[];
  id: string;
  disabled?: boolean;
  placeholder?: string;
};

const CustomAutocomplete: FC<Props> = ({
  valueId,
  onChange,
  items,
  id,
  disabled,
  placeholder
}) => {
  const classes = useStyles();
  const {messagesList} = useContext(LanguageContext) as LanguageContextType;

  return (
    <Autocomplete
      multiple
      noOptionsText={messagesList.noOptions}
      id={id}
      value={valueId}
      options={items}
      disabled={disabled}
      disableCloseOnSelect
      getOptionLabel={(option) => option.value}
      getOptionSelected={(option, value) => option.id === value.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option.value} {...getTagProps({ index })} />
        ))
      }
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </>
      )}
      onChange={onChange}
      classes={{
        paper: classes.menuList,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          className={`${classes.formContol} ${classes.chip}`}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
