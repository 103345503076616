import React, { FC } from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import * as Icon from "../../utils/icons";
import { useStyles } from "./CardMainStyles";
import CustomButton from "../CustomButton/CustomButton";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
  onClick: () => void;
  status?: string;
  date?: string;
  title?: string;
  imgSrc: string;
  defaultImgSrc: string;
  header: boolean;
  imgText?: string;
  optionMenuId?: string;
  loadImg?: boolean;
  handleOptionMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  footer?: boolean;
  buttonData?: {
    label: string;
    icon?: JSX.Element;
    handleClick: () => void;
  }[];
};

const CardMain: FC<Props> = ({
  onClick,
  status,
  date,
  title,
  imgSrc,
  defaultImgSrc,
  children,
  header,
  imgText,
  buttonData,
  optionMenuId,
  handleOptionMenuOpen,
  loadImg,
  footer,
}) => {
  const classes = useStyles();

  const addDefaultSrc = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = defaultImgSrc;
  };

  return (
    <Card
      className={`${classes.cardRoot} ${
        footer === false ? classes.cardRootXs : ""
      }`}
    >
      <CardActionArea onClick={onClick} className={classes.actionArea}>
        {header && (
          <CardContent className={classes.content}>
            <div className={classes.cardHead}>
              <Typography variant="body2" color="textSecondary" component="p">
                Status: {status}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {date}
              </Typography>
            </div>
            <Typography
              className={classes.cardTitle}
              gutterBottom
              variant="h6"
              component="h2"
            >
              {title}
            </Typography>
          </CardContent>
        )}
        {imgSrc || !loadImg ? (
          imgText ? (
            <div className={classes.imgWrapper}>
              <img
                className={classes.cardImage}
                src={imgSrc}
                onError={addDefaultSrc}
                alt={title}
              />
              <span className={classes.imgText}>{imgText}</span>
              <div className={classes.imgOverlay} />
            </div>
          ) : (
            <img
              className={classes.cardImage}
              src={imgSrc}
              onError={addDefaultSrc}
              alt={title}
            />
          )
        ) : (
          <div className={classes.loader}>
            <CircularProgress color="secondary" size={40} />
          </div>
        )}
        <CardContent className={classes.content}>{children}</CardContent>
      </CardActionArea>

      {footer === false ? (
        ""
      ) : (
        <div className={classes.cardFooter}>
          <Divider />
          <CardContent className={classes.content}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={classes.buttons}>
                {buttonData &&
                  buttonData.map((item) => {
                    return (
                      <CustomButton
                        key={item.label}
                        size="small"
                        onClick={item.handleClick}
                        icon={item.icon}
                      >
                        {item.label}
                      </CustomButton>
                    );
                  })}
              </div>
              <IconButton
                aria-controls={optionMenuId}
                aria-haspopup="true"
                onClick={handleOptionMenuOpen}
              >
                <Icon.MoreHoriz />
              </IconButton>
            </Box>
          </CardContent>
        </div>
      )}
    </Card>
  );
};

export default CardMain;
