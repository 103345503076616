import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    maxWidth: "500px",
    width: "100%",
    margin: theme.spacing(2),
  },

  title: {
    marginRight: theme.spacing(3),
  },

  closeBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    opacity: 0.8,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

  closeBtnSmall: {
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },

  saveBtn: {
    textTransform: "none",
  },

  stepIcon: {
    backgroundColor: theme.palette.action.hover,
    borderRadius: "4px",
    padding: "8px 12px",
    fontWeight: 700,
   
    "&:hover": {
      boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
      borderRadius: "4px",
      transition: ".2s",
    },
  },

  stepIconWrapper: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: theme.palette.text.primary,
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      borderRadius: "4px",
    },
  },

  stepperRoot: {
    padding: 0,
    borderRadius: "4px",
    "& div button .MuiStepButton-touchRipple": {
      display: "none",
    },
  },

  button: {
    textTransform:"none",
  },

  errorMessage: {
    color: theme.palette.error.main,
    fontSize: "0.7rem",
    padding: theme.spacing(0.4),
  },
}));
