import React, { FC } from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import * as Icon from "../../utils/icons";
import { useStyles } from "./CardLongStyles";
import CustomButton from "../CustomButton/CustomButton";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
  onClick: () => void;
  lastLine: string;
  firstLine: string;
  title: string;
  imgSrc: string;
  defaultImgSrc: string;
  handleOptionMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  optionMenuId?: string;
  imgRound: boolean;
  footer?: boolean;
  loadImg?: boolean;
  order?: "variant-1" | "variant-2";
  buttonData?: {
    label: string;
    icon?: JSX.Element;
    handleClick: () => void;
  }[];
  imgSmall?: boolean;
};

const CardMain: FC<Props> = ({
  onClick,
  lastLine,
  firstLine,
  title,
  imgSrc,
  defaultImgSrc,
  handleOptionMenuOpen,
  optionMenuId,
  imgRound,
  buttonData,
  footer,
  order,
  imgSmall,
  loadImg,
}) => {
  const classes = useStyles();

  const addDefaultSrc = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = defaultImgSrc;
  };

  return (
    <Card
      className={`${classes.cardLongRoot} ${
        imgRound ? classes.cardRound : ""
      } ${imgSmall ? classes.cardLongRoot_imgSmall : ""}`}
    >
      <CardActionArea
        onClick={onClick}
        className={`${classes.actionArea} ${
          footer === false ? classes.actionArea100 : ""
        }`}
      >
        {imgSrc || !loadImg ? (
          <img
            src={imgSrc}
            onError={addDefaultSrc}
            alt={title}
            className={`${classes.cardLongImage} ${
              imgRound ? classes.imgRound : ""
            } ${imgSmall ? classes.imgSmall : ""}`}
          />
        ) : (
          <div className={classes.loader}>
            <CircularProgress color="secondary" size={30} />
          </div>
        )}
        <CardContent
          className={`${
            order === "variant-2"
              ? classes.cardLongContentReOrder
              : classes.cardLongContent
          } ${imgSmall ? classes.cardLongContent_imgSmall : ""}`}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={order === "variant-2" ? classes.firstLine : ""}
          >
            {firstLine}
          </Typography>
          <Typography
            className={`${classes.cardTitle} ${
              order === "variant-2" ? classes.titleLine : ""
            }`}
            gutterBottom
            variant="h5"
            component="h2"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={order === "variant-2" ? classes.lastLine : ""}
          >
            {lastLine}
          </Typography>
        </CardContent>
      </CardActionArea>
      {buttonData && (
        <Hidden smDown>
          <Box display="flex" mr={8}>
            {buttonData.map((item) => {
              return (
                <CustomButton
                  key={item.label}
                  icon={item.icon}
                  size="small"
                  onClick={item.handleClick}
                >
                  {item.label}
                </CustomButton>
              );
            })}
          </Box>
        </Hidden>
      )}
      {footer === false ? (
        ""
      ) : (
        <IconButton
          className={classes.cardMore}
          aria-controls={optionMenuId}
          aria-haspopup="true"
          onClick={handleOptionMenuOpen}
        >
          <Icon.MoreHoriz />
        </IconButton>
      )}
    </Card>
  );
};

export default CardMain;
