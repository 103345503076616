/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import { UserContext, UserContextType } from "../../context/UserContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import * as Icon from "../../utils/icons";
import CustomAlert from "../CustomAlert/CustomAlert";
import UserAuthModal from "../UserAuthModal/UserAuthModal";
import {
  usersAssignCategory,
  usersAssignRole,
  usersGetAllRoles,
} from "../../apiURL/httpUsers";
import { categoryGetActive } from "../../apiURL/httpCategory";
import { Role, Category, UserType } from "../../types/userType";
import {
  getAllNamesId,
  getRequest,
  postRequest,
} from "../../utils/apiRequests";
import { AllData, findValueById, findValueId } from "../../utils/practiceDetailFunctions";
import { useStyles } from "./UserDetailAuthStyles";

interface Initial {
  application: AllData | null;
  role: AllData | null;
  categories: AllData[];
}

interface InitialExtends extends Initial {
  [key: string]: AllData | AllData[] | null;
}

type Current = {
  applications: AllData[];
  roles: Role[];
  categories: Category[];
};

const current = {
  applications: [],
  roles: [],
  categories: [],
};

const initialValueData: Initial = {
  application: null,
  role: null,
  categories: [],
};

type Props = {
  data: UserType;
  disableEdit: boolean;
};

const UserDetailAuth: FC<Props> = ({ data, disableEdit }) => {
  const [allCategories, setAllCategories] = useState<AllData[]>([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [currentValues, setCurrentValues] = useState<Current>(current); // filled values
  const [initialValues, setInitialValues] = useState(initialValueData); // starting values - empty
  const [postError, setPostError] = useState("");
  const [allRoles, setAllRoles] = useState<AllData[]>([]);

  const { userId } = useContext(UserContext) as UserContextType;
  const { buttonList, userDetailList, messagesList, roleList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  const classes = useStyles();
  const dataObj = data;

  const allApps = [
    { id: 1, value: userDetailList.praxisprofi },
    // { id: 2, value: userDetailList.lotteries },
  ];

  useEffect(() => {
    const added = sessionStorage.getItem("added");
    if (added) {
      setOpenAddModal(true);
      sessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (dataObj && dataObj.categoryDTOSet && dataObj.userRoles) {
      const initial = { ...currentValues };
      initial.categories = dataObj.categoryDTOSet;
      initial.roles = dataObj.userRoles;
      initial.applications = allApps;
      setCurrentValues(initial);
    }
  }, []);

  useEffect(() => {
    Promise.all([
      getAllNamesId(categoryGetActive),
      getRequest(usersGetAllRoles),
    ])
      .then(([categories, roles]) => {
        setAllCategories(categories);
        const filtered = roles
          .filter(
            (role: Role) =>
              role.name.toLowerCase() === "admin" ||
              role.name.toLowerCase() === "editor"
          )
          .map((it: Role) => ({
            id: it.roleId,
            value: it.name,
          }));
        setAllRoles(filtered);
      })
      .catch(() => {});
  }, []);

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setEditable(false);
  };

  const handleEdit = (appId: number, roleId: number) => {
    setEditable(true);
    setOpenAddModal(true);

    const currApp = allApps.find((it) => it.id === appId);
    if (!currApp || !currentValues.roles[0]) {
      return;
    }
    const currRole = {
      id: roleId,
      value: roleList[findValueById(allRoles, roleId).toLowerCase()],
    };

    const currCat = currentValues.categories.map((it) => ({
      id: it.id,
      value: it.name,
    }));

    const newObj: Initial = JSON.parse(JSON.stringify(initialValues));
    newObj.application = currApp;
    newObj.role =
      currApp.id === 1 ? currRole : { id: 1, value: userDetailList.auditor };
    newObj.categories = currCat;
    setInitialValues(newObj);
  };

  const handleMultiSelect = (value: AllData[]) => {
    const newObj: Initial = JSON.parse(JSON.stringify(initialValues));
    newObj.categories = value;
    setInitialValues(newObj);
  };

  const handleSelect = (key: string, value: AllData | null) => {
    const newObj: InitialExtends = JSON.parse(JSON.stringify(initialValues));
    newObj[key] = value;
    setInitialValues(newObj);
  };

  const handleDelete = () => {
    if (!initialValues.application || !initialValues.role) {
      return;
    }
    const app = initialValues.application.id;
    if (app === 2) {
      return;
    }
    const id = initialValues.role.id;
    const filteredRoles = currentValues.roles.filter(
      (role) => role.roleId !== id
    );
    const dbRoles = {
      submitterId: userId,
      userId: data.id,
      userRoles: filteredRoles,
    };
    const dbCat = {
      userId: data.id,
      categoryIds: [],
    };
    const newObj: Current = JSON.parse(JSON.stringify(currentValues));
    postRequest(usersAssignRole, dbRoles)
      .then(() => {
        newObj.roles = filteredRoles;
        setCurrentValues(newObj);
      })
      .catch(() => setPostError(messagesList.unableToDeleteRole));

    initialValues.role.value.toLowerCase() === roleList.editor.toLowerCase() &&
      postRequest(usersAssignCategory, dbCat);
  };

  const handleSave = () => {
    if (!initialValues.role || !initialValues.application) {
      return;
    }
    const newRoleValues = [
      ...currentValues.roles,
      {
        name: findValueById(allRoles, initialValues.role.id),
        roleId: initialValues.role.id,
      },
    ];

    const roleObj = {
      submitterId: userId,
      userId: data.id,
      userRoles: newRoleValues,
    };

    const catIds = initialValues.categories.map((it) => it.id);
    const catObj = {
      categoryIds: catIds,
      userId: data.id,
    };

    const categories = initialValues.categories.map((it) => ({
      id: it.id,
      name: it.value,
      active: true,
    }));

    const newObj: Current = JSON.parse(JSON.stringify(currentValues));

    editable
      ? postRequest(usersAssignCategory, catObj)
          .then((res) => {
            newObj.categories = categories;
            setCurrentValues(newObj);
            setInitialValues(initialValueData);
          })
          .catch(() => {
            setPostError(messagesList.unableToSave);
          })
      : postRequest(usersAssignRole, roleObj)
          .then(() => {
            if (initialValues.role!.value.toLowerCase() !== roleList.editor.toLowerCase()) {
              newObj.roles = roleObj.userRoles;
              setCurrentValues(newObj);
              setInitialValues(initialValueData);
              return;
            }
            postRequest(usersAssignCategory, catObj)
              .then(() => {
                newObj.roles = roleObj.userRoles;
                newObj.categories = categories;
                setCurrentValues(newObj);
                setInitialValues(initialValueData);
              })
              .catch(() => {
                setPostError(messagesList.unableToSave);
              });
          })
          .catch(() => {
            setPostError(messagesList.unableToSave);
          });
  };

  if (!allCategories[0] || !currentValues) {
    return (
      <Box className={classes.loader}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  // admin (roleId - 1) first
  currentValues.roles?.sort(function (a, b) {
    return a.roleId - b.roleId;
  });

  return (
    <Paper elevation={1} style={{ padding: "1.5rem" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mb={1}
      >
        <Typography variant="subtitle1">
          {userDetailList.authorisation}
        </Typography>
        {!disableEdit && (
          <>
            <Hidden xsDown>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                disableElevation
                className={classes.addBtn}
                startIcon={<Icon.Add />}
                onClick={() => setOpenAddModal(true)}
              >
                {buttonList.addRole}
              </Button>
            </Hidden>
            <Hidden smUp>
              <IconButton
                color="secondary"
                size="small"
                className={classes.addBtnSmall}
                onClick={() => setOpenAddModal(true)}
              >
                <Icon.Add />
              </IconButton>
            </Hidden>
          </>
        )}
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        flexBasis="100%"
        mt={2}
        className={classes.content}
      >
        <Typography variant="body1">Praxisprofi</Typography>
        <Box mt={0.5} mb={1}>
          <Divider />
        </Box>
        <Box
          ml={4}
          display="flex"
          flexWrap="wrap"
          flexBasis="100%"
          flexDirection="column"
          className={classes.sectionWrapper}
        >
          {currentValues.roles.map((it) => {
            if (it.name.toLowerCase() === "editor") {
              return (
                <Box
                  key={it.roleId}
                  display="flex"
                  flexBasis="100%"
                  alignItems="flex-start"
                  className={classes.roleWrapper}
                  mb={1}
                >
                  <Box flexGrow={1} mr={6} pt={1}>
                    <Typography variant="body2">
                      {roleList[it.name.toLowerCase()]}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexGrow={2}
                    alignItems="flex-start"
                    className={classes.roleWrapperInner}
                  >
                    <Box display="flex" flexWrap="wrap" flexGrow={1} pt={1}>
                      {currentValues.categories.map((item) => {
                        return (
                          <Box
                            key={item.id}
                            display="flex"
                            className={classes.boxBg}
                          >
                            <Typography variant="body2">{item.name}</Typography>
                          </Box>
                        );
                      })}
                    </Box>
                    {!disableEdit && (
                      <Box flexGrow={0} ml={2} mb={1} justifyContent="flex-end">
                        {currentValues.roles.length > 0 && (
                          <IconButton
                            className={classes.editBtn}
                            onClick={() => handleEdit(1, +findValueId(allRoles, "EDITOR"))}
                          >
                            <Icon.Edit fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            } else {
              return (
                <Box
                  key={it.roleId}
                  display="flex"
                  flexBasis="100%"
                  alignItems="flex-start"
                  mb={1}
                >
                  <Box flexGrow={0} mr={4} mb={1} pt={1}>
                    <Typography variant="body2">
                      {roleList[it.name.toLowerCase()]}
                    </Typography>
                  </Box>
                  <Box flexGrow={2} />
                  {!disableEdit && (
                    <Box flexGrow={0} ml={2} mb={1} justifyContent="flex-end">
                      {currentValues.roles.length > 0 &&
                        (it.name.toLowerCase() === "admin") && (
                          <IconButton
                            className={classes.editBtn}
                            onClick={() => handleEdit(1, +findValueId(allRoles, "ADMIN"))}
                          >
                            <Icon.Edit fontSize="small" />
                          </IconButton>
                        )}
                    </Box>
                  )}
                </Box>
              );
            }
          })}
        </Box>
      </Box>
      <UserAuthModal
        open={openAddModal}
        handleClose={handleCloseModal}
        allCategories={allCategories}
        allRoles={allRoles}
        currentValues={currentValues}
        initialValues={initialValues}
        functionality={editable ? "edit" : "add"}
        handleSave={handleSave}
        handleDelete={handleDelete}
        handleCloseBtn={() => setInitialValues(initialValueData)}
        handleValueChange={(key: string, value: AllData | null) =>
          handleSelect(key, value)
        }
        handleMultiValueChange={(value: AllData[]) => handleMultiSelect(value)}
      />
      <CustomAlert
        open={postError ? true : false}
        onClose={() => setPostError("")}
        severity="error"
        message={postError}
      />
    </Paper>
  );
};

export default UserDetailAuth;
