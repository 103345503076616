import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  loader: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loaderSmall: {
    height: "100%",
  },

  messageSmall: {
    height: "100%",
    marginTop: theme.spacing(2),
  },

  loaderMedium: {
    height: "60vh",
  },

  loaderXS: {
    height: '60%'
    
  }
}));
