import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiPaper-root": {
      top: "57px !important",
      borderRadius: "3px",
    },
  },
  menuIcon: {
      minWidth: "40px"
  },
}));
