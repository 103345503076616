export const pageTitles = {
  de: {
    dashboard: "Dashboard",
    praxisprofiCases: "Best Practices Übersicht",
    praxisprofiUsers: "Praxisprofi Nutzer",
    praxisprofiSettings: "Praxisprofi Einstellungen",
    praxisprofiTemplates: "Praxisprofi Vorlagen",
    lotteryCases: "Gewinnspiele Übersicht",
    lotteryUsers: "Gewinnspiele Nutzer",
    lotterySettings: "Gewinnspiele Einstellungen",
    lotteryTemplates: "Gewinnspiele Vorlagen",
    systemGeneral: "Allgemeine Systemeinstellungen",
    systemAccess: "Systemzugriff",
  },

  en: {
    dashboard: "Dashboard",
    praxisprofiCases: "Best Practices Overview",
    praxisprofiUsers: "Praxisprofi Users",
    praxisprofiSettings: "Praxisprofi Settings",
    praxisprofiTemplates: "Praxisprofi Templates",
    lotteryCases: "Lottery Cases",
    lotteryUsers: "Lottery Users",
    lotterySettings: "Lottery Settings",
    lotteryTemplates: "Lottery Templates",
    systemGeneral: "General System Settings",
    systemAccess: "System access",
  },
};