export const buttons = {
  de: {
    back: "Zurück",
    edit: "Bearbeiten",
    save: "Speichern",
    preview: "Vorschau",
    publish: "Veröffentlichen",
    new: "Neu",
    loadMore: "Mehr laden",
    showProfile: "Profil anzeigen",
    upload: "Hochladen",
    cancel: "Abbrechen",
    addRole: "Rolle hinzufügen",
    next: "Weiter",
    deleteRole: "Rolle löschen",
    delete: "Löschen",
    submit: "Einreichen",
    approve: "Genehmigen",
    deny: "Ablehnen",
    agree: "Zustimmen",
    continueSession: "Sitzung fortsetzen",
    refresh: "Seite neu laden",
    create: "Erstellen"
  },
  
  en: {
    back: "Back",
    edit: "Edit",
    save: "Save",
    preview: "Preview",
    publish: "Publish",
    show: "Show",
    new: "New",
    loadMore: "Load more",
    showProfile: "Show profile",
    upload: "Upload",
    cancel: "Cancel",
    addRole: "Add role",
    next: "Next",
    deleteRole: "Delete role",
    delete: "Delete",
    submit: "Submit",
    approve: "Approve",
    deny: "Deny",
    agree: "Agree",
    continueSession: "Continue session",
    refresh: "Reload page",
    create: "Create"
  },
};
