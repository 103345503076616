export const templates = {
  de: {
    template: "Vorlage",
    adjustText: "Passe den Text der gesendeten Mail an",
    submitted: "eingereicht",
    approved: "genehmigt",
    rejected: "abgelehnt",
    webuser: "Webuser",
    topicExperts: "Themenfachleute",
  },
  en: {
    template: "Template",
    adjustText: "Adjust the text of the sent Email",
    submitted: "submitted",
    approved: "approved",
    rejected: "rejected",
    webuser: "Webuser",
    topicExperts: "Topic experts",
  },
};  