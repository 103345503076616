import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },

  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },

  subTitle: {
    marginTop: theme.spacing(1),
  },

  textBreak: {
    wordWrap: "break-word",
  },

  wrapper: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down(750)]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  content: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },

  profileBtn: {
    flex: "auto",
    "& button": {
      minWidth: "200px",
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        minWidth: "auto",
      },
    },
    [theme.breakpoints.down(750)]: {
      display: "none",
    },
  },

  profileBtnDown: {
    display: "none",
    [theme.breakpoints.down(750)]: {
      display: "flex",
      "& button": {
        minWidth: "200px",
        textTransform: "uppercase",
      },
    },
    [theme.breakpoints.down(350)]: {
      "& button": {
        minWidth: "auto",
      },
    },
  },
}));
