/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../../context/LanguageContext";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import * as Icon from "../../../utils/icons";
import CustomAccardion from "../../CustomAccardion/CustomAccardion";
import CustomInput from "../../CustomInput/CustomInput";
import OptionMenu from "../../OptionMenu/OptionMenu";
import {
  addFunction,
  handleInputValueChange2,
  handleCheckboxValueChange2,
  editFunction,
  deleteFunction,
} from "../../../utils/settingFunctions";
import { postRequest } from "../../../utils/apiRequests";
import { ownershipAdd, ownershipUpdate } from "../../../apiURL/httpOwnership";
import { pitchTypeAdd, pitchTypeUpdate } from "../../../apiURL/httpPitchType";
import { cabinTypeAdd, cabinTypeUpdate } from "../../../apiURL/httpCabinType";
import {
  scopePerUnitAdd,
  scopePerUnitUpdate,
} from "../../../apiURL/httpScopePerUnit";
import {
  scopeAmountAdd,
  scopeAmountUpdate,
} from "../../../apiURL/httpScopeAmount";
import { useStyles } from "./SettingsWithSectionsStyles";
import CustomAlert from "../../CustomAlert/CustomAlert";

type ButtonData = {
  btnLabel?: string;
  btnHandleClick?: (event: React.MouseEvent<HTMLElement>, exp: boolean) => void;
  btnIcon?: JSX.Element;
  btnStyle?: "contained" | "outlined";
  btnColor?: "default" | "secondary" | "primary";
};

type SettingsData = {
  id: number | string;
  name: string;
  active: boolean;
  options?: { id: number; value: string }[];
  activeOption?: number | string;
};

type Props = {
  settingData: { [key: string]: SettingsData[] };
  title: string;
  titleNames: string[];
};

const updateScope = [scopeAmountUpdate, scopePerUnitUpdate];
const addScope = [scopeAmountAdd, scopePerUnitAdd];
const addInfrastructure = [ownershipAdd, pitchTypeAdd, cabinTypeAdd];
const updateInfrastructure = [
  ownershipUpdate,
  pitchTypeUpdate,
  cabinTypeUpdate,
];

const SettingsWithSections: FC<Props> = ({
  settingData,
  title,
  titleNames,
}) => {
  const [data, setData] = useState(settingData);
  const [mockData, setMockData] = useState(settingData);
  const [isEditable, setIsEditable] = useState(false);
  const [newValue, setNewValue] = useState<{ [key: string]: string }>();
  const [newChValue, setNewChValue] = useState<{ [key: string]: boolean }>();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [newInputs, setNewInputs] = useState<{
    [key: string]: SettingsData[];
  }>();

  const [
    optionMenuAnchorEl,
    setOptionMenuAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const isOptionMenuOpen = Boolean(optionMenuAnchorEl);

  const classes = useStyles();
  const { buttonList, settingsList, messagesList } = useContext(
    LanguageContext
  ) as LanguageContextType;

  let names = Object.keys(mockData);
  const sponsors =
    title === settingsList.sponsors + " / " + settingsList.funding;

  const initializingValues = () => {
    const newValues: { [key: string]: string } = {};
    const newChValues: { [key: string]: boolean } = {};
    const emptyObjWithKeys: { [key: string]: SettingsData[] } = {};
    names.forEach((item) => {
      newValues[item] = "";
      newChValues[item] = false;
      emptyObjWithKeys[item] = [];
    });
    setNewValue(newValues);
    setNewChValue(newChValues);
    setNewInputs(emptyObjWithKeys);
  };

  useEffect(() => {
    initializingValues();
  }, []);

  const checkError = (arr: number[]) => {
    if (arr.length === 4) {
      if (arr.includes(2)) {
        setSuccess(false);
        setErrorMessage(messagesList.unableToSave);
      } else {
        setSuccess(true);
        setErrorMessage(messagesList.successfulSave);
      }
    }
  };

  const handleEdit = (event: any, exp?: boolean) => {
    (exp === true || exp === undefined || isEditable) &&
      event.stopPropagation();
    setIsEditable(!isEditable);
    if (isEditable && newInputs) {
      const changed = editFunction(data, mockData);
      const checkErr: number[] = [];

      const makeSuccess = (name?: string) => {
        checkErr.push(1);
        checkError(checkErr);
        name && (changed[name] = [...changed[name], ...newInputs[name]]);
        setData(changed);
        setMockData(changed);
        initializingValues();
      };

      const makeFailed = () => {
        checkErr.push(2);
        checkError(checkErr);
      };

      switch (title) {
        case settingsList.scope:
          names.forEach((name, index) => {
            const newNames = newInputs[name].map((it) => ({ name: it.name }));
            postRequest(updateScope[index], changed[name])
              .then(() => makeSuccess())
              .catch(() => makeFailed());
            postRequest(addScope[index], newNames)
              .then(() => makeSuccess(name))
              .catch(() => makeFailed());
          });
          checkErr.splice(0, checkErr.length);
          break;
        case settingsList.infrastructure:
          names.forEach((name, index) => {
            const newNames = newInputs[name].map((it) => ({ name: it.name }));
            postRequest(updateInfrastructure[index], changed[name])
              .then(() => makeSuccess())
              .catch(() => makeFailed());
            postRequest(addInfrastructure[index], newNames)
              .then(() => makeSuccess(name))
              .catch(() => makeFailed());
          });
          checkErr.splice(0, checkErr.length);
          break;
        default:
          break;
      }
    }
  };

  const handleCancelBtn = (e: any) => {
    e.stopPropagation();
    setIsEditable(!isEditable);
    setMockData(data);
    initializingValues();
  };

  const handleDelete = (id: number | string, name: string) => {
    setNewInputs(deleteFunction(newInputs!, name, id));
  };

  const handleNewInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const newValues = { ...newValue };
    newValues[name] = event.target.value;
    setNewValue(newValues);
  };

  const handleNewCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const newChValues = { ...newChValue };
    newChValues[name] = event.target.checked;
    setNewChValue(newChValues);
  };

  const saveNewValue = (name: string) => {
    if (!newValue || !newValue[name]) {
      return;
    }
    const lastId = +data[name][data[name].length - 1].id;
    setNewInputs(addFunction(newInputs!, name, newValue[name], lastId));
    const newValues = { ...newValue };
    newValues[name] = "";
    setNewValue(newValues);
  };

  // handle more option button
  const handleOptionMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOptionMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOptionMenuAnchorEl(null);
  };

  const optionMenuId = "option-menu";
  const moreOptionsData = [
    {
      name: isEditable ? buttonList.save : buttonList.edit,
      icon: isEditable ? (
        <Icon.Save fontSize="small" />
      ) : (
        <Icon.Edit fontSize="small" />
      ),
      handleClick: function (e: React.MouseEvent<HTMLElement>) {
        handleEdit(e);
        handleMenuClose();
      },
    },
  ];
  const moreOptionsDataEdit = [
    {
      name: buttonList.cancel,
      icon: <Icon.DeleteIcon fontSize="small" />,
      handleClick: function (e: React.MouseEvent<HTMLElement>) {
        handleCancelBtn(e);
        handleMenuClose();
      },
    },
    {
      name: isEditable ? buttonList.save : buttonList.edit,
      icon: isEditable ? (
        <Icon.Save fontSize="small" />
      ) : (
        <Icon.Edit fontSize="small" />
      ),
      handleClick: function (e: React.MouseEvent<HTMLElement>) {
        handleEdit(e);
        handleMenuClose();
      },
    },
  ];

  const buttonDataEdit: ButtonData[] = [
    {
      btnLabel: buttonList.cancel,
      btnHandleClick: function (event: React.MouseEvent<HTMLElement>) {
        handleCancelBtn(event);
      },
      btnIcon: <Icon.DeleteIcon />,
      btnStyle: "outlined",
      btnColor: "default",
    },
    {
      btnLabel: !isEditable ? buttonList.edit : buttonList.save,
      btnHandleClick: function (
        event: React.MouseEvent<HTMLElement>,
        exp: boolean
      ) {
        handleEdit(event, exp);
      },
      btnIcon: !isEditable ? <Icon.Edit /> : <Icon.Save />,
      btnStyle: isEditable ? "contained" : "outlined",
      btnColor: !isEditable ? "secondary" : "default",
    },
  ];
  const buttonData: ButtonData[] = [
    {
      btnLabel: !isEditable ? buttonList.edit : buttonList.save,
      btnHandleClick: function (
        event: React.MouseEvent<HTMLElement>,
        exp: boolean
      ) {
        handleEdit(event, exp);
      },
      btnIcon: !isEditable ? <Icon.Edit /> : <Icon.Save />,
      btnStyle: !isEditable ? "contained" : "outlined",
      btnColor: !isEditable ? "secondary" : "default",
    },
  ];

  if (!mockData || !newValue || !newChValue || !newInputs) {
    return <h1>loading..</h1>;
  }
  return (
    <>
      <CustomAccardion
        title={title}
        subTitle={settingsList.displayed}
        handleOptionMenuOpen={(event) => handleOptionMenuOpen(event)}
        optionMenuId={optionMenuId}
        buttonData={sponsors ? [] : isEditable ? buttonDataEdit : buttonData}
        hideMenu={sponsors}
      >
        <Grid container spacing={4}>
          {names.map((it, index) => {
            return (
              <Grid
                item
                xl={3}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                key={it + title + "key1"}
                className={classes.gridRoot}
              >
                <Typography
                  variant="body2"
                  style={{ marginBottom: 10, fontSize: "1.1rem" }}
                >
                  {`${sponsors && index !== 0 ? "" : it + "."} ${
                    titleNames[index]
                  }:`}
                </Typography>
                {mockData[it].map((item) => {
                  return (
                    <Box display="flex" key={item.id + "key"}>
                      <FormControlLabel
                        className={`${classes.listItem} ${
                          title === settingsList.consent
                            ? classes.listItem2
                            : ""
                        }`}
                        control={
                          <Checkbox
                            id={item.id + it + "_checkbox"}
                            checked={item.active}
                            className={
                              sponsors
                                ? ""
                                : !isEditable
                                ? classes.checkBox
                                : ""
                            }
                            disabled={!isEditable}
                            onChange={(event) => {
                              const current = handleCheckboxValueChange2(
                                mockData,
                                item.id,
                                it,
                                event.target.checked
                              );
                              setMockData(current);
                            }}
                            color="secondary"
                          />
                        }
                        label={
                          // label as input ( onEdit )
                          isEditable ? (
                            <CustomInput
                              id={item.id + "customInput" + title}
                              value={item.name}
                              fullWidth
                              multiline
                              onChange={(event) => {
                                const current = handleInputValueChange2(
                                  mockData,
                                  item.id,
                                  it,
                                  event.target.value
                                );
                                setMockData(current);
                              }}
                            />
                          ) : (
                            // label as text ( default )
                            <Box
                              className={
                                title === settingsList.infrastructure &&
                                (it === "2" || it === "3")
                                  ? classes.textBackground
                                  : ""
                              }
                            >
                              {item.name}
                            </Box>
                          )
                        }
                      />
                    </Box>
                  );
                })}
                {newInputs[it].map((item) => {
                  return (
                    <Box key={item.id + "key"}>
                      <FormControlLabel
                        className={`${classes.listItem} ${
                          // title === settingsList.consent
                          //   ?
                          classes.listItem2
                          // : ""
                        }`}
                        control={
                          <Checkbox
                            id={item.id + it + "_checkbox"}
                            checked={item.active}
                            disabled={true}
                            onChange={(event) => {
                              const current = handleCheckboxValueChange2(
                                newInputs,
                                item.id,
                                it,
                                event.target.checked
                              );
                              setNewInputs(current);
                            }}
                            color="secondary"
                          />
                        }
                        label={
                          isEditable ? (
                            <CustomInput
                              id={item.id + "customInput" + title}
                              value={item.name}
                              fullWidth
                              multiline
                              onChange={(event) => {
                                const current = handleInputValueChange2(
                                  newInputs,
                                  item.id,
                                  it,
                                  event.target.value
                                );
                                setNewInputs(current);
                              }}
                            />
                          ) : (
                            // label as text ( default )
                            <Box
                              className={
                                title === settingsList.infrastructure &&
                                (it === "2" || it === "3")
                                  ? classes.textBackground
                                  : ""
                              }
                            >
                              {item.name}
                            </Box>
                          )
                        }
                      />
                      {isEditable && (
                        <IconButton
                          className={classes.deleteButton}
                          size="small"
                          onClick={() => handleDelete(item.id, it)}
                        >
                          <Icon.DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
                {/* adding new form ( checkbox + text) */}
                {sponsors ? (
                  ""
                ) : (
                  <FormControlLabel
                    key={it + "new_key" + title}
                    className={` ${classes.listItem} ${classes.new}`}
                    control={
                      <Checkbox
                        disabled={true}
                        value={newChValue[it]}
                        onChange={(event) => handleNewCheckboxChange(event, it)}
                        id={it + "new_checkBox_" + title}
                        color="secondary"
                      />
                    }
                    label={
                      <CustomInput
                        value={newValue[it]}
                        id={it + "new_input_" + title}
                        placeholder={settingsList.addAnother}
                        onChange={(event) => {
                          handleNewInputChange(event, it);
                          !isEditable && setIsEditable(true);
                        }}
                        transparent
                        fullWidth
                        icon={<Icon.KeyboardReturnIcon />}
                        iconClick={() => saveNewValue(it)}
                      />
                    }
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </CustomAccardion>
      <OptionMenu
        anchorEl={optionMenuAnchorEl}
        menuId={optionMenuId}
        isOpen={isOptionMenuOpen}
        handleClose={handleMenuClose}
        items={isEditable ? moreOptionsDataEdit : moreOptionsData}
      />
      <CustomAlert
        open={errorMessage ? true : false}
        onClose={() => setErrorMessage("")}
        severity={success ? "success" : "error"}
        message={errorMessage}
      />
    </>
  );
};

export default SettingsWithSections;
