import React, { useState, FC, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { LanguageContext, LanguageContextType } from "../../context/LanguageContext";
import { UserContext, UserContextType } from "../../context/UserContext";
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import * as Icons from "../../utils/icons";
import NavDropdown from "../NavDropdown/NavDropdown";
import Header from '../Header/Header';
import { useStyles } from "./MenuStyles";

type Props = {
  pages: JSX.Element;
}

const Menu: FC<Props> = ({ pages }) => {
  const [open, setOpen] = useState(false);
  
  const { isAdmin } = useContext(UserContext) as UserContextType;
  const { menuList } = useContext(LanguageContext) as LanguageContextType;
  const classes = useStyles();
  const theme = useTheme();
  
  const handleDrawer = () => {
    setOpen(!open);
  };
  
  const menu = [
    {
      name: menuList.praxisprofi,
      icon: <Icons.DescriptionIcon />,
      subCategories: [
        {
          name: menuList.cases,
          path: "/overview",
          icon: <Icons.DescriptionIcon />,
        },
        {
          name: menuList.users,
          path: "/practice-users",
          icon: <Icons.GroupIcon />,
        },
        {
          name: menuList.settings,
          path: "/practice-settings",
          icon: <Icons.BuildIcon />,
        }
      ],
    },
    // {
    //   name: menuList.lottery,
    //   icon: <Icons.EmojiEventsIcon />,
    //   subCategories: [
    //     {
    //       name: menuList.lotteries,
    //       path: "/lottery",
    //       icon: <Icons.EmojiEventsIcon />,
    //     },
    //     {
    //       name: menuList.users,
    //       path: "/lottery-users",
    //       icon: <Icons.GroupIcon />,
    //     },
    //     {
    //       name: menuList.templates,
    //       path: "/lottery-templates",
    //       icon: <Icons.ListAlt />,
    //     },
    //     {
    //       name: menuList.settings,
    //       path: "/lottery-settings",
    //       icon: <Icons.SettingsIcon />,
    //     },
    //   ],
    // },
    {
      name: menuList.system,
      icon: <Icons.SettingsIcon />,
      subCategories: [
        {
          name: menuList.access,
          path: "/system-access",
          icon: <Icons.GroupIcon />,
        },
      ],
    },
  ];

  const filteredSubs = menu[0].subCategories.filter(it => {
    if (!isAdmin) {
      return it.name !== menuList.settings
    } else {
      return it
    }
  });
  menu[0].subCategories = filteredSubs;
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Header handleDrawerOpen={() => handleDrawer()} /> 
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawer}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
          {menu.map((item) => {
            return (
              <NavDropdown
                key={item.name}
                name={item.name}
                icon={item.icon}
                menu={item.subCategories}
                isOpen={open}
              />
            );
          })}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {pages}
      </main>
    </div>
  );
};

export default Menu;