import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useStyles } from "./CustomInputStyles";

type Props = {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  id: string;
  fullWidth?: boolean;
  multiline?: boolean;
  name?: string;
  placeholder?: string;
  transparent?: boolean;
  icon?: JSX.Element;
  iconClick?: () => void;
  defaultValue?: string; 
  type?: string;
  maxWidth?: number;
  minValue?: number;
  maxValue?: number;
};

const CustomInput: FC<Props> = ({
  value,
  onChange,
  label,
  id,
  fullWidth,
  multiline,
  placeholder,
  transparent,
  icon,
  iconClick,
  defaultValue,
  name,
  type,
  minValue,
  maxValue,
  maxWidth
}) => {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      label={label}
      value={value}
      name={name}
      style={maxWidth ? { maxWidth: maxWidth} : undefined}
      className={`${label ? classes.inputWithLabel : classes.input} 
        ${transparent ? classes.transparent : ''}
        ${icon ? classes.withIcon : ''}`}
      fullWidth={fullWidth}
      variant="filled"
      type={type ? type : 'text'}
      onChange={onChange}
      multiline={multiline}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onKeyDown={(event) => icon && event.key === 'Enter' && iconClick!()}
      InputProps={{
        inputProps: {
          max: maxValue,
          min: minValue,
        },
        endAdornment: (
          icon &&
          <InputAdornment position="end" className={classes.icon}>
            <IconButton
              onClick={iconClick}
              color="secondary"
              size="small"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        )
      }}
    /> 
  );
};

export default CustomInput;
