import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardLongContent: {
    padding: "8px",
    "& p": {
      fontSize: "0.75rem",
      "&:last-child": {
        marginTop: "8px",
      },
    },
  },

  cardLongContentReOrder: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    "& p": {
      fontSize: "0.85rem",
      color: theme.palette.text.secondary,
      "&:last-child": {
        fontSize: "0.7rem",
        color: theme.palette.text.disabled,
      },
    },
  },

  cardLongContent_imgSmall: {
    padding: 0,
    [theme.breakpoints.down(350)]: {
      padding: "8px",
    },
  },

  cardTitle: {
    fontSize: "1rem",
    margin: 0,
    [theme.breakpoints.down(450)]: {
      fontSize: "0.85rem",
    },
  },

  cardLongRoot: {
    position: "relative",
    boxShadow: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    maxHeight: "100%",
    margin: "8px 0",
    borderRadius: 0,
    "&:hover": {
      boxShadow: `0 2px 5px ${theme.palette.grey.A200}`,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },

  cardLongRoot_imgSmall: {
    maxHeight: "100%",
  },

  cardLongImage: {
    height: "90px",
    width: "154px",
    objectFit: "cover",
    flexShrink: 0,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(850)]: {
      height: "100px",
    },
    [theme.breakpoints.down(450)]: {
      height: "inherit",
      width: "100px",
    },
  },

  cardRound: {
    maxHeight: "100%",
    padding: theme.spacing(1),
  },

  imgRound: {
    borderRadius: "50%",
    width: "84px",
    height: "84px",
    flexShrink: 0,
    margin: "2px",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    boxShadow: `0 3px 6px ${theme.palette.grey.A100}`,
    [theme.breakpoints.down(450)]: {
      width: "60px",
      height: "60px",
      marginLeft: 0,
      marginRight: theme.spacing(1),
      transition: ".3s",
    },
  },

  imgSmall: {
    width: "100px",
    flexShrink: 0,
    justifySelf: "flex-start",
    alignSelf: "flex-start",
    maxHeight: "80px",
    [theme.breakpoints.down(400)]: {
      maxHeight: "100%",
    },
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
  },

  cardMore: {
    position: "absolute",
    borderRadius: "3px",
    right: "30px",
    [theme.breakpoints.down("xs")]: {
      right: "10px",
    },
    [theme.breakpoints.down(350)]: {
      padding: 0,
    },
  },

  actionArea: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    marginRight: theme.spacing(13),
    "& .MuiCardActionArea-focusHighlight": {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down(350)]: {
      marginRight: theme.spacing(6),
    },
  },

  actionArea100: {
    marginRight: 0,
  },

  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "154px",
    [theme.breakpoints.down(450)]: {
      width: "100px",
    },
  },

  //ordering
  firstLine: {
    order: 2,
  },

  titleLine: {
    order: 1,
  },

  lastLine: {
    order: 3,
  },
}));
