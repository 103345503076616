import React, { FC, useContext } from "react";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/LanguageContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { AllData } from "../../utils/practiceDetailFunctions";
import { useStyles } from "./CustomAutocompleteSingleStyles";
import { useState } from "react";

type Props = {
  valueId: AllData | null;
  onChange: (event: React.ChangeEvent<{}>, newValue: AllData | null) => void;
  items: AllData[];
  id: string;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  charCount?: number;
};

const emptyData = [
  {
    id: 1,
    value: "",
  },
];

const CustomAutocompleteSingle: FC<Props> = ({
  valueId,
  onChange,
  items,
  id,
  disabled,
  placeholder,
  fullWidth,
  charCount,
}) => {
  const [inputValue, setInputValue] = useState("");
  const classes = useStyles();
  const { messagesList } = useContext(LanguageContext) as LanguageContextType;

  const checkCharLength =
    charCount && ((inputValue && inputValue.length < charCount) || !inputValue)
      ? true
      : false;

  return (
    <Autocomplete
      id={id}
      onInputChange={(e, value) => {
        charCount && setInputValue(value);
      }}
      noOptionsText={
        checkCharLength
          ? messagesList.typeToFind
          : messagesList.noOptions
      }
      value={valueId}
      options={
        checkCharLength
        ? emptyData 
        : (valueId && valueId.value === inputValue) 
        ? items.filter(it => it.value === inputValue) 
        : items
      }
      fullWidth={fullWidth}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.value}
      disabled={disabled}
      classes={{
        paper: classes.menuList,
        listbox: checkCharLength ? classes.hidden : "",
        endAdornment: checkCharLength ? classes.hidden : "",
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="filled"
          className={classes.formContol}
        />
      )}
    />
  );
};

export default CustomAutocompleteSingle;
