import React, { createContext, useState, FC } from "react";

export const TitleContext = createContext<TitleContextType>(null!);

export type TitleContextType = {
  setSiteTitle: React.Dispatch<React.SetStateAction<string>>,
  siteTitle: string,
}

export const TitleProvider:FC = ({ children }) => {
  const [siteTitle, setSiteTitle] = useState('');

  return (
    <TitleContext.Provider
      value={{
        setSiteTitle,
        siteTitle,
      }}
    >
      {children}
    </TitleContext.Provider>
  );
};
