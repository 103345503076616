import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50px",
    border: `1px solid ${theme.palette.secondary.main}`,
    fontSize: "0.75rem",
    margin: "12px 12px 12px 0",
    color: theme.palette.text.primary,
    minWidth: "80px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      transition: "0.3s",
    },
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down(300)]: {
      fontSize: "0.65rem",
    },
  },

  noRound: {
    borderRadius: "4px"
  },

  buttonLarge: {
    minWidth: "300px",
    fontSize: "1rem",
    padding: theme.spacing(0.8),
    [theme.breakpoints.down("xs")]: {
      minWidth: "200px",
    },
  },

  filled: {
    border: 'none',
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      transition: "0.3s",
    },
    [theme.breakpoints.down(300)]: {
      fontSize: "0.65rem",
    },
  }
}));