import cloneDeep from "lodash.clonedeep";
import { PracticeType } from "../types/practiceType";
import { AllDataType, DataType } from "./getAllData";
import { decimalCount, removeRest } from "./helperFunctions";
import {
  validateClubInfo,
  validateDraft,
  validateFinancials,
  validateOverview,
} from "./practiceDetailValidation";

export type MessageFields = {
  key: string;
  active: boolean;
};

export type AllData = {
  id: number;
  value: string;
  active?: boolean;
};

export type AllDataBool = {
  id: number;
  value: boolean;
};

export const validation = (obj: PracticeType, all: AllDataType) => {
  if (!all || !obj) {
    return;
  }
  const failedSections = [];
  !validateOverview(obj) && failedSections.push("general");
  !validateClubInfo(obj) && failedSections.push("club");
  !validateFinancials(obj) && failedSections.push("finance");
  if (failedSections[0]) {
    return failedSections;
  }
  return true;
};

export const draftValidation = (obj: PracticeType) => {
  if (!obj) {
    return;
  }
  const failedSections = [];
  !validateDraft(obj) && failedSections.push("general");

  if (failedSections[0]) {
    return failedSections;
  }
  return true;
};

export const handleActiveMessages = (
  obj: MessageFields[],
  objectKey: string,
  newValue: boolean
) => {
  const messages: MessageFields[] = obj.map((item) => {
    if (item.key === objectKey) {
      item.active = newValue;
      return item;
    }
    return item;
  });
  return messages;
};

export const findValueId = (obj: AllData[], name: string) => {
  const singleObj = obj.find((item) => item.value === name);
  return singleObj ? singleObj.id : name;
};

export const findValueById = (obj: AllData[], id: number) => {
  const singleObj = obj.find((item) => item.id === id);
  return singleObj ? singleObj.value : "";
};

export const findValueIds = (obj: AllData[], names: string[]) => {
  const values = names ? names : [];
  const allObjects = obj.filter((item) => values.includes(item.value));
  const allIds = allObjects.map((item) => item.id);
  return allIds[0] ? allIds : ((values as unknown) as number[]);
};

export const findValuesByIds = (obj: AllData[], ids: number[]) => {
  const allObjects = obj.filter((item) => ids.includes(item.id));
  const allValues = allObjects.map((item) => item.value);
  return allValues ? allValues : [];
};

export const findBoolValueById = (obj: AllDataBool[], id: number) => {
  const singleObj = obj.find((item) => item.id === id);
  return singleObj ? singleObj.value : undefined;
};

export const handleMultiSelectChange = (
  newValue: AllData[],
  key: string,
  obj: PracticeType
) => {
  const clone = cloneDeep(obj);
  if (newValue === null) {
    clone[key] = [];
  } else {
    clone[key] = newValue;
  }

  if (key === "sponsors") {
    const sponsors = newValue as AllData[];
    if (sponsors.length > 1 && sponsors.some((spon) => spon.id === 1)) {
      const index = sponsors.findIndex((spon) => spon.id === 1);
      if (index === sponsors.length - 1) {
        sponsors.splice(0, index);
      } else {
        sponsors.splice(index, 1);
      }
    }
  }
  return clone;
};

export const handleSelectChange = (
  newValue: AllData,
  key: string,
  obj: PracticeType
) => {
  const clone = cloneDeep(obj);
  if (newValue === null) {
    clone[key] = "";
  } else {
    if (key === "categoryId" || key === "club") {
      clone[key] = newValue.id;
    } else {
      clone[key] = newValue.value;
    }
  }
  return clone;
};

export const handleInputChange = (
  newValue: string | number,
  key: string,
  obj: PracticeType,
  max?: number,
  fraction?: boolean
) => {
  const clone = cloneDeep(obj);

  if (max && max === 2 && (+newValue < 0 || +newValue > 99)) {
    if (+newValue < 0) {
      clone[key] = 0;
    }
    if (+newValue > 99) {
      const trimed = removeRest(newValue.toString(), max);
      clone[key] = +trimed;
    }
  } else if (max && max === 2 && (+newValue >= 0 || +newValue <= 99)) {
    clone[key] = +newValue;
  } else if (
    max &&
    max !== 2 &&
    newValue.toString().length > max &&
    !fraction
  ) {
    const trimed = removeRest(newValue.toString(), max);
    clone[key] = trimed;
    // decimal numbers xxx.00 ( max + 2 after decimal point)
  } else if (
    max &&
    max !== 2 &&
    (newValue.toString().length > max || !Number.isInteger(+newValue)) &&
    fraction
  ) {
    if (newValue.toString().length > max && Number.isInteger(+newValue)) {
      const trimed = removeRest(newValue.toString(), max);
      clone[key] = trimed;
    } else if (!Number.isInteger(+newValue)) {
      const integerLength = Math.round(+newValue).toString().length;
      if (decimalCount(+newValue) > 2) {
        const trimed = removeRest(newValue.toString(), integerLength + 3);
        clone[key] = trimed;
      } else {
        clone[key] = newValue;
      }
    }
  } else if (+newValue < 0) {
    clone[key] = 0;
  } else {
    clone[key] = newValue;
  }
  return clone;
};

export const handleArrayChange = (
  newValue: string | number,
  key: string,
  index: number,
  obj: PracticeType
) => {
  const clone = cloneDeep(obj);

  if (+newValue < 0 || +newValue > 99 || !newValue) {
    if (+newValue > 99) {
      newValue = removeRest(newValue.toString(), 2);
    } else {
      newValue = 0;
    }
  } else if (+newValue >= 0 || +newValue <= 99) {
    newValue = +newValue;
  }

  (clone[key] as string[][])[index].pop();
  (clone[key] as string[][])[index].push(newValue.toString());
  return clone;
};

export const fromDbToRegular = (
  obj: PracticeType,
  allObj: DataType[] | undefined,
  allSpons: DataType[] | undefined
) => {
  if (!allObj || !allSpons) {
    return;
  }
  const objectives = allObj.map((it) => ({ id: it.id, value: it.name }));
  const sponsor = allSpons.map((it) => ({ id: it.id, value: it.name }));
  const clone = cloneDeep(obj);

  if (typeof clone.objective === "string") {
    if (clone.objective === "") {
      clone.objective = [];
    } else {
      clone.objective = (clone.objective as string).split("; ").map((ob) => ({
        id: findValueId(objectives, ob) as number,
        value: ob,
      }));
    }
  }
  if (typeof clone.sponsors === "string") {
    if (clone.sponsors === "") {
      clone.sponsors = [];
    } else {
      clone.sponsors = (clone.sponsors as string)
        .split("; ")
        .map((ob) => ({ id: findValueId(sponsor, ob) as number, value: ob }));
    }
  }
  if (clone.teamComposition && clone.teamComposition.length === undefined) {
    const teams = Object.entries(clone.teamComposition);
    clone.teamComposition = teams;
  }
  if (clone.pitchType && clone.pitchType.length === undefined) {
    const pitches = Object.entries(clone.pitchType);
    clone.pitchType = pitches;
  }
  if (clone.cabinType && clone.cabinType.length === undefined) {
    const cabins = Object.entries(clone.cabinType);
    clone.cabinType = cabins;
  }
  if (clone.scope === 0) {
    clone.scope = "";
  }
  if (typeof clone.club === "object") {
    clone.club = clone.club.id;
  }
  if (clone.clubhouseAvailable === "true") {
    clone.clubhouseAvailable = "Ja";
  } else if (clone.clubhouseAvailable === "false") {
    clone.clubhouseAvailable = "Nein";
  }
  return clone;
};

export const fromRegularToDb = (obj: PracticeType) => {
  const clone = cloneDeep(obj);
  if (typeof clone.objective !== "string") {
    clone.objective = (clone.objective as AllData[])
      .map((ob) => ob.value)
      .join("; ");
  }
  if (typeof clone.sponsors !== "string") {
    clone.sponsors = (clone.sponsors as AllData[])
      .map((sp) => sp.value)
      .join("; ");
  }
  if (clone.teamComposition.length) {
    const obj = Object.fromEntries(clone.teamComposition as string[][]);
    clone.teamComposition = obj;
  }
  if (clone.pitchType.length) {
    const obj = Object.fromEntries(clone.pitchType as string[][]);
    clone.pitchType = obj;
  }
  if (clone.cabinType.length) {
    const obj = Object.fromEntries(clone.cabinType as string[][]);
    clone.cabinType = obj;
  }
  if (clone.clubhouseAvailable) {
    if (clone.clubhouseAvailable === "Ja") {
      clone.clubhouseAvailable = true;
    } else {
      clone.clubhouseAvailable = false;
    }
  } else {
    delete clone.clubhouseAvailable;
  }
  if (clone.scope === "") {
    clone.scope = 0;
  }
  if (Number(clone.club) === 0) {
    delete clone.club;
  } else if (typeof clone.club === "number") {
    clone.club = { id: clone.club };
  }
  return clone;
};
