/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TitleContext, TitleContextType } from "../context/TitleContext";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/LanguageContext";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as Icon from "../utils/icons";

import Error from "../components/Error/Error";
import { makeStyles } from "@material-ui/core/styles";
import { PracticeType } from "../types/practiceType";
import CardMain from "../components/CardMain/CardMain";
import CardLong from "../components/CardLong/CradLong";
import SearchBar from "../components/SearchBar/SearchBar";
import AddButton from "../components/AddButton/AddButton";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomAutocomplete from "../components/CustomAutocomplete/CustomAutocomplete";
import AddBestPractice from "../components/AddBestPractice/AddBestPractice";
import defaultImage from "../assets/images/defImg.jpeg";
import {
  getRequest,
  getRequestWithParams,
  getRequestWithTwoParams,
} from "../utils/apiRequests";
import {
  bestPracticeGetAll,
  bestPracticeGetByCategories,
  bestPracticeGetByStatus,
  bestPracticeGetByStatusesAndCategories,
} from "../apiURL/httpBestPractice";
import {
  truncate,
  capitalize,
  changeSpaceCount,
} from "../utils/helperFunctions";
import { categoryGetActive } from "../apiURL/httpCategory";
import { AllData, findValueById } from "../utils/practiceDetailFunctions";
import { DataType } from "../utils/getAllData";
import { fileGetMain } from "../apiURL/httpFiles";
import { FileType } from "../types/fileType";
import CustomAlert from "../components/CustomAlert/CustomAlert";

const useStyles = makeStyles((theme) => ({
  activeIcon: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },

  iconButton: {
    borderRadius: "3px",
  },

  filterWrapper: {
    minWidth: "219px",
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down(600)]: {
      flexShrink: 1,
      minWidth: "100% !important",
    },
  },

  filterWrapper_large: {
    minWidth: "260px",
  },

  extraSpace: {
    minWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
  },

  extraSpace_2: {
    display: "none",
    minWidth: "200px",
    [theme.breakpoints.up(1448)]: {
      display: "block",
    },
  },

  extraSpace_3: {
    display: "none",
    minWidth: "200px",
    [theme.breakpoints.up(1784)]: {
      display: "block",
    },
  },

  spaceParent_3: {
    [theme.breakpoints.down(1784)]: {
      display: "none",
    },
  },

  container: {
    alignItems: "stretch",
    display: "flex",
    [theme.breakpoints.down(777)]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up(2120)]: {
      flexGrow: 0,
    },
  },
}));

let space = [1];

const statuses = [
  "draft",
  "created",
  "submitted",
  "approved",
  "denied",
  "published",
];
const allStatuses = statuses.map((st, index) => ({ id: index + 1, value: st }));
const COUNT = 10;

const Overview = () => {
  const [showColumns, setShowColumns] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [category, setCategory] = useState<AllData[]>([]);
  const [status, setStatus] = useState<AllData[]>([]);
  const [allCategories, setAllCategories] = useState<AllData[]>();
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState(10);
  const [openAdd, setOpenAdd] = useState(false);
  const [practiceData, setPracticeData] = useState<PracticeType[]>();
  const [originalData, setOriginalData] = useState<PracticeType[]>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorFilter, setErrorFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [mainFiles, setMainFiles] = useState<{ [key: string]: string }>();
  const [loadingImg, setLoadingImg] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");

  const { setSiteTitle } = useContext(TitleContext) as TitleContextType;

  const {
    buttonList,
    optionList,
    pageTitleList,
    settingsList,
    statusList,
    messagesList,
    siteLanguage,
  } = useContext(LanguageContext) as LanguageContextType;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSiteTitle(pageTitleList.praxisprofiCases);
  }, [siteLanguage]);

  useEffect(() => {
    const draftDeleted = sessionStorage.getItem("deleteDraft");
    if (draftDeleted) {
      setAlertMessage(messagesList.successfulDeleteDraft);
    }
    sessionStorage.removeItem('deleteDraft');
  }, []);

  useEffect(() => {
    getRequest(bestPracticeGetAll)
      .then((res) => {
        setPracticeData(res);
        setOriginalData(res);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, []);

  // search filter + sort by Date
  const filtered = practiceData
    ?.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    )
    .sort(function (a, b) {
      return +new Date(b.submitted) - +new Date(a.submitted);
    });

  const length = filtered?.length;

  useEffect(() => {
    if (!originalData || !length || !filtered) {
      return;
    }

    setLoadingImg(true);
    const ids = filtered
      .filter((it, index) => {
        if (mainFiles && mainFiles.hasOwnProperty(it.id)) {
          return false;
        } else if (index >= count - COUNT && index < 0 + count) {
          return true;
        } else {
          return false;
        }
      })
      .map((it) => it.id);

    const req = ids.map((id) => {
      return getRequest(fileGetMain(id))
        .then((res: FileType) => ({ bpId: id, file: res }))
        .catch((rej) => ({ bpId: id, file: undefined }));
    });
    Promise.all([...req])
      .then(([...files]) => {
        const fileObj = files.map((file) =>
          file && file.file
            ? [file.bpId, file.file.storageUri]
            : [file.bpId, defaultImage]
        );
        const obj = Object.fromEntries(fileObj);
        setMainFiles({ ...mainFiles, ...obj });
        setLoadingImg(false);
      })
      .catch(() => {
        setLoadingImg(false);
      });
  }, [originalData, length, count]);

  useEffect(() => {
    getRequest(categoryGetActive)
      .then((res) => {
        setAllCategories(
          res.map((it: DataType) => ({ id: it.id, value: it.name }))
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (!allStatuses || !status) {
      return;
    }
    const categoryIds = category.map((cat) => cat.id);
    const statusNames = status.map((st) =>
      findValueById(allStatuses, st.id).toUpperCase()
    );
    // status filter
    if (!category[0] && status[0]) {
      setLoadingFilter(true);
      getRequestWithParams(bestPracticeGetByStatus, "status", statusNames)
        .then((res: any) => {
          setPracticeData(res);
          setErrorFilter(false);
          setLoadingFilter(false);
        })
        .catch(() => {
          setErrorFilter(true);
          setLoadingFilter(false);
        });
      // category filter
    } else if (category[0] && !status[0]) {
      setLoadingFilter(true);
      getRequestWithParams(
        bestPracticeGetByCategories,
        "categoryId",
        categoryIds
      )
        .then((res) => {
          setPracticeData(res);
          setErrorFilter(false);
          setLoadingFilter(false);
        })
        .catch(() => {
          setErrorFilter(true);
          setLoadingFilter(false);
        });
      // status + category filter
    } else if (category[0] && status[0]) {
      setLoadingFilter(true);
      getRequestWithTwoParams(
        bestPracticeGetByStatusesAndCategories,
        "categoryId",
        "status",
        categoryIds,
        statusNames
      )
        .then((res) => {
          setPracticeData(res);
          setErrorFilter(false);
          setLoadingFilter(false);
        })
        .catch(() => {
          setErrorFilter(true);
          setLoadingFilter(false);
        });
      // default all
    } else {
      setPracticeData(originalData);
    }
    setCount(10);
  }, [category, status]);

  if (
    error ||
    loading ||
    !practiceData ||
    !allCategories ||
    !allStatuses ||
    !filtered
  ) {
    return <Error loading={loading} error={error} />;
  }

  // show only 10 per page
  const grouped = filtered?.filter((item, i) => i < count);

  space = changeSpaceCount(grouped.length);
  const checkLength = grouped < filtered;

  return (
    <>
      {showFilter && (
        <Box display="flex" flexWrap="wrap">
          <Box
            mb={2}
            flexShrink={1}
            className={`${classes.filterWrapper} ${classes.filterWrapper_large}`}
          >
            <Typography variant="subtitle2">
              {settingsList.categories}
            </Typography>
            <CustomAutocomplete
              valueId={category}
              id="category-select"
              onChange={(e, newValue) => setCategory([...newValue])}
              items={allCategories}
              placeholder={
                category[0]
                  ? ""
                  : siteLanguage
                  ? `${
                      settingsList.categories
                    } ${optionList.select.toLowerCase()}`
                  : `${
                      optionList.select
                    } ${settingsList.categories.toLowerCase()}`
              }
            />
          </Box>
          <Box mb={2} flexShrink={1} className={classes.filterWrapper}>
            <Typography variant="subtitle2">STATUS</Typography>
            <CustomAutocomplete
              valueId={status}
              placeholder={
                status[0]
                  ? ""
                  : siteLanguage
                  ? `Status ${optionList.select.toLowerCase()}`
                  : `${optionList.select} status`
              }
              id="status-select"
              onChange={(e, newValue) => setStatus([...newValue])}
              items={allStatuses.map((status) => ({
                id: status.id,
                value: statusList[status.value.toLowerCase()],
              }))}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" mb={1} justifyContent="flex-end">
        <Box flexGrow={1} mb={1}>
          <SearchBar
            label={optionList.search}
            value={searchValue}
            id="overview-search"
            onChange={(e) => {
              setSearchValue(e.target.value);
              setCount(10);
            }}
          />
        </Box>
        <Box display="flex">
          <Box mx={1}>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                setShowColumns(!showColumns);
              }}
              disableRipple
            >
              {showColumns ? <Icon.ViewList /> : <Icon.ViewArray />}
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              className={`${classes.iconButton} ${
                showFilter && classes.activeIcon
              }`}
              disableRipple
            >
              <Icon.FilterList />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="body2" component="p">
          {grouped?.length} / {filtered?.length}
        </Typography>
      </Box>

      {errorFilter || loadingFilter ? (
        <Error loading={loadingFilter} error={errorFilter} size="medium" />
      ) : (
        <>
          {((practiceData && !practiceData[0]) || (grouped && !grouped[0])) && (
            <Typography>{messagesList.noResults}</Typography>
          )}
          <Box display="flex" flexBasis="100%" flexWrap="wrap">
            {showColumns ? (
              <>
                {grouped?.map((item) => {
                  return (
                    <Box
                      flexGrow={1}
                      className={classes.container}
                      key={item.id}
                    >
                      <CardMain
                        onClick={() => {
                          history.push({
                            pathname: `/practice-detail/${item.id}`,
                            state: { prevPath: location.pathname },
                          });
                        }}
                        status={capitalize(
                          statusList[item.status.toLowerCase()]
                        )}
                        title={item.title}
                        date={dayjs(item.submitted).format("DD.MM.YYYY")}
                        defaultImgSrc={defaultImage}
                        imgSrc={mainFiles ? mainFiles[item.id] : ""}
                        loadImg={loadingImg}
                        header={true}
                        footer={false}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {truncate(item.description, 190)}
                        </Typography>
                      </CardMain>
                    </Box>
                  );
                })}
                {space.map((item, index) => {
                  return (
                    <Box flexGrow={1} mx={1} key={item} className={index === 2 ? classes.spaceParent_3 : ""}>
                      <Box
                        className={`${index === 0 ? classes.extraSpace : ""}
                          ${index === 1 ? classes.extraSpace_2 : ""}
                          ${index === 2 ? classes.extraSpace_3 : ""}`}
                        style={{
                          maxWidth: "320px",
                          width: "100%",
                          marginRight: "120px",
                        }}
                      ></Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              grouped?.map((item) => {
                return (
                  <CardLong
                    key={item.id}
                    onClick={() =>
                      history.push({
                        pathname: `/practice-detail/${item.id}`,
                        state: { prevPath: location.pathname },
                      })
                    }
                    title={item.title}
                    lastLine={`Status: ${capitalize(
                      statusList[item.status.toLowerCase()]
                    )}`}
                    firstLine={dayjs(item.submitted).format("DD.MM.YYYY")}
                    imgSrc={mainFiles ? mainFiles[item.id] : ""}
                    loadImg={loadingImg}
                    defaultImgSrc={defaultImage}
                    footer={false}
                    imgRound={false}
                  />
                );
              })
            )}
          </Box>
        </>
      )}
      {checkLength && (
        <Box display="flex" justifyContent="center" mt={2} mx={1.8}>
          <CustomButton
            size="large"
            variant="contained"
            disableRound={true}
            onClick={() => setCount(count + 10)}
          >
            {buttonList.loadMore}
          </CustomButton>
        </Box>
      )}
      <AddButton onClick={() => setOpenAdd(true)} />
      <AddBestPractice open={openAdd} handleClose={() => setOpenAdd(false)} />
      <CustomAlert 
        open={alertMessage ? true : false}
        onClose={() => setAlertMessage("")}
        message={alertMessage}
        severity="success"  
      />
    </>
  );
};

export default Overview;
