export const practiceDetail = {
  de: {
    overview: "Übersicht",
    name: "Name",
    startingPoint: "Ausgangslage",
    objectives: "Zielsetzung",
    description: "Kurzbeschreibung",
    submittedAt: "Eingereicht um",
    submittedBy: "Eingereicht von",
    deniedAt: "Verweigert um",
    deniedBy: "Verweigert von",
    createdAt: "Erstellt um",
    createdBy: "Erstellt von",
    approvedAt: "Genehmigt um",
    lastEdit: "Zuletzt bearbeitet",
    approvedBy: "Genehmigt von",
    editedBy: "Bearbeitet von",
    draftCreated: "Entwurf erstellt um",
    history: "Verlauf",
    action: "Aktion",
    date: "Datum",
    publishedBP: "Veröffentlichte Kampagnen",
    specifyAmount: "Bitte geben Sie den Betrag an",
    isSponsored: "Wurde Euer Verein bei der Finanzierung unterstützt?",
    review: "Überprüfung der Wirksamkeit",
    clubInfo: "Allgemeine Informationen zum Verein",
    sportsFacilityOwn: "Wem gehört die Sportstätte?",
    clubhouseAvailable: "Ist ein Vereinsheim vorhanden?",
    cabinsAvailable: "Wie viele Umkleidekabinen sind vorhanden?",
    noOfPitches: "Anzahl der Spielfelder",
    dataProtect: "Datenschutzrecht",
    copyrights: "Urheberrecht / Nutzungsrechte",
    financials: "Finanzen",
    club: "Vereinsinformationen",
    addBestPractice: "Best Practice Anlegen",
    title: "Titel",
    documents: "Dokumente",
    mainPic: "Hauptbild",
    clubName: "Vereinsname"
  },

  en: {
    overview: "Overview",
    name: "Name",
    startingPoint: "Starting point",
    objectives: "Objectives",
    description: "Short description",
    submittedAt: "Submitted at",
    submittedBy: "Submitted by",
    deniedAt: "Denied at",
    deniedBy: "Denied by",
    createdAt: "Created at",
    createdBy: "Created by",
    approvedAt: "Approved at",
    lastEdit: "Last edit",
    approvedBy: "Approved by",
    editedBy: "Edited by",
    draftCreated: "Draft created at",
    history: "History",
    action: "Action",
    date: "Date",
    publishedBP: "Published best practices",
    isSponsored: "Has your club been supported in financing?",
    specifyAmount: "Please specify the amount",
    review: "Review the effectiveness",
    clubInfo: "General information about the club",
    sportsFacilityOwn: "Who owns the sports facility?",
    clubhouseAvailable: "Is a clubhouse available?",
    cabinsAvailable: "How many cabins are available?",
    noOfPitches: "Number of pitches",
    dataProtect: "Data protection law",
    copyrights: "Copyright / usage rights",
    financials: "Financials",
    club: "Club information",
    addBestPractice: "Create Best Practice",
    title: "Title",
    documents: "Documents",
    mainPic: "Main picture",
    clubName: "Club name"
  },
};