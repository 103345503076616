export const statuses = {
  de: {
    draft: "Entwurf",
    submitted: "Eingereicht",
    created: "Erstellt",
    denied: "Abgelehnt",
    approved: "Genehmigt",
    published: "Zu Typo3 übertragen",
  },
  en: {
    draft: "Draft",
    submitted: "Submitted",
    created: "Created",
    denied: "Denied",
    approved: "Approved",
    published: "Transferred to Typo3",
  },
};